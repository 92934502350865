<div class="row justify-content-end mt-0">
  <div class="col-12 mb-2">
    <h2 class="naranja">Inicializar un turno</h2>
    <p class="mt-3">
      Hola {{user.name}},
      para comenzar ingresa cuanto <strong>dinero</strong> tienes en caja
    </p>
  </div>
  <div class="col-12 pt-2 mb-2">
    <div><p>Agrega tu nombre</p></div>
    <div class="input-group">
      <input class="form-control" type="number" [ngClass]="complexForm.controls['name'].hasError('required') ? 'is-invalid':'is-valid'" type="text" name="mNamds"  [(ngModel)]="object.name" [formControl]="complexForm.controls['name']" required>
      <div class="invalid-feedback">
        Campo requerido
      </div>
    </div>

</div>
  <div class="col-12 pt-2 mb-2">
    <div><p>Monto en caja</p></div>
    <div class="input-group">
      <input class="form-control" type="number" [ngClass]="complexForm.controls['openning_amount'].hasError('required') ? 'is-invalid':'is-valid'" type="text" placeholder="0.00" name="mName"  [(ngModel)]="object.openning_amount" [formControl]="complexForm.controls['openning_amount']" required>
      <div class="invalid-feedback">
        Campo requerido
      </div>
    </div>

</div>

  <div class="col-12">
    <div class="row justify-content-center m-0">
      <div class="col-12 col-md-10">
        <button type="button" class="btn btn-primary btn-save" (click)="openTurn();" [disabled]="complexForm.invalid">COMENZAR</button>
      </div>
    </div>
  </div>
</div>
