import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { AdminShoesUpdateComponent } from './admin-shoes-update/admin-shoes-update.component';

@Component({
  selector: 'app-admin-shoes',
  templateUrl: './admin-shoes.component.html',
  styleUrls: ['./admin-shoes.component.css']
})
export class AdminShoesComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  shoesMin = {
    max: 10,
    page: 1,
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  constructor(private session: SessionService,
  public loadding:LoadingService,
  public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadShoes();
  }

  /**funcionalidad para cargar la lista de precios
  */
  loadShoes(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest("shoe_list:list",this.shoesMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
      this.loadding.hide();
    },error=>{
      console.log("error:shoe_list:list",error);
      this.loadding.hide();
    })
  }

  /** funcionalidad para crear una categoria
  */
  update(uuid){
    const dialogRef = this.dialog.open(AdminShoesUpdateComponent, {
      width: '36rem',
      height:'90%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.loadShoes();
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

  delete(object){
    this.loadding.show(true, "Espere un momento...");
    this.session.postRequest("shoe_list:delete",object).subscribe((data:any)=>{
      for(let i=0; i < this.list.length; i++){
        if(this.list[i].id == object.id){
          this.list.splice(i,1);
          break;
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("error:shoe_list:delete",error);
      this.loadding.hide();
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.shoesMin.max = event.pageSize;
    this.shoesMin.page = event.pageIndex + 1;
    this.loadShoes();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
