<div class="row justify-content-center mt-2 p2 fondous" >
  <div class="col-4">
    <table style="width:100%; text-align: right;">
      <tbody>
        <tr>
          <td style="float: left !important;"><h5>Total a cobrar</h5></td>
          <td> <h5>{{metadata.object.total | currency:'CAD':'symbol-narrow'}}</h5></td>
        </tr>
        <tr>
          <td style="float: left !important;"> <h5>{{metadata.paymentMethod}}</h5> </td>
          <td> <h5>{{metadata.totalPayment | currency:'CAD':'symbol-narrow'}}</h5> </td>
        </tr>
        <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
        <tr>
          <td style="float: left !important;"><h5>Saldo</h5></td>
          <td> <h5 *ngIf="metadata.totalPayment == 0">{{metadata.saldo | currency:'CAD':'symbol-narrow'}}</h5> <h5 *ngIf="metadata.totalPayment > 0">{{metadata.object.total | currency:'CAD':'symbol-narrow'}}</h5></td>
        </tr>
        <tr>
          <td style="float: left !important;"> <h5>Cambio</h5> </td>
          <td> <h5>{{metadata.totalChange | currency:'CAD':'symbol-narrow'}}</h5> </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-5">
    <div style="width:100%">
      <button mat-raised-button  class="btnPayment" *ngFor="let object of fastPayment" (click)="loadFastPayment(object);">{{object.name}}</button>
    </div>
    <div style="width:100%" class="mt-3 mb-3">
      <button mat-raised-button  class="btnPayment" *ngFor="let object of calculatorDigits" (click)="useCalculator(object,object.type)">{{object.name}}</button>
    </div>
  </div>
  <div class="col-3">
    <button mat-raised-button class="btnPaymentS"  style="background:{{metadata.paymentMethod == object.value ? '#009688':'rgb(231 135 31)'}};" (click)="selectedPaymentMethod(object.value)" *ngFor="let object of paymentMethods">{{object.name}}</button>
  </div>
</div>

<div class="row justify-content-end">
  <div class="col-6">
    <button type="button" class="btn btn-success btn-lg btn-block" (click)="close(true);" [disabled]="metadata.totalPayment == 0">COBRAR</button>
  </div>
</div>
