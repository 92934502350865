import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { TurnService } from '../turn.service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-close-turn',
  templateUrl: './admin-close-turn.component.html',
  styleUrls: ['./admin-close-turn.component.css']
})
export class AdminCloseTurnComponent implements OnInit {

  complexForm: FormGroup;

  turnUserModel = {
    id:'',
    open:true,
    open_date:'',
    close_date:null,
    user:{
      id:''
    },
    turn:{
      id:''
    },
    clossing_amount:0,
    openning_amount:0,
    check:false,
    clossing_amount_user:0
  }

  object = JSON.parse(JSON.stringify(this.turnUserModel))
  user = {
    id:'',
    name:''
  }

  constructor(public dialogRef: MatDialogRef<AdminCloseTurnComponent>,
    private session: SessionService,
    public loadding:LoadingService,
    public fb: FormBuilder,
    public turnService:TurnService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.complexForm = fb.group({
        // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
        'clossing_amount_user': [null, Validators.required],
      });
    }

  ngOnInit(): void {
    this.user = JSON.parse(JSON.stringify(this.session.getSession().user));
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'turnInit:001';
    }
    this.dialogRef.close(object);
  }

}
