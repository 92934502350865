import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-toolbar',
  templateUrl: './admin-toolbar.component.html',
  styleUrls: ['./admin-toolbar.component.css']
})
export class AdminToolbarComponent implements OnInit {
  metadata = {
    toggleMenu: false,
  }
  @Output() menuToogle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  /*
  * funcion para abrir o cerrar le menu
  */
  toogleMenu(){
    this.metadata.toggleMenu = !this.metadata.toggleMenu;
    this.menuToogle.emit(this.metadata.toggleMenu);
  }

}
