<div class="row justify-content-end mt-0">
    <div class="col-1">
      <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
    </div>
    <div class="col-12">
      <h6>Agregar una nueva membrecia</h6>
      <p><small>Rellena el formulario de acuerdo al nuevo concepto a crear.</small></p>
    </div>
    <div class="col-12">
      <div class="row m-0">
        <div class="col-12">
          <div class="form-group">
            <label for="name" class="labelInputForm">Nombre</label>
            <input type="text" class="form-control" id="name" [(ngModel)]="object.name">
          </div>
        </div>
        <div class="col-8">
          <div class="form-group">
            <label for="name" class="labelInputForm">Descipción</label>
            <input type="text" class="form-control" id="description" [(ngModel)]="object.description">
          </div>
        </div>
        <div class="col-4">
            <div class="form-group">
              <label for="name" class="labelInputForm">Precio</label>
              <input type="number" class="form-control" id="price" [(ngModel)]="object.price">
            </div>
          </div>

      </div>
      <div class="row justify-content-end m-0">
        <div class="col-12 col-md-6">
          <button type="button" class="btn btn-primary btn-save" (click)="send();">Guardar</button>
        </div>
      </div>
    </div>
  </div>
  