<app-client-top></app-client-top>


<!-- Menu colapsado para solicitar datos -->
<div class="row justify-content-center m-0" style="margin-top: 30px!important;">
  <div class="col-md-10 col-12">
    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="toggle-1">

      <ngb-panel id="toggle-1">
        <ng-template ngbPanelTitle>
          <h2>Fecha y hora</h2>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-md-9 col-12">
              <div class="alert alert-warning">
                Selecciona la fecha para reservar.
                <strong *ngIf="clickedDate">Reservar el día: {{ clickedDate | date:'dd-MM-yyyy' }}</strong>
              </div>
              <div class="row justify-content-center">
                <div class="col-4 col-md-3">
                  <div class="btn-top-calendar" mwlCalendarPreviousView [view]="view"
        [(viewDate)]="viewDate">
                    <i class="bi bi-arrow-bar-left"></i> Antes
                  </div>
                </div>
                <div class="col-4 col-md-3">
                  <div class="text-center bg-success" style="border-radius: .25rem;">
                    <h5 class="text-white p-2" style="text-transform: capitalize;">{{ viewDate | date:'MMMM':'':'es'}}</h5>
                  </div>
                </div>
                <div class="col-4 col-md-3">
                  <div class="btn-top-calendar" mwlCalendarNextView [view]="view"
        [(viewDate)]="viewDate">
                    Después <i class="bi bi-arrow-bar-right"></i>
                  </div>
                </div>
              </div>

              <div>
                <mwl-calendar-month-view
                  [viewDate]="viewDate"
                  [events]="events"
                  [locale]="locale"
                  (columnHeaderClicked)="clickedColumn = $event.isoDayNumber"
                  (dayClicked)="daySelected($event);"
                  (beforeViewRender)="beforeMonthViewRender($event)"
                  >
                </mwl-calendar-month-view>
              </div>
            </div>
            <div class="">
              <!-- <ul>
                <li style="list-style-image: url(assets/imagenes/circulo-gris-04.svg)">No disponible</li>
                <li style="list-style-image: url(assets/imagenes/circulo-amarillo-05.svg)">Sólo algunos horarios</li>
                <li style="list-style-image: url(assets/imagenes/circulo-verde-06.svg)">Disponible</li>
              </ul> -->
            </div>
            <div class="col-md-3 col-12 select-horario" style="">
              <div class="alert alert-success" *ngIf="clickedDate">
                <strong >Reservar el día: {{ clickedDate | date:'dd-MMM-yyyy':'':'es' }}</strong>
              </div>
              <div class="alert alert-warning" *ngIf="!clickedDate">
                <div >En el calendario selecciona un dia para reservar</div>
              </div>
              <h6>Elije un horario</h6>
              <select class="form-control" name="horario" [(ngModel)]="object.scheduleBooking" (ngModelChange)="loadShoes(); loadAvalabilityExtra(); calculateAvalibility();">
                <option *ngFor="let shedul of shedules" [value]="shedul">{{shedul}}</option>
              </select>
              <div class="button-next" style="">
                <button type="button" class="siguiente" name="button" (click)="acc.toggle('toggle-2');  calculateTotal();" [disabled]="!clickedDate || object.scheduleBooking == '' || object.dateBooking == ''"><p style="color: #132069; padding-right: 2px;">Siguiente</p><img src="/assets/imagenes/flecha-03.svg" alt="" style="width: 2rem;"></button>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="toggle-2">
        <ng-template ngbPanelTitle >
          <h2>General</h2>
        </ng-template>

        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-md-4 col-12" style="padding: 5px;"><h6>Nombre</h6></div>
            <div class="col-md-6 col-12" style="padding: 5px;"> <input type="text"  value="Nombre" name="nombre" class="formulario" [(ngModel)]="metadata.name"> </div>
            <div class="col-md-4 col-12" style="padding: 5px;"><h6>Primer Apellido</h6></div>
            <div class="col-md-6 col-12" style="padding: 5px;"> <input type="text"  value="Apellido paterno" name="apellido-p" class="formulario" [(ngModel)]="metadata.firtlastname"> </div>
            <div class="col-md-4 col-12" style="padding: 5px;"><h6>Segundo Apellido</h6></div>
            <div class="col-md-6 col-12" style="padding: 5px;"> <input type="text"  value="Apellido materno" name="apellido-m" class="formulario" [(ngModel)]="metadata.secondlastname"> </div>
            <div class="col-md-4 col-12" style="padding: 5px;"><h6>Télefono</h6></div>
            <div class="col-md-6 col-12" style="padding: 5px;"> <input type="text"  value="Télefono" name="phone" class="formulario" [(ngModel)]="object.phone"> </div>
            <div class="col-md-4 col-12" style="padding: 5px;"><h6>Email</h6></div>
            <div class="col-md-6 col-12" style="padding: 5px;"> <input type="email"  value="Email" name="email" class="formulario" [(ngModel)]="object.email"> </div>
          </div>
          <div class="row" style="margin-top: 10px;">
            <div class="col-md-3 col-6" style="padding: 5px;"><h6 *ngIf="metadata.priceSelected.people == 1">Precio por persona</h6><h6 *ngIf="metadata.priceSelected.people > 1">Precio por cada {{metadata.priceSelected.people}} personas</h6></div>
            <div class="col-md-9 col-6" style="padding: 5px;"> <strong>{{metadata.priceSelected.unit_price | currency:'CAD':'symbol-narrow'}}</strong> </div>
            <div class="col-md-3 col-6" style="padding: 5px;"><h6>Tiempo</h6></div>
            <div class="col-md-9 col-6" style="padding: 5px;"> <strong *ngIf="metadata.priceSelected.time == 1"> {{metadata.priceSelected.time}} hora </strong> <strong *ngIf="metadata.priceSelected.time > 1"> {{metadata.priceSelected.time}} horas </strong> </div>
            <div class="col-md-3 col-6" style="padding: 5px;"><h6>Minimo de personas</h6></div>
            <div class="col-md-9 col-6" style="padding: 5px;"> <strong>{{metadata.priceSelected.people}}</strong> </div>
            <div class="col-md-3 col-6" style="padding: 5px;" *ngIf="metadata.priceSelected.shoes"><h6>Observaciones</h6></div>
            <div class="col-md-9 col-6" style="padding: 5px;" *ngIf="metadata.priceSelected.shoes"><strong>* Incluye patines</strong></div>
            <div class="col-md-3 col-6" style="padding: 5px;"><h6>Número de entradas</h6></div>
            <div class="col-md-2 col-6" style="padding: 5px;">
                <select class="form-control" name="acompanantes" [(ngModel)]="object.people" (ngModelChange)="calculateTotal();">
                <option *ngFor="let p of peopleList">{{p}}</option>
              </select>
            </div>

          <!-- <label class="col-12" style="margin-top:30px;text-align: right;">
            <input type="checkbox" name="sameadr" [(ngModel)]="object.terms"> Acepto los Términos y Condiciones
          </label>
          <label class="col-12" style="text-align: right;">
            <input type="checkbox" name="priv" [(ngModel)]="object.privacy"> Acepto la Política de Privacidad
          </label> -->
          </div>
          <div class="row justify-content-center" style="margin-top: 10px;">
            <div class="col-12 m-0 p-0">
              <h5 style="color: #ef8c20;">Extras</h5>
            </div>
            <div class="col-md-3 col-6" *ngFor="let object of bookingDetailList; let i = index;">
              <div class="form-group mb-0" *ngIf="object.type == 'Extra' && object.avalability.length > 0">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck{{i}}" [(ngModel)]="object.status" (change)="calculateTotal();">
                  <label class="form-check-label labelInputForm blue" for="gridCheck{{i}}">
                    {{object.concept}} | {{object.unit_price | currency:'CAD':'symbol-narrow'}} MXN
                    <small class="comments-min">Por persona</small> | <small class="comments-min">{{object.avalability.length}} Disponibles</small>
                  </label>
                </div>
              </div>
              <div class="form-group" *ngIf="object.type == 'Extra' && object.status">
                <label for="concept" class="labelInputForm">Cantidad</label>
                <select class="form-control" id="quantity{{i}}" name="quantity{{i}}" [(ngModel)]="object.quantity" (ngModelChange)="calculateTotal();">
                  <option *ngFor="let avalability of object.avalability" >{{avalability}}</option>
                </select>
              </div>
            </div>


          </div>
          <div class="row mt-2">
            <div style="position: absolute; bottom: 2px; right: 0px;">
              <!-- || !object.terms || !object.privacy -->
              <button type="button" class="siguiente" name="button" (click)="acc.toggle('toggle-2A'); calculateTotal(); loadShoesOnBookingDetails();" [disabled]="object.phone == '' || object.email == '' || metadata.name == '' || metadata.firtlastname == '' || metadata.secondlastname == '' "><p style="color: #132069; padding-right: 2px;">Siguiente</p><img src="/assets/imagenes/flecha-03.svg" alt="" style="width: 2rem;"></button>
            </div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="toggle-2A">
        <ng-template ngbPanelTitle>
          <h2>Equipo</h2>
        </ng-template>

        <ng-template ngbPanelContent>
          <div class="row justify-content-center" style="margin-top: 10px;">
            <div class="col-12 m-0 p-0">
              <h6>Ingresa los numeros de pares de patines</h6>
            </div>
            <ul class="col-12 col-10">
              <li *ngFor="let object of bookingDetailList; let i = index;">
                <div class="shoesItems">

                  <div class="form-group" *ngIf="object.type == 'Shoes' && object.status">
                    <label for="concept" class="labelInputForm">Nombre</label>
                    <div class="input-group">
                      <input type="text" class="form-control" id="nameShoes{{i}}" name="nameShoes{{i}}" [(ngModel)]="object.concept">
                    </div>
                  </div>

                  <div class="form-group" *ngIf="object.type == 'Shoes' && object.status">
                    <label for="inputState{{i}}" class="labelInputForm">Medida</label>
                    <select id="inputState{{i}}" class="form-control" name="days{{i}}" [(ngModel)]="object.size" (change)="checkShoesAvalaibility(object);">
                      <option *ngFor="let object of shoeList" value="{{object.size}}">No. {{object.size}} | {{object.quantity}} pares disponibles</option>
                    </select>
                  </div>

                </div>
                <small class="errorMsj" *ngIf="object.error">{{object.error}}</small>
              </li>
            </ul>
          </div>
          <div class="row mt-2">
            <label class="col-12" style="margin-top:30px;text-align: right;">
              <input type="checkbox" name="sameadr" [(ngModel)]="object.terms"> Acepto los Términos y Condiciones
            </label>
            <label class="col-12" style="text-align: right;">
              <input type="checkbox" name="priv" [(ngModel)]="object.privacy"> Acepto la Política de Privacidad
            </label>
            <div style="position: absolute; bottom: 2px; right: 0px;">
              <!-- || !object.terms || !object.privacy -->
              <button type="button" class="siguiente" name="button" (click)="acc.toggle('toggle-3'); calculateTotal(); finisBookingDetails();" [disabled]="object.phone == '' || object.email == '' || metadata.name == '' || metadata.firtlastname == '' || metadata.secondlastname == '' || !object.terms || !object.privacy "><p style="color: #132069; padding-right: 2px;">Siguiente</p><img src="/assets/imagenes/flecha-03.svg" alt="" style="width: 2rem;"></button>
            </div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="toggle-3" [disabled]="false">
        <ng-template ngbPanelTitle>
          <h2>Pago</h2>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="alert alert-warning col-12" role="alert" *ngIf="metadata.msj != ''">
              {{metadata.msj}}
            </div>
            <div class="col-12">
              <p>Reservación para el día <strong>{{object.dateBooking}}</strong> de <strong>{{object.scheduleBooking}}</strong>.</p>
              <p>A nombre de <strong>{{metadata.name + ' ' +  metadata.firtlastname + ' ' + metadata.secondlastname}}</strong></p>
              <p>con <strong>{{object.people}} entradas</strong></p>
            </div>
          </div>
          <div class="row justify-content-center" style="margin-top: 15px;">
            <div class="col-md-10 col-12" style="padding: 0px 10px; ">
              <table class="compra">
                <tr>
                  <th>Concepto</th>
                  <th>Precio U.</th>
                  <th>Cantidad</th>
                  <th>Subtotal</th>
                </tr>
                <tr *ngFor="let object of bookingDetailList">
                  <td *ngIf="object.status"> <span *ngIf="object.type == 'Shoes'"> Patines incluidos - </span> {{object.concept}}</td>
                  <td style="text-align: center;" *ngIf="object.status"> <span *ngIf="object.type != 'Shoes'">{{object.unit_price | currency:'CAD':'symbol-narrow'}}</span>  <span *ngIf="object.type == 'Shoes'"> Tamaño {{object.size}}  </span> </td>
                  <td style="text-align: center;" *ngIf="object.status">{{object.quantity}}</td>
                  <td style="text-align: center;" *ngIf="object.status"> <span *ngIf="object.type != 'Shoes'"> {{object.total | currency:'CAD':'symbol-narrow'}}</span>  <span *ngIf="object.type == 'Shoes'">  -- </span> </td>
                </tr>
              </table>
            </div>
            <div class="col-md-3 col-6" style="padding: 5px 10px; border: 2px solid #ececec;">
              <div class="" style="width: 100%;">
                <h5 style="float: left;">Total</h5> <h5 style="color: #132069; float: right;">{{object.total | currency:'CAD':'symbol-narrow'}}</h5>
              </div>
              <!-- <div class="" style="width: 100%;">
                <button type="button" name="button" class="btn-reserva">Reservar</button>
              </div> -->
            </div>
            <div class="col-12">
              <p class="pt-2 pb-2">Selecciona un método de pago</p>
              <div class="form-check" *ngFor="let paymentM of paymentMethods; let i = index;">
                <input class="form-check-input" type="radio" name="exampleRadios" id="paymentM.name" [value]="paymentM.selected" [(ngModel)]="object.type_payment" (ngModelChange)="changePaymentMethod();" [disabled]="paymentM == 'OXXO'">
                <div class="imgPaymentMethod">
                  <img [src]="paymentM.icon">
                </div> <strong>{{paymentM.name}}</strong>
              </div>
            </div>
            <div class="col-12" style="margin-top:30px;">
              <!-- seccion de paypal -->
              <div *ngIf="object.type_payment == 'PayPal'" class="col-12">
                <div id="paypal-button-container"></div>
              </div>
              <!-- session de mexpago -->
              <div class="" *ngIf="object.type_payment == 'Mexpago'">
                <form #myForm ngForm  [action]="'https://www.mexpago.com/app/pagoOnline'" method="post">
                  <input type="hidden" name="monto" value="{{mexpagoModel.monto}}">
                  <input type="hidden" name="noTransaccion" value="{{mexpagoModel.noTransaccion}}">
                  <input type="hidden" name="llave" value="{{mexpagoModel.llave}}">
                  <input type="hidden" name="fecha" value="{{mexpagoModel.fecha}}">
                  <input type="hidden" name="articulos" value= "{{mexpagoModel.articulos}}">
                  <input type="hidden" name="precargaDatos" value= "{{mexpagoModel.precargaDatos}}">
                  <input type="hidden" name="enviarCorreo" value= "{{mexpagoModel.enviarCorreo}}">
                  <input type="hidden" name="infoComercio" value= "{{mexpagoModel.infoComercio}}">
                  <input type="hidden" name="lenguaje" value= "{{mexpagoModel.lenguaje}}">
                  <button type="button" class="btn btn-success btn-lg btn-block" (click)="sendToPay();">Pagar</button>

                  <!-- <button type="submit" style="background: white; border: none; " (click)="sendSale();"> <img src="https://www.mexpago.com/img/btn-mexpago.png"> </button> -->
                </form>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
<app-client-footer></app-client-footer>
