<div class="row justify-content-end mt-0">
  <div class="col-12 mb-2">
    <h2 class="naranja">Finalizar un turno</h2>
    <p class="mt-3">
      Hola {{user.name}},
      para finalizar tu turno ingresa cuanto <strong>dinero</strong> tienes en caja
    </p>
  </div>
  <div class="col-12 pt-2 mb-2">
    <div><p>Monto de cierre de caja</p></div>
    <div class="input-group">
      <input class="form-control" type="number" [ngClass]="complexForm.controls['clossing_amount_user'].hasError('required') ? 'is-invalid':'is-valid'" type="text" placeholder="0.00" name="mName"  [(ngModel)]="object.clossing_amount_user" [formControl]="complexForm.controls['clossing_amount_user']" required>
      <div class="invalid-feedback">
        Campo requerido
      </div>
    </div>

</div>

  <div class="col-12">
    <div class="row justify-content-center m-0">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-close" (click)="close(false);">CANCELAR</button>
      </div>
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-save" (click)="close(true);" [disabled]="complexForm.invalid">CERRAR TURNO</button>
      </div>
    </div>
  </div>
</div>
