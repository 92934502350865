<div class="row baner-superior justify-content-center  m-0">
  <div class="col-12">
    <img src="assets/imagenes/logo_horizontal.png" alt="Tecnopista" class="logoClass" routerLink="/">
  </div>
  <div class="col-md-6 col-8">
    <h1 class="title">Reservación</h1>
  </div>
  <div class="col-4" style="text-align: end;">
    <!-- <img src="/assets/imagenes/Reservacion-mercury.png" alt="" style="width:50%;"> -->
  </div>
</div>
