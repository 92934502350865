<div class="row justify-content-end mt-0">
  <div class="col-1">
    <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
  </div>
  <div class="col-12">
    <h6>Agregar un nuevo concepto</h6>
    <p><small>Rellena el formulario deacuerdo al nuevo concepto a crear.</small></p>
  </div>
  <div class="col-12">
    <div class="row m-0">
      <div class="col-12">
        <div class="form-group">
          <label for="concept" class="labelInputForm">Concepto</label>
          <input type="text" class="form-control" id="concept" [(ngModel)]="object.description">
        </div>
      </div>
      <div class="col-12 mt-1">
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="gridCheck" [(ngModel)]="object.access">
            <label class="form-check-label labelInputForm" for="gridCheck">
              Concepto para acceso a la pista
            </label>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="gridCheck" [(ngModel)]="object.allDays">
            <label class="form-check-label labelInputForm" for="gridCheck">
              Aplica todos los dias.
            </label>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="!object.allDays">
        <div class="form-group">
          <label for="inputState" class="labelInputForm">Dia</label>
          <select id="inputState" class="form-control" name="days" [(ngModel)]="object.day">
            <option value="0">Domingo</option>
            <option value="1">Lunes</option>
            <option value="2">Martes</option>
            <option value="3">Miercoles</option>
            <option value="4">Jueves</option>
            <option value="5">Viernes</option>
            <option value="6">Sabado</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="concept" class="labelInputForm">Tiempo</label>
          <div class="input-group">
            <input type="number" class="form-control" id="time" [(ngModel)]="object.time">
            <div class="input-group-append">
              <div class="input-group-text">Hra</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="concept" class="labelInputForm">Precio</label>
          <div class="input-group">
            <input type="number" class="form-control" id="unit_price" [(ngModel)]="object.unit_price">
            <div class="input-group-append">
              <div class="input-group-text">MXN</div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="concept" class="labelInputForm">Cantidad de Personas</label>
          <input type="number" class="form-control" id="people" [(ngModel)]="object.people">
        </div>
      </div>
      <div class="col-12 mt-1">
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="gridCheckShoes" [(ngModel)]="object.shoes">
            <label class="form-check-label labelInputForm" for="gridCheckShoes">
              Incluye patines
            </label>
          </div>
        </div>
      </div>


    </div>
    <div class="row justify-content-end m-0">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-save" (click)="send();">Guardar</button>
      </div>
    </div>
  </div>
</div>
