import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-top',
  templateUrl: './client-top.component.html',
  styleUrls: ['./client-top.component.css']
})
export class ClientTopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
