import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../../../directive/loading/loading.module';
import { SessionService } from '../../../service/session/session.module';
import * as moment from 'moment';
declare var paypal;
moment.locale('es-us');

@Component({
  selector: 'app-reservation-update',
  templateUrl: './reservation-update.component.html',
  styleUrls: ['./reservation-update.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationUpdateComponent implements OnInit {
  @ViewChild('myForm') ngForm;

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  clickedDate: Date;
  clickedColumn: number;
  bookingModel = {
    id:'',
    sendEmail:true,
  	folio:'',
  	fullname:'',
  	phone:'',
    email:'',
  	dateBooking: moment().format('YYYY-MM-DD'),
  	scheduleBooking:'', //string 13:00 - 14:00,
  	people: 1,
  	bowlings: 1, // numero de lineas a reservar
  	peopleUnitPrice: 0,
  	bowlingsUnitPrice: 0,
  	discount: 0.00,
  	subtotal: 0.00,
  	tax: 0.00,
  	total: 0.00,
    terms: false,
    privacy:false,
  	type_payment:'', //mexpago, paypal, referencia
  	payment_id:'',
  	status:'Pagado', //pagado, pendiente, cancelado.
    bookingDetails:[]
  }
  bookingDetailModel = {
    id:'',
    concept:'',
    unit_price:0.00,
    quantity:0,
    discount:0.00,
    subtotal:0.00,
    tax: 0.00,
    total: 0.00,
    type:'',
    booking:{
      id:''
    },
    size:'',
    status:false,
  }
  priceObjectModel = {
    id:'',
    day:null,
    time:'1',
    description:'',
    unit_price:'',
    people:1,
    shoes:false,
    allDays:false,
    status:true,
    access:false
  }
  object = JSON.parse(JSON.stringify(this.bookingModel));
  metadata = {
    name:'',
    firtlastname:'',
    secondlastname:'',
    msj:'',
    priceSelected:JSON.parse(JSON.stringify(this.priceObjectModel))
  }
  paypalModel = {
    id:'',
    platform:'',
    product_name:'',
    paypal_id:'',
    intent:'',
    state:'',
    environment:'',
  }
  paymentMethods = [{
    icon:'/assets/img/paypal.png',
    name:'PayPal',
    selected:'PayPal'
  },{
    icon:'/assets/img/tarjeta.png',
    name:'Tarjeta de Débito/Crédito',
    selected:'Mexpago'
  },{
    icon:'/assets/img/oxxo.png',
    name:'OXXO',
    selected:'OXXO'
  }];
  locale: string = 'es';
  shedules = [
    '12:00 - 13:00',
    '13:15 - 14:15',
    '14:45 - 15:45',
    '16:00 - 17:00',
    '17:30 - 18:30',
    '18:45 - 19:45',
    '20:00 - 21:00',
  ];
  defaultShedules = JSON.parse(JSON.stringify(this.shedules));
  mexpagoModel = {
    monto:0,
    noTransaccion:'',
    llave:'',
    fecha:'',
    articulos:'',
    precargaDatos:'',
    enviarCorreo:false,
    infoComercio:true,
    lenguaje:'es',
  }
  mexpagoDB = {
    id:'',
    folioMexPago:'',
    numeroTransaccion:'',
    pago:'',
    monto:''
  }
  peopleList = [];
  priceMin = {
    status:true,
    max: 10,
    page: 1,
  }
  priceList = [];
  accessPriceList = [];
  otherChangerList = [];
  bookingDetailList = [];
  shoeList = [];
  constructor(protected session: SessionService, public loading: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadPrices();
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] == 'new') {

      }
    });
    // this.calculateAvalibility();
  }

  calculate(object) {
    this.object.total = (object.bowlings_unit_price * object.time) + (object.people_unit_price * object.people)
    this.object.subtotal = this.object.tax = this.object.total/1.16;
    this.object.tax = this.object.total - this.object.subtotal;
  }

  pay(object) {
    // console.log('cobrar', object);
  }

  daySelected(ev){
    // console.log(ev);
    let today = moment();
    let selectedDay = moment(ev.day.date);
    // ev.day.cssClass = 'bg-selected-day';
    // console.log(selectedDay.diff(today,'day'));
    if(selectedDay.diff(today,'day') >= 0){
      this.object.dateBooking = moment(ev.day.date).format('YYYY-MM-DD');
      this.clickedDate = ev.day.date;
      this.priceSelectedByDay();
      this.object.scheduleBooking = '';
    }else{
      console.log("Este dia no se puede seleccionar");
    }
    // console.log("------------",this.object);
  }
  /**función para calcular el totla
  */
  calculateTotal(){
    let tickets = 0;
    this.object.subtotal = 0;
    this.object.discount = 0;
    this.object.tax = 0;
    this.object.total = 0;

    //Reglas de precio de ticket
    //calculamos los ticket a utilizar para calcular el precio
    tickets = Math.round(this.object.people / this.metadata.priceSelected.people);
    // console.log("Tickets:::::::",tickets);
    this.object.subtotal = (tickets * this.metadata.priceSelected.unit_price)/1.16;
    this.object.tax = (tickets * this.metadata.priceSelected.unit_price) - this.object.subtotal;


    //Verificamos si hay extra agregados
    for(let  i =0; i < this.bookingDetailList.length; i++){
      this.bookingDetailList[i].discount = 0;
      this.bookingDetailList[i].subtotal = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price)/1.16;
      this.bookingDetailList[i].tax = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price) - this.bookingDetailList[i].subtotal;
      this.bookingDetailList[i].total = this.bookingDetailList[i].subtotal + this.bookingDetailList[i].tax - this.bookingDetailList[i].discount;
      if(this.bookingDetailList[i].status && this.bookingDetailList[i].type== 'Extra' ){
        this.object.subtotal = this.object.subtotal + this.bookingDetailList[i].subtotal;
        this.object.tax = this.object.tax + this.bookingDetailList[i].tax;
      }
    }

    // this.object.subtotal = ((this.object.people * this.object.peopleUnitPrice) + (this.object.bowlings * this.object.bowlingsUnitPrice))/1.16;
    // this.object.tax = ((this.object.people * this.object.peopleUnitPrice) + (this.object.bowlings * this.object.bowlingsUnitPrice)) - this.object.subtotal;
    this.object.total =  this.object.subtotal + this.object.tax - this.object.discount;
    // console.log(this.object);
  }

  /************ Funciones de Pagos *******************/
    /** función para cambiar el methodo de pago
    */
   async changePaymentMethod(){
     this.object.fullname =  this.metadata.name+' '+this.metadata.firtlastname+' '+this.metadata.secondlastname;
     this.object.bookingDetails = [];
     for(let item of this.bookingDetailList){
       if(item.status)
        this.object.bookingDetails.push(item);
     }
      switch(this.object.type_payment){
        case 'PayPal':{
          this.metadata.msj = '';
          this.object.payment = JSON.parse(JSON.stringify(this.paypalModel));
            setTimeout(()=>{
              paypal.Buttons({
               createOrder: (data, actions) => {
                 // This function sets up the details of the transaction, including the amount and line item details.
                 return actions.order.create({
                   purchase_units: [{
                     amount: {
                       value: this.object.total.toString()
                     }
                   }]
                 });
               },
               onApprove: (data, actions) => {
                 // console.log("dataPaypal",data);
                 this.loading.show(true,"Espere un momento...");
                 return actions.order.capture().then(async(details)=> {
                   // console.log("paypalDetalle",details)
                  // This function shows a transaction success message to your buyer.
                  if(details.status == 'COMPLETED'){

                    // Successfully paid -- bindeamos los datos para envia a la BD
                    this.object.payment.platform = 'WEB';
                    this.object.payment.product_name = details.payer.payer_id;
                    this.object.payment.paypal_id = details.id;
                    this.object.payment.intent = details.intent;
                    this.object.payment.state = details.status;
                    this.object.payment.environment = 'WEB';
                    // enviamos los datos a la BD;
                    try{
                      this.object.status = "Pagado";
                      let sale:any = await this.sendSale();
                      this.metadata.msj ="Gracias por tu compra";

                      // console.log("Enviamos el objeto",this.object);
                      // this.openSearchSale(sale.folio,sale.email);
                      this.loading.hide();
                      this.router.navigate(['/client/search/'+sale.phone]);
                    }catch(e){
                      this.metadata.msj = "Hubo un error al procesar los datos, guarde la siguiente informacion y contacte con Natura, paypal_id: " + this.object.payment.paypal_id;
                      this.router.navigate(['/']);
                      this.loading.hide();
                    }

                  }else{
                    this.metadata.msj = "Error: Fallo en el modo de pago, intento con otra forma de pago.";
                  }
                });
              }
             }).render('#paypal-button-container');
           }, 200);
          break;
        }
        case 'Mexpago':{
          this.metadata.msj = '';
          this.mexpagoModel.monto = this.object.total;
          this.mexpagoModel.fecha = moment().format('YYYY-MM-DD hh:mm:ss');
          this.mexpagoModel.llave = 'eyJsbGF2ZSI6Ijc2ZDljZjJhLTU5ZjYtNDY1Mi05YWVmLTY2OTNlYmIwYjRmMyIsImlkT25saW5lIjoiOGI2YzBlNWEtZmEyZC00MjUxLWJiZGMtYzA5N2QzZDRhMzZhIn0=';
          this.mexpagoModel.lenguaje = 'es';
          let precargaDatos = {
            nombre:'',
            apPaterno:'',
            apMaterno:'',
            celular:'',
            correo:''
          };
          precargaDatos.nombre = this.metadata.name;
          precargaDatos.apPaterno = this.metadata.firtlastname;
          precargaDatos.apMaterno = this.metadata.secondlastname;
          precargaDatos.celular = this.object.phone;
          precargaDatos.correo = this.object.email;
          this.mexpagoModel.precargaDatos = JSON.stringify(precargaDatos);
          this.mexpagoModel.enviarCorreo = false;
          this.mexpagoModel.infoComercio = true;
          let articulos = {
            articulos:[
              // {
              //   descripcion:'Boliche por hora',
              //   monto:Math.round((this.object.bowlings * this.object.bowlingsUnitPrice)*100)/100,
              // },{
              //   descripcion:'Renta de zapatos',
              //   monto:Math.round((this.object.people * this.object.peopleUnitPrice)*100)/100,
              // },
            ]
          };
          for(let objectBookingDetails of this.object.bookingDetails){
            if(objectBookingDetails.type != 'Shoes'){
              articulos.articulos.push({
                descripcion:objectBookingDetails.concept,
                monto:Math.round((objectBookingDetails.total)*100)/100,
              })
            }
          }
          this.mexpagoModel.articulos = JSON.stringify(articulos);
          this.mexpagoModel.noTransaccion = 'TECNO-'+Math.floor(Math.random() * (9999999-100000) + 100001).toString();
          this.object.payment = JSON.parse(JSON.stringify(this.mexpagoDB));
          this.object.payment.numeroTransaccion = this.mexpagoModel.noTransaccion;
          this.object.status = "Pendiente";
          // console.log("Mexpago",this.object);
          break;
        }
        default:{
          // console.log("Error, selecciona un metodo de pago");
          this.metadata.msj = "Error, selecciona un metodo de pago";
          break;
        }
      }
    }

    /** función para enviar lso datos de venta al backend
    */
    sendSale(){
      return new Promise((resolve,reject)=>{
        this.session.postRequest("booking:update",this.object).subscribe((data:any)=>{
          // WTF!! son las 1:25AM y parece que ya va a quedar.
          // console.log("WTF al fin!!",data);
          resolve(data.object);
        },error=>{
          // console.log("error:sale:update",error);
          reject(error);
        })
      })
    }

    /**fucnionalidad para enviar venta de mexpago
    */
    async sendToPay(){
      this.loading.show(true,"Espere un momento...");
      let sale:any = await this.sendSale();
      this.loading.hide();
      this.ngForm.nativeElement.submit();
    }

    /**watcher para determinar un cambio en el calendario
    */
    beforeMonthViewRender(renderEvent:CalendarMonthViewBeforeRenderEvent){
      renderEvent.body.forEach((day) => {
        const dayOfMonth = day.date;
        let today = new Date();
        if (dayOfMonth < today) {
          day.cssClass = 'bg-disabled';
        }
        if (dayOfMonth > today) {
          day.cssClass = 'bg-enabled';
        }
        if(today.getDate() == dayOfMonth.getDate() && today.getMonth() == dayOfMonth.getMonth()){
          day.cssClass = 'bg-enabled';
        }
      });
    }


    /**funcionalidad para obetner la lista de precios vigente
    */
    loadPrices(){
      this.session.postRequest("price_list:findAllByStatus",this.priceMin).subscribe((data:any)=>{
        this.priceList = data.object.instanceList;
        this.accessPriceList = this.priceList.filter(object => object.access == true);
        this.otherChangerList = this.priceList.filter(object => object.access == false);
        this.preloadBookingDetails();
        // console.log("priceList",this.priceList);
        // console.log("accessPriceList",this.accessPriceList);
        // console.log("otherChangerList",this.otherChangerList);

      },error=>{
        console.log("error:price_list:findAllByStatus",error);
      })
    }

    /**funcionalida para cargar el precio de la reservación
    */
    priceSelectedByDay(){
      let daySelected = this.clickedDate;
      let priceAccess:any = {};
      for(let item of this.accessPriceList){
        if(item.allDays){
          priceAccess = JSON.parse(JSON.stringify(item));
          break;
        }
        if(item.day == daySelected.getDay()){
          priceAccess = JSON.parse(JSON.stringify(item));
        }
      }
      this.metadata.priceSelected = priceAccess;
      this.shedules = JSON.parse(JSON.stringify(this.defaultShedules));
      //verificamos si el dia el fin de semana para agregar horarios extras
      if(this.metadata.priceSelected.day == 0 || this.metadata.priceSelected.day == 6  ){
        this.shedules.unshift('10:15 - 11:15');
        this.shedules.unshift('9:00 - 10:00');
      }
      // this.calculateAvalibility();
      // console.log(">>>>>>>>>>>",priceAccess);
    }

    /** fucnionalidad para cargar cuando espacios hay disponibles en la hora de pista seleccionada
    */
    calculateAvalibility(){
      this.peopleList = [];
      if(this.object.dateBooking != ''){
        this.session.postRequest("booking:findSpaceByScheduleAndDate",{date_booking:this.object.dateBooking,schedule_booking:this.object.scheduleBooking}).subscribe((data:any)=>{
          let start = 1;
          let ends = 50 - data.object
          for(let i =start; i <= ends; i++){
            this.peopleList.push(i);
          }
          // console.log("Disponilidad de espacios::::",this.peopleList.length);
        },error=>{
          console.log("error:booking:findSpaceByScheduleAndDate",error);
        })
      }
    }

    /**funcionalidad para precargar datos el bookigndetails
    */
    preloadBookingDetails(){
      this.bookingDetailList = [];
      //calculamos disponibilidad de monitos, instructores
      for(let i = 0; i < this.otherChangerList.length; i++){
        if(this.otherChangerList[i].description == 'Instructor personalizado'){
          this.otherChangerList[i].avalability = [1,2,3,4];
        }else{
          this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8];
        }
      }
      for(let object of this.otherChangerList){
        let promo = object.description.toUpperCase().indexOf('PROMO');
        if(promo == -1){
          let aux = JSON.parse(JSON.stringify(this.bookingDetailModel));
          aux.concept = object.description;
          aux.unit_price = object.unit_price;
          aux.quantity = object.people;
          aux.type = 'Extra';
          aux.avalability = object.avalability;
          this.bookingDetailList.push(aux);
        }

      }
    }

    /**función para cargar disponibilidad de los articulos extras
    */
    getAvalabitlityByConcept(object){
      let concepts = [];
      for(let item of object){
        if(item.type == 'Extra'){
          concepts.push(item.concept);
        }
      }
      this.session.postRequest("booking:findStockByScheduleAndConceptList",{date_booking:this.object.dateBooking,schedule_booking:this.object.scheduleBooking,concept:concepts}).subscribe((data:any)=>{
        for(let item of data.object){
          for(let i = 0; i < object.length; i++){
            if(item.concept == object[i].concept ){
              for(let j = 1 ; j <= item.used; j++){
                object[i].avalability.pop();
              }
            }
          }
        }
        // for(let i = 1 ; i <= data.object; i++){
        //   object.avalability.pop();
        // }
      },error=>{
        console.log("error:booking:findStockByScheduleAndConceptList",error);
      })
    }

    /**funcionalidad para cargar los zapatos seleccionados
    */
    loadShoesOnBookingDetails(){
      // incializamos lo ncesario para el detalle de zapatos del booking
      for(let i = 0; i < this.bookingDetailList.length; i++){
        if(this.bookingDetailList[i].type == 'Shoes'){
          this.bookingDetailList.splice(i,1);
          i--;
        }
      }
      for(let j = 0; j < this.object.people; j++){
        let aux = JSON.parse(JSON.stringify(this.bookingDetailModel));
        aux.unit_price = 0;
        aux.quantity = 1;
        aux.type = "Shoes";
        aux.status = true;
        this.bookingDetailList.push(aux);
      }
    }

    /**funcioanalidad para obetner los zapatos
    */
    loadShoes(){
      this.session.postRequest("shoes:findAllByStatus",{status:true}).subscribe((data:any)=>{
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].use = 0;
        }
        this.shoeList = data.object.instanceList;
        this.getShoesAvalabotlity(this.shoeList);
        // for(let i = 0; i < this.shoeList.length; i++){
        //   this.getShoesAvalabotlity(this.shoeList[i]);
        // }
        // console.log(this.shoeList);
      },error=>{
        console.log("error:price_list:findAllByStatus",error);
      })
    }

    /**Cargamos las disponibilidad de lo extra
    */
    loadAvalabilityExtra(){
      //Aqui verificamos
      for(let i = 0; i < this.bookingDetailList.length; i++){
        if(this.bookingDetailList[i].type == 'Extra'){
          if(this.bookingDetailList[i].concept == 'Instructor personalizado'){
            this.bookingDetailList[i].avalability = [1,2,3,4];
          }else{
            this.bookingDetailList[i].avalability = [1,2,3,4,5,6,7,8];
          }
          if(this.bookingDetailList[i].concept == 'Calcetines'){
            this.bookingDetailList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30];

          }
          if(this.bookingDetailList[i].concept == 'Lockers'){
            this.bookingDetailList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30,31,32];
          }
        }
      }
      this.getAvalabitlityByConcept(this.bookingDetailList)
    }


    getShoesAvalabotlity(object){
      let sizes = [];
      for(let item of object){
        sizes.push(item.size);
      }
      this.session.postRequest("booking:findShoesSizeByScheduleList",{date_booking:this.object.dateBooking,schedule_booking:this.object.scheduleBooking, size:sizes}).subscribe((data:any)=>{
        for(let item of data.object){
          for(let i =0; i <object.length; i++){
            if(item.size == object[i].size){
              object[i].avalability = item.used;
              object[i].quantity = object[i].quantity - object[i].avalability;
            }
          }
        }

      },error=>{
        console.log("error:booking:findShoesSizeByScheduleList",error);
      })
    }

    /**funcionalidad para saber si todavia es posible seleccionar el par de patines
    */
    checkShoesAvalaibility(object){
      for(let i = 0; i < this.shoeList.length; i++){
        if(object.size == this.shoeList[i].size){
          let avalability = this.shoeList[i].quantity - this.shoeList[i].use;
          if(avalability > 0){
            this.shoeList[i].use++;
          }else{
            object.size = '';
            object.error = "Lo sentimos ya no hay disponilidad para este par, seleccione otro por favor.";
          }
        }
      }
    }

  /**Funcionalidad para terminar el detalle del booking
  */
  finisBookingDetails(){
    for(let i = 0; i < this.bookingDetailList.length; i++){
      if(this.bookingDetailList[i].type == 'Ticket'){
        this.bookingDetailList.splice(i,1);
      }
    }
    let aux = JSON.parse(JSON.stringify(this.bookingDetailModel));
    aux.concept = this.metadata.priceSelected.description;
    aux.unit_price = this.metadata.priceSelected.unit_price;
    // let tickets = Math.round(this.object.people / this.metadata.priceSelected.people);
    let tickets = this.object.people;
    aux.quantity = tickets;
    aux.subtotal = (tickets * this.metadata.priceSelected.unit_price)/1.16;
    aux.tax = (tickets * this.metadata.priceSelected.unit_price) - aux.subtotal;
    aux.total = aux.subtotal + aux.tax;
    aux.status = true;
    aux.type = "Ticket";
    this.bookingDetailList.unshift(aux);
  }
}
