import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ViewEncapsulation, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../directive/loading/loading.module';
import { SessionService } from '../service/session/session.module';
import * as moment from 'moment';
moment.locale('es-us');
import * as Rx from 'rxjs/Rx';
import { MatDialog } from '@angular/material/dialog';
import * as printJS from 'print-js';
import { TurnService } from '../admin/turn.service';
import { AdminCloseTurnComponent } from '../admin/admin-close-turn/admin-close-turn.component';
import { AdminBookingConfirmComponent } from '../admin/admin-booking-confirm/admin-booking-confirm.component';
import { AdminInitTurnComponent } from '../admin/admin-init-turn/admin-init-turn.component';
import { PointSalePaymentComponent } from './point-sale-payment/point-sale-payment.component';
import { AdminBookingSuccessComponent } from '../admin/admin-booking-success/admin-booking-success.component';

@Component({
  selector: 'app-point-sale',
  templateUrl: './point-sale.component.html',
  styleUrls: ['./point-sale.component.css']
})
export class PointSaleComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  bookingModel = {
    id:'',
    sendEmail:false,
  	folio:'',
  	fullname:'PUBLICO EN GENERAL',
  	phone:'0000000000',
    email:'cobranza@tecnomoda.mx',
  	dateBooking: moment().format('YYYY-MM-DD'),
  	scheduleBooking:'00:00 - 00:00', //string 13:00 - 14:00,
  	people: 1,
  	bowlings: 1, // numero de lineas a reservar
  	peopleUnitPrice: 0,
  	bowlingsUnitPrice: 0,
  	discount: 0.00,
  	subtotal: 0.00,
  	tax: 0.00,
  	total: 0.00,
    terms: true,
    privacy:true,
  	type_payment:'Efectivo', //mexpago, paypal, referencia
  	payment_id:'',
  	status:'Pagado', //pagado, pendiente, cancelado.
    bookingDetails:[],
    point_sale:true,
    total_payment:0,
    total_change:0
  }
  bookingDetailModel = {
    id:'',
    concept:'',
    unit_price:0.00,
    quantity:0,
    discount:0.00,
    subtotal:0.00,
    tax: 0.00,
    total: 0.00,
    type:'',
    booking:{
      id:''
    },
    size:'',
    status:true,
  }
  object = JSON.parse(JSON.stringify(this.bookingModel));
  locale: string = 'es';
  priceMin = {
    status:true,
    max: 10,
    page: 1,
  }
  priceList = [];
  otherChangerList = [];
  bookingDetailList = [];

  metadata = {
    turnOpen:false,
    role:''
  }
  paymentMethods = [
    {
      name:'Efectivo',
      value:'Efectivo',
      icon:'bi bi-cash'
    },{
      name:'Tarjeta',
      value:'Tarjeta',
      icon:'bi bi-credit-card'
    },{
      name:'Cortesía',
      value:'Cortesia',
      icon:'bi bi-person-badge'
    }
  ];
  user = {
    id:'',
    name:''
  }
  constructor(
    protected session: SessionService,
    public loading: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public turnService:TurnService
  ) { }

  async ngOnInit() {
    let roles = this.session.getRoles();
    this.metadata.role = roles[0]
    this.user =  JSON.parse(JSON.stringify(this.session.getSession().user))
    this.checkAndGetTurn();
    await this.loadPrices();
  }

  /**funcinalidad para inicializar el punto de venta
  */
  initPointSale(sale){
    sale.point_sale = sale.poin_sale;
    this.turnService.addBookingToTurn(sale);
    const dialogRef = this.dialog.open(AdminBookingSuccessComponent, {
      width: '40rem',
      disableClose:true,
      data:sale
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.object = JSON.parse(JSON.stringify(this.bookingModel));
          this.bookingDetailList = [];
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }


  /**funcionalidad para agregar un producto a cobrar
  */
  addBookingDetail(object){
    let bookingDetailAux = JSON.parse(JSON.stringify(this.bookingDetailModel));
    bookingDetailAux.concept = object.description;
    bookingDetailAux.unit_price = object.unit_price*1;
    bookingDetailAux.quantity = 1;
    bookingDetailAux.type = 'Extra';
    this.bookingDetailList.push(bookingDetailAux);
    this.calculateTotal();
  }

  /**funcionalidad para eliminar un item de los detalles de ventas
  */
  deleteAItem(object){
    this.bookingDetailList = this.bookingDetailList.filter((item)=>{
      return item.concept != object.concept;
    })
    this.calculateTotal();
  }


  /**funcionalidad para mdificar la cantidad de un producto
  */
  changeQuantity(type,object){
    type == 'min' ? object.quantity-- : object.quantity++;
    if(object.quantity == 0){
      this.deleteAItem(object);
    }
    this.calculateTotal();
  }




  /**Metodos de reservacion ==================================
  */

  // pay(object) {
  // }
  //
  // daySelected(ev){
  //   let today = moment();
  //   let selectedDay = moment(ev.day.date);
  //   // ev.day.cssClass = 'bg-selected-day';
  //   if(selectedDay.diff(today,'day') >= 0){
  //     this.object.dateBooking = moment(ev.day.date).format('YYYY-MM-DD');
  //     this.clickedDate = ev.day.date;
  //     this.priceSelectedByDay();
  //     this.object.scheduleBooking = '';
  //   }else{
  //     console.log("Este dia no se puede seleccionar");
  //   }
  //   // console.log("------------",this.object);
  // }
  /**función para calcular el totla
  */
  calculateTotal(){
    this.object.subtotal = 0;
    this.object.discount = 0;
    this.object.tax = 0;
    this.object.total = 0;


    //Verificamos si hay extra agregados
    for(let  i =0; i < this.bookingDetailList.length; i++){
      this.bookingDetailList[i].subtotal = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price)/1.16;
      this.bookingDetailList[i].tax = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price) - this.bookingDetailList[i].subtotal;
      this.bookingDetailList[i].total = this.bookingDetailList[i].subtotal + this.bookingDetailList[i].tax - this.bookingDetailList[i].discount;
      this.object.subtotal = this.object.subtotal + this.bookingDetailList[i].subtotal;
      this.object.tax = this.object.tax + this.bookingDetailList[i].tax;
    }
    this.object.total =  this.object.subtotal + this.object.tax - this.object.discount;
  }

  /************ Funciones de Pagos *******************/
    /** función para cambiar el methodo de pago
    */
   async sendToPayEfective(){
     this.object.fullname =  "PUNTO DE VENTA";
     this.object.bookingDetails = [];
     for(let item of this.bookingDetailList){
        this.object.bookingDetails.push(item);
     }
      switch(this.object.type_payment){
        case 'Tarjeta':
        case 'Efectivo':{
          this.object.payment = {};
          this.object.status = "Pagado";
          this.loading.show(true,"Espere un momento...");
          let sale:any = await this.sendSale();
          //funcionalidad para enviar a imprimir
          // this.print(sale);
          this.initPointSale(sale);
          this.loading.hide();
          console.log("Gracias por tu compra Incializar venta de nuevo");
          break;
        }
        case 'Cortesia':{
          const dialogRef = this.dialog.open(AdminBookingConfirmComponent, {
            width: '36rem',
            disableClose:true,
          });
          dialogRef.afterClosed().subscribe(async(result) => {
            if(result != undefined ){
              if(result.transaction == 'ok'){
                // El modal se cerro con objeto
                this.object.payment = {};
                this.object.status = "Pagado";
                //agregamos la cortesia para pasar los saldos a cero
                let auxBooking = JSON.parse(JSON.stringify(this.bookingDetailModel));
                auxBooking.concept = "Cortesía";
                auxBooking.discount = this.object.total;
                auxBooking.status = true;
                auxBooking.type = "Cortesia";
                //verificamos los totales
                this.object.discount = this.object.total;
                auxBooking.total = auxBooking.subtotal + auxBooking.tax - auxBooking.discount;
                this.object.total =  this.object.subtotal + this.object.tax - this.object.discount;
                //fin los totales
                this.object.bookingDetails.push(auxBooking);
                this.loading.show(true,"Espere un momento...");
                let sale:any = await this.sendSale();
                //funcionalidad para enviar a imprimir
                // this.print(sale);
                this.initPointSale(sale);
                this.loading.hide();
                console.log("Gracias por tu compra reiniciar venta");
              }else{
                // El modal se cerro sin objeto
              }
            }else{
              // El modal se cerro sin seleccionar algo, dandole click fuera
            }
          });
          break;
        }
        default:{
          console.log("Error, selecciona un metodo de pago");
          break;
        }
      }
    }

    /** función para enviar lso datos de venta al backend
    */
    sendSale(){
      return new Promise((resolve,reject)=>{
        this.session.postRequest("booking:update",this.object).subscribe((data:any)=>{
          // WTF!! son las 1:25AM y parece que ya va a quedar.
          // QUE RAYOS--> ahora son las 12:31 pm 27 de mayo 2022.
          // console.log("WTF al fin!!",data);
          resolve(data.object);
        },error=>{
          // console.log("error:sale:update",error);
          reject(error);
          this.loading.hide();
        })
      })
    }




    /**funcionalidad para obetner la lista de precios vigente
    */
    loadPrices(){
      return new Promise((resolve,reject)=>{
        this.session.postRequest("price_list:findAllByStatus",this.priceMin).subscribe((data:any)=>{
          this.priceList = data.object.instanceList;
          this.otherChangerList = this.priceList.filter(object => object.access == false);
          // console.log("priceList",this.priceList);
          // console.log("accessPriceList",this.accessPriceList);
          console.log("otherChangerList",this.otherChangerList);
          resolve(true);
        },error=>{
          console.log("error:price_list:findAllByStatus",error);
          reject(error);
        })
      })

    }



    /**función para cargar disponibilidad de los articulos extras
    */
    getAvalabitlityByConcept(object){
      let concepts = [];
      for(let item of object){
        if(item.type == 'Extra'){
          concepts.push(item.concept);
        }
      }
      this.session.postRequest("booking:findStockByScheduleAndConceptList",{date_booking:this.object.dateBooking,schedule_booking:this.object.scheduleBooking, concept:concepts}).subscribe((data:any)=>{
        for(let item of data.object){
          for(let i = 0; i < object.length; i++){
            if(item.concept == object[i].concept ){
              for(let j = 1 ; j <= item.used; j++){
                object[i].avalability.pop();
              }
            }
          }
        }
        // for(let i = 1 ; i <= data.object; i++){
        //   object.avalability.pop();
        // }
      },error=>{
        console.log("error:booking:findStockByScheduleAndConceptList",error);
      })
    }



  print(object){
    this.loading.show(true,'Espere un momento');
    this.session.postRequest("booking:downloadTicket",{id:object.id}).subscribe((data:any)=>{
      printJS({printable:data.object, type: 'pdf', base64: true});
      this.loading.hide();
    },error=>{
      this.loading.hide();
    });
  }


/****
* Inicia seccion de métodos del turno
**/

/** funcionalidad para saber si ya existe un turno iniciado
*/
async checkAndGetTurn(){
  let username = this.session.getSession().user.email;
  let rol = this.session.getRoles()[0];
  if(rol != 'ADMINISTRATOR' && rol != 'SUPERVISOR'){
    let checkTurn:any = await this.turnService.checkTurn();
    this.metadata.turnOpen = checkTurn;
    if(!checkTurn){
      // como no tenemos un turno iniciado... procedemos a inicializar uno
      await this.turnService.openTurn();
    }

    let checkTurnUser = await this.turnService.checkTurnUser();

    if(!checkTurnUser){
      // como no hay ningun turno de usuario iniciado
      const dialogRef = this.dialog.open(AdminInitTurnComponent, {
        width: '36rem',
        height: '80%',
        disableClose:true
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
          }else{
            // El modal se cerro sin objeto
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

  }

}

/**funcionalidad para cerra el turno
*/
async closeSession(){
  try{
    let username = this.session.getSession().user.email;
    if(username != 'administrador@sti.mx'){
      if(this.turnService.turnUser.id != ''){
        const dialogRef = this.dialog.open(AdminCloseTurnComponent, {
          width: '36rem',
          height: '80%',
          disableClose:true
        });
        dialogRef.afterClosed().subscribe(async(result) => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              let fisheTurn:any = await this.turnService.closeTurn(result.object.clossing_amount_user);
              this.router.navigate(['/admin/bye/'+fisheTurn.turn.id+'/'+fisheTurn.id]);
            }else{
              // El modal se cerro sin objeto
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
          }
        });
      }else{
        this.router.navigate(['login']);
      }
    }else{
      this.router.navigate(['login']);
    }

  }catch(e){
    console.log(e);
    this.router.navigate(['login']);
  }
}

/**funcionalidad para abrir el modal de pago
*/
openPayment(){
  const dialogRef = this.dialog.open(PointSalePaymentComponent, {
    width: '56rem',
    data:{object:this.object}
  });
  dialogRef.afterClosed().subscribe(async(result) => {
    if(result != undefined ){
      if(result.transaction == 'ok'){
        console.log(result.object);
        this.object = result.object.object;
        this.sendToPayEfective();
      }else{
        // El modal se cerro sin objeto
      }
    }else{
      // El modal se cerro sin seleccionar algo, dandole click fuera
    }
  });
}

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
