<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-admin-toolbar (menuToogle)="toogleMenu($event)"></app-admin-toolbar>

    <!-- aqui va el contenido -->
    <div class="row justify-content-center m-0" style="margin-top: 30px!important;">
      <div class="col-12 col-md-5">
      </div>
      <div class="col-12 col-md-5" style="text-align: end;">
        <span style="color: #021b6b;font-weight: bolder;text-align: right;">{{user.name}}</span> &nbsp;&nbsp; <span class="badge bg-success">Turno Abierto</span>
        <div class=" btnCloseTurn"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Salir y cerrar mi turno" (click)="closeSession();">
          <span>Cerrar Turno</span> <i class="bi bi-door-closed-fill" style=" font-size: 1.4rem;"></i>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="boxProducts">
          <div class="row">
            <div class="col-12">
              <h5 class="mt-2 ml-2">Articulos</h5>
            </div>
            <div class="col-3 pt-3">
              <div class="boxReserv" routerLink="/admin/dashboard">
                <i class="bi bi-calendar-check"></i>
                  <p>Reservaciones</p>
                  <p><strong>&nbsp;</strong></p>
              </div>
            </div>
            <div class="col-2 pt-3" *ngFor="let object of otherChangerList">
              <div class="boxProduct" (click)="addBookingDetail(object);">
                <i class="bi bi-snow2"></i>
                <p>{{object.description}}</p>
                <p><strong>{{object.unit_price | currency:'CAD':'symbol-narrow'}}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="boxItemToBuy">
          <div class="" style="overflow-y: scroll;">
            <ul style="list-style-type:none;">
              <li class="productItem" *ngFor="let object of bookingDetailList; let i = index;">
                <div class="productQuantity">
                  <div class="input-group">
                    <button class="btn btn-outline-secondary btn-sm" style="border: none;" type="button" (click)="changeQuantity('min',object);"><i class="bi bi-dash"></i></button>
                    <input  class="form-control" type="number" placeholder="Cant." name="bquantyity{{i}}" [(ngModel)]="object.quantity">
                    <button class="btn btn-outline-secondary btn-sm" type="button" style="border: none;" (click)="changeQuantity('add',object);"><i class="bi bi-plus"></i></button>
                  </div>
                </div>
                <div class="productName">
                  {{object.concept}}
                </div>
                <div class="price">
                  {{object.total | currency:'CAD':'symbol-narrow'}}
                </div>
                <div class="" style="cursor:pointer; color:#e91e63;" (click)="deleteAItem(object);">
                  <i class="bi bi-trash"></i>
                </div>
              </li>
            </ul>
          </div>
          <div class="" *ngIf="bookingDetailList.length == 0">
            <p>No hay articulos agregados</p>
          </div>

          <div class="btnFinal">
            <div class="textTotalFinal">
              <strong>TOTAL</strong>
              <span>{{object.total | currency:'CAD':'symbol-narrow'}}</span>
            </div>
            <button type="button" class="btn btn-success btn-lg btn-block" (click)="openPayment()" [disabled]="object.total == 0">PAGAR</button>
          </div>

        </div>
      </div>
    </div>
    <app-client-footer></app-client-footer>
  </mat-drawer-content>
</mat-drawer-container>
