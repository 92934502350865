<app-client-top></app-client-top>
<div class="row justify-content-center m-0" style="margin-top: 30px!important;">
  <div class="alert alert-success" role="alert" *ngIf=" object.status == 'Pagado'"> Reservación pagada </div>
  <div class="alert alert-danger" role="alert" *ngIf=" object.status == 'Pendiente'">Pago pendiente</div>
  <span class="col-12" style="text-align: center;"><h3>Gracias por tu preferencia.</h3></span>
  <div class="col-6">
    <div class="col-4" style="padding: 5px;"><h6>Folio</h6></div>
    <div class="col-6" style="padding: 5px;"> <strong>{{object.folio}}</strong> </div>
    <div class="col-12" style="padding: 5px; text-align: right;">
      <h6>Fecha: <strong>{{object.dateBooking | date:'dd-MM-yyyy'}} | {{object.scheduleBooking}}</strong></h6>
    </div>
    <div class="col-12" style="padding: 5px; text-align: left;">
      <h6>Cliente: <strong>{{object.fullname}}</strong></h6>
      <h6>Télefono: <strong>{{object.phone}}</strong></h6>
      <table class="compra mt-3">
        <tr>
          <th>Servicio/hora</th>
          <th>Precio U.</th>
          <th>Cantidad</th>
          <th>Subtotal</th>
        </tr>
        <tr *ngFor="let object of bookingDetailList">
          <td *ngIf="object.status"> <span *ngIf="object.type == 'Shoes'"> Patines incluidos - </span> {{object.concept}}</td>
          <td style="text-align: center;" *ngIf="object.status"> <span *ngIf="object.type != 'Shoes'">{{object.unit_price | currency:'CAD':'symbol-narrow'}}</span>  <span *ngIf="object.type == 'Shoes'"> Tamaño {{object.size}}  </span> </td>
          <td style="text-align: center;" *ngIf="object.status">{{object.quantity}}</td>
          <td style="text-align: center;" *ngIf="object.status"> <span *ngIf="object.type != 'Shoes'"> {{object.total | currency:'CAD':'symbol-narrow'}}</span>  <span *ngIf="object.type == 'Shoes'">  -- </span> </td>
        </tr>
      </table>
    </div>
    <div class="col-12">
      <br>
      <p>Te esperamos para el día <strong>{{object.dateBooking}}</strong> de <strong>{{object.scheduleBooking}}</strong>.</p>
      <p>con <strong>{{object.people}} entradas</strong></p>
    </div>

  </div>
  <div class="col-7" style="margin-top:30px;">
    <hr>
    <h5><strong>OTRAS RESERVACIONES.</strong></h5>
    <p></p>
    <table class="compra">
      <tr>
        <th>Folio</th>
        <th>Fecha / Horario</th>
        <th>Cliente</th>
        <th>Entradas</th>
        <th>Total</th>
        <th>Acciones</th>
      </tr>
      <tr *ngFor="let object of bookingsList">
        <td style="text-align: center;">{{object.folio}}</td>
        <td style="text-align: center;">{{object.dateBooking | date:'dd-MM-yyyy'}} | {{object.scheduleBooking}}</td>
        <td style="text-align: center;">{{object.fullname}}</td>
        <td style="text-align: center;">{{object.people}}</td>
        <td style="text-align: center;"> {{object.total | currency:'CAD':'symbol-narrow'}}</td>
        <td>
          <button type="button" class="btn btn-link" (click)="selectedBooking(object);">ver</button>

        </td>
      </tr>
    </table>
  </div>
</div>
<app-client-footer></app-client-footer>
