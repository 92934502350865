<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-admin-toolbar (menuToogle)="toogleMenu($event)"></app-admin-toolbar>

    <!-- aqui va el contenido -->
    <div class="row justify-content-center m-0" style="margin-top: 30px!important;">

      <div class="col-11 col-md-10">
        <div class="row justify-content-between mb-2 mt-2">
          <div class="col-md-4 col-6" style="padding: 5px;"><h5 class="blue">Listado de ventas</h5></div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="form-group">
              <label for="concept" class="labelInputForm">Inicio</label>
              <div class="input-group">
                <input type="date" class="form-control" id="time" [(ngModel)]="metadata.start" (ngModelChange)="changeDate();">
                <!-- <div class="input-group-append">
                  <div class="input-group-text">Hra</div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="col-5 col-md-4">
            <div class="form-group">
              <label for="concept" class="labelInputForm">Final</label>
              <div class="input-group">
                <input type="date" class="form-control" name="dateDeliver2y" [(ngModel)]="metadata.end" (ngModelChange)="changeDate();">
                <!-- <div class="input-group-append">
                  <div class="input-group-text">Hra</div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="cardResumen">
              <h4 class="naranja">Total del periodo</h4>
              <!-- <p class="mb-3">Saldos</p> -->
              <div class="resumenTotal">
                <p>Registros</p>
                <p>{{metadata.totals.registros}}</p>
              </div>
              <div>
                <hr>
                <!-- <div class="resumenTotal" *ngIf="metadata.totals">
                  <p><strong>Efectivo</strong></p>
                  <p>{{metadata.totals.efectivo | currency:'CAD':'symbol-narrow'}}</p>
                </div>
                <div class="resumenTotal" *ngIf="metadata.totals">
                  <p><strong>Tarjeta</strong></p>
                  <p>{{metadata.totals.tarjeta | currency:'CAD':'symbol-narrow'}}</p>
                </div> -->
                <!-- <div class="resumenTotal" *ngIf="metadata.totals">
                  <p><strong>Cortesias</strong></p>
                  <p>{{metadata.totals.cortesia | currency:'CAD':'symbol-narrow'}}</p>
                </div> -->
                <!-- <div class="resumenTotal" *ngIf="metadata.totals">
                  <p><strong>Total</strong></p>
                  <p><strong>{{(metadata.totals.total) | currency:'CAD':'symbol-narrow'}}</strong></p>
                </div> -->
              </div>
              <div class="row justify-content-end m-0">
                <div class="col-12">
                  <button type="button" class="btn btn-primary btn-save" style="width:100%;" (click)="downloadReportr();">Descargar</button>
                </div>
                <hr><br>
                <div class="col-12 mt-2">
                  <button type="button" class="btn btn-primary btn-save" style="width:100%;" (click)="downloadReportrResumen();">Descargar Resumen</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <table class="compra">
              <tr>
                <th>Folio</th>
                <th>Cliente</th>
                <th>Tipo de pago</th>
                <th>Concepto</th>
                <th>Personas</th>
                <th>Punto de venta</th>
                <th>Caja</th>
                <th>Cajero</th>
                <th>Total</th>
                <th>Fecha</th>

              </tr>
              <tr *ngFor="let object of list">
                <td>
                  {{object.folio}}
                </td>
                <td>
                  {{object.fullname}}
                </td>
                <td>
                  {{object.typePayment}}
                </td>
                <td>
                  {{object.concept}}
                </td>
                <td>
                  {{object.people}}
                </td>
                <td>
                  <span *ngIf="object.point_sale">Por punto de venta</span>
                  <span *ngIf="!object.point_sale">Por reservación</span>
                </td>
                <td>
                  {{object.userName}}
                </td>
                <td>
                  {{object.turnoName}}
                </td>
                <td>
                  {{object.totalDetail | currency:'CAD':'symbol-narrow'}}
                </td>
                <td>
                  {{object.createdAt | date:'dd/MM/yyyy HH:mm'}}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
          <div class="col-6 ">
            <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
    <app-client-footer></app-client-footer>
  </mat-drawer-content>
</mat-drawer-container>
