import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingService } from './../../directive/loading/loading.module';
import { SessionService } from './../../service/session/session.module';
import { interval, Subject } from 'rxjs';
import * as moment from 'moment';
moment.locale('es-us');

const timerToCheckValidSession = interval(1000); //

@Component({
  selector: 'app-available',
  templateUrl: './available.component.html',
  styleUrls: ['./available.component.css']
})
export class AvailableComponent implements OnInit {

  locale: string = 'es';
  shedules = [
    {
      active:true,
      name:'12:00 - 13:00',
      used:false
    },{
      active:true,
      name:'13:15 - 14:15',
      used:false
    },{
      active:true,
      name:'14:45 - 15:45',
      used:false
    },{
      active:true,
      name:'16:00 - 17:00',
      used:false
    },{
      active:true,
      name:'17:30 - 18:30',
      used:false
    },{
      active:true,
      name:'18:45 - 19:45',
      used:false
    },{
      active:true,
      name:'20:00 - 21:00',
      used:false
    }
  ];

  metadata = {
    currentDate: moment(),
    countMinute: moment(),
    shedule: '',
    counter: 0,
    priceAccess:0,
  }
  shoeList = [];
  priceMin = {
    status:true,
    max: 10,
    page: 1,
  }
  otherChangerList = [];
  priceList = [];

  constructor(protected session: SessionService, public loading: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) {

  }

  async ngOnInit() {
    if (this.metadata.currentDate.format('dddd') == 'sábado' || this.metadata.currentDate.format('dddd') == 'domingo') {
      this.shedules.unshift({
        active:true,
        name:'10:15 - 11:15',
        used:false
      });
      this.shedules.unshift({
        active:true,
        name:'9:00 - 10:00',
        used:false
      });
    }
    this.metadata.countMinute = moment().add(20, 'seconds');
    this.checkSchedule();
    this.metadata.counter = await this.getActiveIndex(0);
    this.shedules = this.shedules.map((item)=>{
      item.used = false;
      return item;
    });
    this.metadata.shedule = this.shedules[this.metadata.counter].name;
    this.shedules[this.metadata.counter].used = true;

    this.loadShoes();
    this.loadPrices();

    // Invocamos el tarea a ejecutarse cada minuto
    this.initDog();
  }

  initDog() {
    this.watch(300).subscribe((data:any)=>{

    },error=>{
      console.log("hubo un error, no se puede iniciar el vigilante",error);
    })
  }

  watch(emitAlert: number): Observable<Response> {
    let response = {
      transaction: '', // ok | bad
      message: '', // posibles mensajes del observador
      code: '' // codigo identificador de la respuesta del observador
    }

    let enableEndSession = true;
    return Observable.create((observer) => {
      timerToCheckValidSession.subscribe(async(n) => {
        // Cambiamos el minutero del reloj
        this.metadata.currentDate = moment();

        let chageTime = this.metadata.countMinute;
        if (moment(chageTime) < this.metadata.currentDate) {
          this.checkSchedule();
          if (this.metadata.counter == this.shedules.length-1) {
            this.metadata.counter = await this.getActiveIndex(0);
          } else {
            // this.metadata.counter = this.metadata.counter + 1;
            this.metadata.counter = await this.getActiveIndex(this.metadata.counter);
          }
          this.shedules = this.shedules.map((item)=>{
            item.used = false;
            return item;
          });
          this.metadata.shedule = this.shedules[this.metadata.counter].name;
          this.shedules[this.metadata.counter].used = true;
          this.metadata.countMinute = moment().add(20, 'second');
          // Mandamos a obtener la cantidad de zapatos apartados
          this.loadShoes();
          this.loadPrices();
        }
        response.code = "watchDogSession:002";
        response.transaction = "ok";
        response.message = "Su sesión esta próxima a vencer, reanude su sesión para continuar."
        observer.next(response);
        observer.complete();
      })
    });
  }

  /**funcioanalidad para obetner los zapatos
  */
  loadShoes() {
    this.session.postRequest("shoes:findAllByStatus",{status:true}).subscribe((data:any)=>{
      for(let i = 0; i < data.object.instanceList.length; i++){
        data.object.instanceList[i].use = 0;
      }
      //Ordenamos los zapatos
      this.shoeList = data.object.instanceList.sort((a,b)=>{
        if(a.size < b.size){
          return -1;
        }
        if(a.size > b.size){
          return 1;
        }
        return 0;
      });
      this.getShoesAvalabotlity(this.shoeList);
    },error=>{
      console.log("error:price_list:findAllByStatus",error);
    })
  }
  /**funcionalidad para obetner la lista de precios vigente
  */
  loadPrices(){
    this.session.postRequest("price_list:findAllByStatus",this.priceMin).subscribe((data:any)=>{
      this.priceList = data.object.instanceList;
      this.otherChangerList = this.priceList.filter(object => object.access == false);
      let accesPrice = this.priceList.filter(object => object.access == true);
      //buscamos el precios de acceso
      for(let item of accesPrice){
        let date = moment().toDate();
        let day = date.getDay();
        if(item.day == day){
          this.metadata.priceAccess = item.unit_price;
        }
      }

      this.preloadBookingDetails();
      // console.log("otherChangerList",this.otherChangerList);
    },error=>{
      console.log("error:price_list:findAllByStatus",error);
    })
  }
  /**funcionalidad para precargar datos el bookigndetails
  */
  preloadBookingDetails(){
    //calculamos disponibilidad de monitos, instructores
    for(let i = 0; i < this.otherChangerList.length; i++){
      if(this.otherChangerList[i].description == 'Instructor personalizado'){
        this.otherChangerList[i].avalability = [1,2,3,4];
      }else{
        this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8];
      }
      if(this.otherChangerList[i].description == 'Calcetines'){
        this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30];

      }
      if(this.otherChangerList[i].description == 'Lockers'){
        this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30,31,32];
      }
      if(this.otherChangerList[i].description == 'Ayudante Delfin'){
        this.otherChangerList[i].avalability = [1,2,3,4];
      }
      if(this.otherChangerList[i].description == 'Ayudante monito Olaf'){
        this.otherChangerList[i].avalability = [1,2,3,4,5,6,7];
      }
      if(this.otherChangerList[i].description == 'Ayudante monito santa'){
        this.otherChangerList[i].avalability = [1,2,3];
      }
    }
    for(let i = 0; i < this.otherChangerList.length; i++){
      let promo = this.otherChangerList[i].description.toUpperCase().indexOf('PROMO');
      if(promo != -1){
        this.otherChangerList.splice(i,1);
        i = 0;
      }
    }
    this.getAvalabitlityByConcept(this.otherChangerList);
  }

  /**funcionalidad para verificar si el horario sigue activo
  */
  getActiveIndex(actualIndex:number):Promise<number>{
    return new Promise(async(resolve,reject)=>{
      let index = actualIndex;
      let nextIndex = index + 1;
      if(this.shedules[nextIndex].active){
        index = nextIndex;
      }else{
        index = await this.getActiveIndex(nextIndex);
      }
       resolve(index)
    });
  }

  /**funcionalidad para determinar si un horario aun sigue activo
  */
  checkSchedule(){
    this.shedules.map((item)=>{
      let hours = item.name.split(" - ");
      let hoursArrays = hours.map((_h:any)=>{
        let _hsplit = _h.split(":");
        let h = {
          hour:parseInt(_hsplit[0]),
          min:parseInt(_hsplit[1])
        }
        return h
      });
      let starDate = moment().set('hour',hoursArrays[0].hour).set('minute',hoursArrays[0].min).set('second',0);
      let now = moment();
      let endDate = moment().set('hour',hoursArrays[1].hour).set('minute',hoursArrays[1].min).set('second',0);
      if(now.diff(starDate,'minute') < 0 || now.diff(endDate,'minute') < 0){
      }else{
        item.active = false;
      }
      return item;
    })
  }

  /**función para cargar disponibilidad de los articulos extras
  */
  getAvalabitlityByConcept(object){
    let concepts = [];
    for(let item of object){
      concepts.push(item.description);
    }
    this.session.postRequest("booking:findStockByScheduleAndConceptList",{date_booking: moment(this.metadata.countMinute).format('YYYY-MM-DD'), schedule_booking:this.metadata.shedule, concept:concepts}).subscribe((data:any)=>{
      for(let item of data.object){
        for(let i = 0; i < object.length; i++){
          if(item.concept ==object[i].description ){
            for(let j = 1 ; j <= item.used; j++){
              object[i].avalability.pop();
            }
          }
        }
      }
      // for(let i = 1 ; i <= data.object; i++){
      //   object.avalability.pop();
      // }
    },error=>{
      console.log("error:booking:findStockByScheduleAndConceptList",error);
    })
  }

  getShoesAvalabotlity(object){
    let sizes = [];
    for(let item of object){
      sizes.push(item.size);
    }
    this.session.postRequest("booking:findShoesSizeByScheduleList",{date_booking: moment(this.metadata.countMinute).format('YYYY-MM-DD'), schedule_booking:this.metadata.shedule, size:sizes}).subscribe((data:any)=>{
      for(let item of data.object){
        for(let i =0; i <object.length; i++){
          if(item.size == object[i].size){
            object[i].avalability = item.used;
            object[i].quantity = object[i].quantity - object[i].avalability;
          }
        }
      }

    },error=>{
      console.log("error:booking:findShoesSizeByScheduleList",error);
    })
  }

}
