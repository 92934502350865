<div class="row justify-content-end mt-0">
  <div class="col-1">
    <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
  </div>
  <div class="col-12 mb-2">
    <h2 class="naranja">Confirmar la reservación</h2>
    <p class="mt-3">
      Para realizar la reservación por cortesia se requiere la confirmacion de usuario y contraseña de un <strong>ADMINISTRADOR</strong> ó <strong>SUPERVISOR</strong>
    </p>
  </div>
  <div class="col-12 pt-2 mb-2">
    <div><p>E-mail</p></div>
    <div class="input-group">

      <input class="form-control" [ngClass]="complexForm.controls['username'].hasError('required') ? 'is-invalid':'is-valid'" type="text" placeholder="Correo electrónico" name="mName"  [(ngModel)]="object.email" [formControl]="complexForm.controls['username']" required>
      <div class="invalid-feedback">
        Se debe ingresar un correo electrónico
      </div>
    </div>

    <div><p>Contraseña</p></div>
    <div class="input-group">
      <input class="form-control" [ngClass]="complexForm.controls['password'].hasError('required') ? 'is-invalid':'is-valid'" type="password" placeholder="Contraseña" name="mPassword" [(ngModel)]="object.password" [formControl]="complexForm.controls['password']" required>
      <div class="invalid-feedback">
        Ingrese su contraseña
      </div>
    </div>

</div>
<div class="col-12 mt-2 mt-2" *ngIf="metadata.msj != ''">
  <div class="alert alert-warning" role="alert">
    {{metadata.msj}}
</div>
</div>
  <div class="col-12">
    <div class="row justify-content-end m-0">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-close" (click)="close(false);">CANCELAR</button>
      </div>
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-save" (click)="checkUser();">CONFIRMAR</button>
      </div>
    </div>
  </div>
</div>
