<div class="row justify-content-end mt-0">
  <div class="col-12 mb-2">
    <h2 class="naranja">Realizar corte parcial</h2>
    <p class="mt-3">
      Hola {{user.name}},
      Ingresa el <strong>monto</strong> para realizar el corte de caja
    </p>
  </div>
  <div class="col-12 pt-2 mb-2">
    <div><p>Monto del corte</p></div>
    <div class="input-group">
      <input class="form-control" type="number" [ngClass]="complexForm.controls['amount'].hasError('required') ? 'is-invalid':'is-valid'" type="text" placeholder="0.00" name="mName"  [(ngModel)]="object.amount" [formControl]="complexForm.controls['amount']" required>
      <div class="invalid-feedback">
        Campo requerido
      </div>
    </div>

</div>
<div class="col-12">
  <div class="">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="gridCheck" [(ngModel)]="object.expense">
      <label class="form-check-label labelInputForm" for="gridCheck">
        Aplicar para indicar un gasto
      </label>
    </div>
  </div>
</div>
<div class="col-12 pt-2 mb-2">
  <div><p>Agrega un motivo</p></div>
  <div class="input-group">
    <textarea class="form-control" type="text" name="mName"  [(ngModel)]="object.observation"></textarea>
  </div>
</div>

  <div class="col-12">
    <div class="row justify-content-center m-0">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-close" (click)="close(false);">CANCELAR</button>
      </div>
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-save" (click)="close(true);" [disabled]="complexForm.invalid">REALIZAR</button>
      </div>
    </div>
  </div>
</div>
