import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.css']
})
export class AdminReportsComponent implements OnInit {
  metadata = {
    start:'',
    end:'',
    totals :{
      efectivo:0,
      tarjeta:0,
      total:0,
      cortesia:0,
      registros:0
    }
  }
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  metricMin = {
    max: 10,
    page: 1,
    from:'',
    to:''
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[50, 100, 200, 300],
  };
  pageEvent: PageEvent;
  constructor(
    private session: SessionService,
    public loadding:LoadingService,
  ) { }

  ngOnInit(): void {
    let actualMonth = moment().get('month');
    let endOfTheMonth = moment().set('month',actualMonth+1).set('date', 1).subtract(1, 'day');

    this.metricMin.from =  moment().set('date', 1).format('YYYY-MM-DD');
    this.metricMin.to = endOfTheMonth.format('YYYY-MM-DD');
    // colocamos los el value en los datePicker
    this.metadata.start =  moment().set('date', 1).format('YYYY-MM-DD');
    this.metadata.end =  endOfTheMonth.format('YYYY-MM-DD');
    this.getListByPeriod()
  }

  getListByPeriod(){
    this.loadding.show(true,"Espere un momento...");
    this.metadata.totals.total = 0;
    this.metadata.totals.efectivo = 0;
    this.metadata.totals.tarjeta = 0;
    this.metadata.totals.cortesia = 0;

    this.session.postRequest("metric:findAllSalesWithDetailsByPeriod",this.metricMin).subscribe((data:any)=>{
      this.list = data.object.instanceList.map((item)=>{
        this.metadata.totals.total = this.metadata.totals.total + item.totalDetail*1;
        if(item.typePayment == 'Efectivo')
          this.metadata.totals.efectivo = this.metadata.totals.efectivo + item.totalDetail*1;
        if(item.typePayment == 'Tarjeta')
          this.metadata.totals.tarjeta = this.metadata.totals.tarjeta + item.totalDetail*1;
        if(item.typePayment == 'Cortesia')
          this.metadata.totals.cortesia = this.metadata.totals.cortesia + item.totalDetail*1;
        return item;
      });
      this.metadata.totals.registros = data.object.total;
      this.paginator.total = data.object.total;

      this.loadding.hide()
    },error=>{
      console.log(error);
      this.loadding.hide()
    })
  }

  changeDate(){
    this.metricMin.from =  moment(this.metadata.start).format('YYYY-MM-DD');
    this.metricMin.to =  moment(this.metadata.end).format('YYYY-MM-DD');
    console.log("Cambio de periodo");

    if(moment(this.metadata.start).diff(moment(this.metadata.end)) < 0){
      this.metricMin.page = 1;
      this.list = [];
      this.getListByPeriod();
    }else{
      // this.snackBar.open('Revisar el periodo de tiempo seleccionado.', 'Aceptar', {duration: 5000});
    }
  }


  // funcionalidad para descargar el csv

  downloadReportr(){
    // metric/downloadSalesWithDetailsByPeriod
    this.loadding.show(true,"Espere un momento...");

    this.session.postRequest("metric:downloadSalesWithDetailsByPeriod",this.metricMin).subscribe(async(data:any)=>{
      // console.log(data);
      const r = await fetch(data.object.file);
      const blob = await r.blob();
      // console.log(blob);
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = data.object.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loadding.hide()
        },error=>{
      console.log(error);
      this.loadding.hide()
    })
  }

    // funcion para obtener los datos del paginado.
    onPaginateChange(event){
      this.metricMin.max = event.pageSize;
      this.metricMin.page = event.pageIndex + 1;
      this.getListByPeriod();
    }

  downloadReportrResumen(){
    // metric/downloadSalesWithDetailsByPeriod
    this.loadding.show(true,"Espere un momento...");

    this.session.postRequest("metric:downloadSalesWithDetailsByPeriodAndResume",this.metricMin).subscribe(async(data:any)=>{
      // console.log(data);
      const r = await fetch(data.object.file);
      const blob = await r.blob();
      // console.log(blob);
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = data.object.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loadding.hide()
        },error=>{
      console.log(error);
      this.loadding.hide()
    })
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
