import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-subscription-update',
  templateUrl: './admin-subscription-update.component.html',
  styleUrls: ['./admin-subscription-update.component.css']
})
export class AdminSubscriptionUpdateComponent implements OnInit {

  complexForm: FormGroup;
  subscriptionModel = {
    id:'',
    name:'',
    member:'',
    address:'',
    status:true,
  }

  memberList = [
    'Estandar',
    'Cubo de Hielo',
    'Iceberg'
  ]
  object = JSON.parse(JSON.stringify(this.subscriptionModel));

  constructor(public fb: FormBuilder,
    private session: SessionService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<AdminSubscriptionUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
  }

  ngOnInit(): void {
    if(this.data != 'new') {
      this.loadingService.show(true, "Espere un momento...");
       // cargamos los datos de la BD
       this.session.postRequest('subscription:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: subscription:get',error)
       })
    }
  }

  /**
   * funcion para guardar shoes
   */
  send(){
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("subscription:update",this.object).subscribe(
      (data:any) => {
        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: subscription:update",error);
        this.loadingService.hide();
      }
    );
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'subscription:001';
    }
    this.dialogRef.close(object);
  }


}
