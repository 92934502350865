<div class="row justify-content-end mt-0">
  <div class="col-1">
    <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
  </div>
  <div class="col-12">
    <h6>Agregar unos nuevos patines</h6>
    <p><small>Rellena el formulario de acuerdo al nuevo concepto a crear.</small></p>
  </div>
  <div class="col-12">
    <div class="row m-0">
      <div class="col-12">
        <div class="form-group">
          <label for="name" class="labelInputForm">India la medida de los patines</label>
          <input type="text" class="form-control" id="name" [(ngModel)]="object.size">
        </div>
      </div>

      <div class="col-12" *ngIf="!object.allDays">
        <div class="form-group">
          <label for="concept" class="labelInputForm">Pares disponibles</label>
          <div class="input-group">
            <input type="number" class="form-control" id="quantity" [(ngModel)]="object.quantity">
            <div class="input-group-append">
              <div class="input-group-text">Pares</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end m-0">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-save" (click)="send();">Guardar</button>
      </div>
    </div>
  </div>
</div>
