import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from './../../directive/loading/loading.module';
import { SessionService } from './../../service/session/session.module';
import * as moment from 'moment';
moment.locale('es-us');

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {
  bookingModel = {
    id:'',
    folio:'',
    fullname:'',
    phone:'',
    dateBooking: moment().format('YYYY-MM-DD'),
    scheduleBooking:'', //string 13:00 - 14:00,
    people: 1,
    bowlings: 1, // numero de lineas a reservar
    peopleUnitPrice: 25.00,
    bowlingsUnitPrice: 440.00,
    discount: 0.00,
    subtotal: 0.00,
    tax: 0.00,
    total: 0.00,
    terms: false,
    privacy:false,
    type_payment:'', //mexpago, paypal, referencia
    payment_id:'',
    status:'Pagado' //pagado, pendiente, cancelado.
  }
  bookingsList = [];
  object = JSON.parse(JSON.stringify(this.bookingModel));
  mexpago = {
    folioMexPago:'',
    monto:'',
    numeroTransaccion:'',
    pago:''
  }
  metadata = {
    status:''
  }
  bookingDetailList = [];
  constructor(protected session: SessionService, public loading: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['phone'] != 'mexpago') {
        this.getBooking(params['phone']);
      }else{
        console.log(params['phone']);
        this.activatedRoute.queryParams.subscribe(async(params) => {
          console.log(params);
          this.mexpago.folioMexPago = params.folioMexPago;
          this.mexpago.monto = params.monto;
          this.mexpago.numeroTransaccion = params.numeroTransaccion;
          this.mexpago.pago = params.pago;
          this.metadata.status = params.pago;
          this.getBookingByMexpago();
        });
      }
    });
  }

  /**funcion para obtener las refervaciones por numero telefonico
  */
  getBooking(phone){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("booking:findAllByPhone",{phone:phone}).subscribe((data:any)=>{
      this.bookingsList = data.object.instanceList;
      if(this.bookingsList.length > 0 ){
        this.object = JSON.parse(JSON.stringify(this.bookingsList[0]));
        this.bookingDetailList = this.object.bookingDetails;
      }
      this.loading.hide();
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }

  /**función para seleccionar una reservación
  */
  selectedBooking(object){
    this.object = JSON.parse(JSON.stringify(object));
    this.bookingDetailList = this.object.bookingDetails;
  }

  /**función para obtener reservacion por mexpago
  */
  getBookingByMexpago(){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("booking:findByMexpago",this.mexpago).subscribe((data:any)=>{
      //actualizamos el pago
      this.object = data.object;
      this.bookingDetailList = this.object.bookingDetails;
      let status = '';
      if(this.metadata.status == 'aprobado'){
        status = 'Pagado';
      }else{
        status = 'Cancelado';
      }
      this.session.postRequestAnonimus("booking:checkAndValidate",{
        id:this.object.id,
        paymentId:this.object.paymentId,
        folioMexPago:this.mexpago.folioMexPago,
        status:status
      }).subscribe((data:any)=>{
        //actualizamos el pago
        this.object.status = data.object.status;
        this.loading.hide();
      },error=>{
        console.log(error);
        this.loading.hide();
      })
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }

}
