<div class="row baner-footer justify-content-center  m-0">
  <div class="col-12">
    <div class="footerMain">
      <p>Powered By</p>
      <div class="">
        <a href="https://mx.linkedin.com/company/sys-tecnology-and-innovation"><img src="assets/imagenes/keySoft-FINAL-01.png" alt="keysoft" class="footerLogoClass"></a>
      </div>
    </div>
  </div>
</div>
