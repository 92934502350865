import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-admin-metrics-turns-details',
  templateUrl: './admin-metrics-turns-details.component.html',
  styleUrls: ['./admin-metrics-turns-details.component.css']
})
export class AdminMetricsTurnsDetailsComponent implements OnInit {
  turnModel = {
    id:'',
    open:true,
    clossing_amount:0,
    openning_amount:0,
    open_date:'',
    close_date:null,
    valid:true,
    check:false
  }

  object = JSON.parse(JSON.stringify(this.turnModel));
  turnUser = {
    open:true,
    filter:'',
    max: 100,
    page: 1,
    turn:{
      id:''
    }
  }
  turnUserList = [];
  partialCutList = [];
  partialCutMin = {
    filter:'',
    max: 100,
    page: 1,
    turn_user:{
      id:''
    }
  }
  constructor(
    private session: SessionService,
    public loadding:LoadingService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AdminMetricsTurnsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.object = this.data;
    this.getTurnsUser();
  }

  /**funcionalidad para obtener el detalle de turnos de usuario de un turno
  */
  getTurnsUser(){
    this.turnUser.turn.id = this.object.id;
    this.loadding.show(true, "Espere un momento...");
    this.session.postRequest("turnUser:findAllByTurn",this.turnUser).subscribe((data:any)=>{
      this.turnUserList = data.object.instanceList;
      for(let i=0; i < this.turnUserList.length; i++){
        this.getTotals(this.turnUserList[i]);
        this.getPartialCut(this.turnUserList[i]);
      }
      this.loadding.hide();
    },error=>{
      console.log(error);
      this.loadding.hide();
    })
  }

  /**obtenemos los totales del turno
  */
  getTotals(object){
    this.session.postRequest("turnSale:getTotalByTurnUser",{turn_user:{id:object.id}}).subscribe((data:any)=>{
      object.totals = data.object;
    },error=>{
      console.log(error);
    });
  }

  getPartialCut(object){
    this.loadding.show(true,"Espere un momento...");
    let partialCutMinAux = JSON.parse(JSON.stringify(this.partialCutMin));
    partialCutMinAux.turn_user.id = object.id;
    this.session.postRequest("partialCut:findAllByTurnUser",partialCutMinAux).subscribe((data:any)=>{
      object.totalCortes = 0;
      object.partialCutList = data.object.instanceList.map((item)=>{
        object.totalCortes = object.totalCortes + (item.amount*1);
        return item;
      });
      this.loadding.hide();
    },error=>{
      console.log(error);
      this.loadding.hide();
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'price_list:001';
    }
    this.dialogRef.close(object);
  }

}
