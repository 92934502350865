import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsiva',
  templateUrl: './responsiva.component.html',
  styleUrls: ['./responsiva.component.css']
})
export class ResponsivaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
