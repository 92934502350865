import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent, CalendarMonthViewDay, CalendarMonthViewComponent } from 'angular-calendar';
import * as moment from 'moment';
moment.locale('es-us');

@Component({
  selector: 'app-admin-promotion-update',
  templateUrl: './admin-promotion-update.component.html',
  styleUrls: ['./admin-promotion-update.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AdminPromotionUpdateComponent implements OnInit {
  @ViewChild('calendar',{static:false})calendar: CalendarMonthViewComponent;

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  clickedDate: Date;
  clickedColumn: number;
  locale: string = 'es';
  // selectedMonthViewDay: CalendarMonthViewDay;
  selectedMonthViewDay: any;
  selectedDays: any = [];

  complexForm: FormGroup;
  promoModel = {
    id:'',
    name:'',
    type_price:'',
    type_quantity:'',
    quantity:0,
    price:0.0,
    permanent:false,
    day:0,
    date:null,
    observation:''
  }

  typeQuantityList = [
    'Personas',
    'Monto'
  ];
  typeList = [
    'Acceso',
    'Descuento',
    // 'Monto Fijo'
  ]

  object = JSON.parse(JSON.stringify(this.promoModel));

  listPromotions = [];
  constructor(public fb: FormBuilder,
    private session: SessionService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<AdminPromotionUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.complexForm = fb.group({
      'name': [null, Validators.required],
      'quantity': [null, Validators.required],
      'price': [null, Validators.required],
      'type': [null, Validators.required],
    })
  }

  ngOnInit(): void {
    if(this.data != 'new') {
      this.loadingService.show(true, "Espere un momento...");
       // cargamos los datos de la BD
       this.session.postRequest('promo:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         if(!this.object.permanent){
           this.object.id = '';
           this.loadingService.hide();

           this.session.postRequest('promo:findAllByName',{name:this.object.name}).subscribe((data:any)=>{
             for(let item of data.object.instanceList){
               let selectedMonthViewDayAux = JSON.parse(JSON.stringify(this.selectedMonthViewDay));
               selectedMonthViewDayAux.object = JSON.parse(JSON.stringify(item));
               selectedMonthViewDayAux.object.date = moment(item.date).format('yyyy-MM-DD')+' 09:00:00';
               selectedMonthViewDayAux.date = moment(item.date).set('hour',0).set('minute',0).set('second',0).toDate();
               console.log(selectedMonthViewDayAux);
               this.selectedDays.push(selectedMonthViewDayAux);
             }


             for(let  i = 0; i < this.selectedDays.length; i++){
               for(let  j = 0; j < this.calendar.view.days.length; j++){
                 if(this.selectedDays[i].date.getTime()===this.calendar.view.days[j].date.getTime()){
                   this.calendar.view.days[j].cssClass = 'cal-day-selected';
                 }
               }
             }
           },
           (error)=>{
             console.log('Error: promo:findAllByName',error)
           })
         }else{
           this.loadingService.hide();
         }



       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: promo:get',error)
       })
    }
  }


  async daySelected(ev){
    let today = moment();
    let selectedDay = moment(ev.day.date);
    if(selectedDay.diff(today,'day') >= 0){
      // console.log("Este dia puedes seleccionar");
      this.selectedMonthViewDay = ev.day;
      const selectedDateTime = this.selectedMonthViewDay.date.getTime();
      const dateIndex = this.selectedDays.findIndex(
        (selectedDay) => selectedDay.date.getTime() === selectedDateTime
      );
      if (dateIndex > -1) {
        delete this.selectedMonthViewDay.cssClass;
        await this.deleteAPromotion(this.selectedDays[dateIndex].object)
        this.selectedDays.splice(dateIndex, 1);
      } else {
        this.selectedMonthViewDay.object = JSON.parse(JSON.stringify(this.object));
        this.selectedMonthViewDay.object.date = moment(this.selectedMonthViewDay.date).format('yyyy-MM-DD')+' 09:00:00';
        this.selectedDays.push(this.selectedMonthViewDay);
        ev.day.cssClass = 'cal-day-selected';
        this.selectedMonthViewDay = ev.day;
      }
      console.log(this.selectedDays)
    }else{
      // console.log("Este dia no se puede seleccionar");
    }
  }



  /**watcher para determinar un cambio en el calendario
  */
  beforeMonthViewRender(renderEvent:CalendarMonthViewBeforeRenderEvent){

    renderEvent.body.forEach((day) => {
      const dayOfMonth = day.date;
      this.selectedMonthViewDay = day;
      let today = new Date();
      if (dayOfMonth < today) {
        day.cssClass = 'bg-disabled';
      }
      if (dayOfMonth > today) {
        day.cssClass = 'bg-enabled';
      }
      if(today.getDate() == dayOfMonth.getDate() && today.getMonth() == dayOfMonth.getMonth()){
        day.cssClass = 'bg-enabled';
      }

    });
  }

  async send(){
    try{
      if(!this.object.permanent){
        this.loadingService.show(true,"Espere un momento..");
        for(let item of this.selectedDays){
          await this.updatePromo(item.object);
        }
        this.loadingService.hide();
      }else{
        await this.updatePromo(this.object);
      }

      this.close(true);
    }catch(e){
      console.log(e)
    }

  }


  updatePromo(object){
    return new Promise((resolve,reject)=>{
      this.session.postRequest("promo:update",object).subscribe(
        (data:any) => {
          resolve(true);
        },
        error => {
          console.log("Error:promo:delete",error);
          reject(error);
        }
      );
    });
  }


  /**funcionalidad para eliminar una promoción
  */
  deleteAPromotion(object){
    return new Promise((resolve,reject)=>{
      if(object.id != ''){
        this.loadingService.show(true,'Espere un registro...');
        this.session.postRequest("promo:delete",object).subscribe(
          (data:any) => {
            resolve(true);
            this.loadingService.hide();
          },
          error => {
            console.log("Error:promo:delete",error);
            this.loadingService.hide();
            reject(error);
          }
        );
      }else{
        resolve(true);
      }
    });
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'promo:001';
    }
    this.dialogRef.close(object);
  }

}
