import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-booking-confirm',
  templateUrl: './admin-booking-confirm.component.html',
  styleUrls: ['./admin-booking-confirm.component.css']
})
export class AdminBookingConfirmComponent implements OnInit {
  complexForm: FormGroup;
  object = {
    email:"",
    password:"",
    remember_me:true
  }
  metadata = {
    msj:''
  }
  constructor(public dialogRef: MatDialogRef<AdminBookingConfirmComponent>,
    private session: SessionService,
    public loadding:LoadingService,
    public fb: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.complexForm = fb.group({
          // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
          'username': [null, Validators.required],
          'password': [null, Validators.compose([Validators.required])],
        });
       }

  ngOnInit(): void {
  }

  /*confirmar si el usuario es correcto
  */
  checkUser(){
    this.loadding.show(true,"Espere un momento, estamos verificando su usuario");
    this.session.confirmUser(this.object).then((data:any)=>{
      // console.log(data);
      let roles = data.object.roles;
      // console.log(roles);
      if(roles.length > 0){
        let rol = roles[0];
        // console.log(rol);
        switch(rol){
          case 'SUPERVISOR':
          case 'ADMINISTRATOR':
          this.close(true);
          break;
          default:
          this.metadata.msj = "El usuario no tiene acceso para realizar esta acción.";
          break;
        }
      }else{
        this.metadata.msj = "El usuario no tiene acceso para realizar esta acción.";
      }
      this.loadding.hide();
    }).catch(e=>{
      console.log(e);
      this.metadata.msj = "Usuario ó constrasela incorrecta";
      this.loadding.hide()
    });
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'price_list:001';
    }
    this.dialogRef.close(object);
  }

}
