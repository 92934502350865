<div class="row justify-content-end mt-0">
  <div class="col-1">
    <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
  </div>
  <div class="col-12">
    <h6>Agregar una promoción</h6>
    <p><small>Rellena el formulario deacuerdo a la nueva promoción.</small></p>
  </div>
  <div class="col-12">
    <div class="row m-0">
      <div class="col-12">
        <div class="form-group">
          <label for="concept" class="labelInputForm">Nombre</label>
          <input type="text" class="form-control" id="concept" [(ngModel)]="object.name">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="inputState" class="labelInputForm">Tipo de Restricciones</label>
          <select id="inputState" class="form-control" name="type" [(ngModel)]="object.type_quantity">
            <option *ngFor="let object of typeQuantityList" value="{{object}}">{{object}}</option>
          </select>
        </div>
      </div>
      <div class="col-8">
        <div class="form-group">
          <label for="concept" class="labelInputForm">
            <span *ngIf="object.type_quantity == 'Personas'">¿ Apartir de cuantas personas aplica la promoción ?</span>
            <span *ngIf="object.type_quantity == 'Monto'">¿ Apartir de cuanto aplica la promoción ?</span>
          </label>
          <div class="input-group" *ngIf="object.type_quantity">
            <input type="number" class="form-control" id="quantity" [(ngModel)]="object.quantity">
            <div class="input-group-append"  *ngIf="object.type_quantity == 'Personas'">
              <div class="input-group-text">Personas</div>
            </div>
            <div class="input-group-append" *ngIf="object.type_quantity == 'Monto'">
              <div class="input-group-text">MXN</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="inputState" class="labelInputForm">Tipo de promoción</label>
          <select id="inputState" class="form-control" name="type" [(ngModel)]="object.type_price">
            <option *ngFor="let object of typeList" value="{{object}}">{{object}}</option>
          </select>
        </div>
      </div>

      <div class="col-8">
        <div class="form-group">
          <label for="concept" class="labelInputForm" *ngIf="object.type_price == 'Acceso'">
            <span>¿ Costo del acceso ?</span>
          </label>
          <label for="concept" class="labelInputForm" *ngIf="object.type_price == 'Descuento'">
            <span>¿ Cual es el descuento a aplicar a todas entradas ?</span>
          </label>
          <label for="concept" class="labelInputForm" *ngIf="object.type_price == 'Monto Fijo'">
            <span>¿ Cuanto cuesta el acceso por {{object.quantity}} <span *ngIf="object.type_quantity == 'Personas'">Personas</span> <span *ngIf="object.type_quantity == 'Monto'">MXN</span> ?</span>
          </label>
          <div class="input-group" *ngIf="object.type_price">
            <input type="number" class="form-control" id="price" [(ngModel)]="object.price">
            <div class="input-group-append" *ngIf="object.type_price == 'Acceso' || object.type_price == 'Monto Fijo'">
              <div class="input-group-text">MXN</div>
            </div>
            <div class="input-group-append" *ngIf="object.type_price == 'Descuento'">
              <div class="input-group-text">%</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="gridCheck" [(ngModel)]="object.permanent">
            <label class="form-check-label labelInputForm" for="gridCheck">
              La promoción es permanente.
            </label>
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="object.permanent">
        <div class="form-group">
          <label for="inputState" class="labelInputForm">Dia</label>
          <select id="inputState" class="form-control" name="days" [(ngModel)]="object.day">
            <option value="7">Todos los dias</option>
            <option value="0">Domingo</option>
            <option value="1">Lunes</option>
            <option value="2">Martes</option>
            <option value="3">Miercoles</option>
            <option value="4">Jueves</option>
            <option value="5">Viernes</option>
            <option value="6">Sabado</option>
          </select>
        </div>
      </div>


      <div class="col-md-12 col-12" *ngIf="!object.permanent">
        <div class="alert alert-info">
          Selecciona las fechas a utilizar en la promoción.
          <strong *ngIf="clickedDate">Reservar el día: {{ clickedDate | date:'dd-MM-yyyy' }}</strong>
        </div>
        <div class="row justify-content-center">
          <div class="col-4 col-md-3">
            <div class="btn-top-calendar" mwlCalendarPreviousView [view]="view"
  [(viewDate)]="viewDate">
              <i class="bi bi-arrow-bar-left"></i> Antes
            </div>
          </div>
          <div class="col-4 col-md-3">
            <div class="text-center bg-success" style="border-radius: .25rem;">
              <h5 class="text-white p-2" style="text-transform: capitalize;">{{ viewDate | date:'MMMM':'':'es'}}</h5>
            </div>
          </div>
          <div class="col-4 col-md-3">
            <div class="btn-top-calendar" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
              Después <i class="bi bi-arrow-bar-right"></i>
            </div>
          </div>
        </div>

        <div>
          <mwl-calendar-month-view
            #calendar
            [viewDate]="viewDate"
            [events]="events"
            [locale]="locale"
            (columnHeaderClicked)="clickedColumn = $event.isoDayNumber"
            (dayClicked)="daySelected($event);"
            (beforeViewRender)="beforeMonthViewRender($event)"
            >
          </mwl-calendar-month-view>
        </div>
      </div>



    </div>
    <div class="row justify-content-end m-0">
      <div class="col-12 col-md-6 mt-3">
        <button type="button" class="btn btn-primary btn-save" style="width:100%;" (click)="send();" [disabled]="object.name == ''">Guardar</button>
      </div>
    </div>
  </div>
</div>
