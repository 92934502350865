import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { AdminPriceListUpdateComponent } from './admin-price-list-update/admin-price-list-update.component';

@Component({
  selector: 'app-admin-price-list',
  templateUrl: './admin-price-list.component.html',
  styleUrls: ['./admin-price-list.component.css']
})
export class AdminPriceListComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  priceMin = {
    max: 10,
    page: 1,
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;

  constructor(
    private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadPrices();
  }

  /**funcionalidad para cargar la lista de precios
  */
  loadPrices(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest("price_list:list",this.priceMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
      this.loadding.hide();
    },error=>{
      console.log("error:price_list:list",error);
      this.loadding.hide();
    })
  }

    /** funcionalidad para crear una categoria
    */
    update(uuid){
      const dialogRef = this.dialog.open(AdminPriceListUpdateComponent, {
        width: '36rem',
        height:'90%',
        data: uuid,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            // El modal se cerro con objeto
            this.loadPrices();
          }else{
            // El modal se cerro sin objeto
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

    delete(object){
      this.loadding.show(true, "Espere un momento...");
      this.session.postRequest("price_list:delete",object).subscribe((data:any)=>{
        for(let i=0; i < this.list.length; i++){
          if(this.list[i].id == object.id){
            this.list.splice(i,1);
            break;
          }
        }
        this.loadding.hide();
      },error=>{
        console.log("error:price_list:delete",error);
        this.loadding.hide();
      })
    }

    // funcion para obtener los datos del paginado.
    onPaginateChange(event){
      this.priceMin.max = event.pageSize;
      this.priceMin.page = event.pageIndex + 1;
      this.loadPrices();
    }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
