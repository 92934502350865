<div class="menu-title">
  <img src="assets/imagenes/logo_horizontal.png" alt="Tecnopista" style="width: 90%;">
</div>
<div class="row justify-content-center global-menu">
  <div class="col-10 main-menu">
    <ul class="list-group">
      <li class="list-group-item item-menu-all" [ngClass]="{'item-menu-active': object.selected}" *ngFor="let object of menuOptions" (click)="menuSelection(object);">
        <i class="{{object.icon}}"></i>
        {{object.title}}
      </li>
      <li class="list-group-item item-menu-all" (click)="logout();">
        <i class="bi bi-box-arrow-left"></i>
        Salir sin cerrar turno
      </li>
    </ul>
  </div>
</div>
