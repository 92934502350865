<div class="row baner-superior justify-content-center  m-0">
  <div class="col-md-4 text-center">
    <h1 class="title">Disponibilidad</h1>
  </div>
  <div class="col-md-4 col-8 text-center align-middle">
    <p class="h4" style="margin-top:30px; color:#132069;"><span class="material-icons">event</span> {{metadata.currentDate  | date:'d/M/y h:mm:ss' }}</p>
  </div>
  <div class="col-md-4" style="text-align: end;">
    <img src="assets/imagenes/logo_horizontal.png" alt="Tecnopista" class="logoClass pt-2" routerLink="/">
  </div>
</div>
<div class="row justify-content-center mt-3 mb-2 mr-0">
  <div class="col-lg-3">
    <ul class="list-group text-center ml-2 mr-2">
      <li *ngFor="let shedule of shedules; let i = index;" class="list-group-item h4" [ngClass]="shedule.used ? 'active' : shedule.active ? '' : 'disabledSchedule'" aria-current="true">{{shedule.name}}</li>
    </ul>
  </div>
  <div class="col-lg-9">
    <div class="row mt-2">
      <div class="col-md-7">
        <mat-card-title class="text-center" style="color:#ee8b33;">Precio por persona: ${{metadata.priceAccess}}&nbsp;&nbsp;&nbsp;&nbsp;</mat-card-title>
        <div class="row mt-3">
          <div class="col-4" *ngFor="let object of shoeList">
            <mat-card class="mt-1 mb-1 pl-0 pr-0 pt-0">
              <div class="card-icon">
                <span class="material-icons ">ice_skating</span> No. {{object.size}}
              </div>
              <h6 class="mt-3 text-center">{{object.quantity}} Disponibles</h6>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <mat-card-title class="text-right" style="color:#ee8b33;">Extras</mat-card-title>
        <mat-card class="mt-1 mb-1 pl-0 pr-0 pt-0" *ngFor="let extra of otherChangerList">
          <div class="card-icon" style="background-color:#f3f3f3;display: flex; justify-content: space-between;">
            <div class="">
              <span class="material-icons" style="color:#005c9c;">snowshoeing</span> <strong>{{extra.description}}</strong>
            </div>
            <div class="">
              <strong style="color:#ee8b33;">{{extra.unit_price | currency:'CAD':'symbol-narrow'}}</strong>
            </div>
          </div>
          <h6 class="mt-3 text-center"> {{extra.avalability.length}} Disponibles</h6>
        </mat-card>
      </div>

    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
