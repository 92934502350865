<div class="row justify-content-end mt-0">
  <div class="col-1">
    <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
  </div>
</div>
<div class="row justify-content-center mt-0">
  <div class="col-12 col-md-12">
    <h3 class="naranja">Detalle del turno </h3>
    <ul class="mainContentDetails">
      <li>
        <div class="liDisplay">
          <div class="liItem">
            <strong>Inicio del turno:&nbsp;</strong>{{object.open_date | date: 'dd-MM-yyyy HH:mm'}}  <br>
            <strong>Fin del turno:&nbsp;</strong>{{object.close_date | date: 'dd-MM-yyyy HH:mm'}}
          </div>
          <div class="">
            <strong>Saldos</strong>
            <ul style="list-style-type:none;">
              <li class="userResemenContent"><span>Apertura:&nbsp;</span>{{object.openning_amount | currency:'CAD':'symbol-narrow'}}</li>
              <li class="userResemenContent"><span>Cierre:&nbsp;</span>{{object.clossing_amount | currency:'CAD':'symbol-narrow'}}</li>
              <!-- <li class="userResemenContent"><hr style="width:100%"></li>
              <li class="userResemenContent"><span>Total:&nbsp;</span>{{(object.openning_amount*1 + object.clossing_amount*1 ) | currency:'CAD':'symbol-narrow'}}</li> -->
            </ul>
          </div>
          <div class="">
            <strong>Resumen</strong>
            <ul style="list-style-type:none;" *ngIf="object.totals">
              <li class="userResemenContent"><span>Efectivo:&nbsp;</span>{{object.totals.efectivo | currency:'CAD':'symbol-narrow'}}</li>
              <li class="userResemenContent"><span>Tarjeta:&nbsp;</span>{{object.totals.tarjeta | currency:'CAD':'symbol-narrow'}}</li>
              <li class="userResemenContent"><hr style="width:100%"></li>
              <li class="userResemenContent"><span>Total:&nbsp;</span>{{object.totals.total | currency:'CAD':'symbol-narrow'}}</li>
            </ul>
          </div>
          <div class="">
            <span class="badge badge-success" *ngIf="object.open">Abierto</span>
            <span class="badge badge-danger" *ngIf="!object.open">Cerrado</span>
          </div>
        </div>
      </li>
    </ul>
    <hr>
    <hr>
    <h5>Turnos de usuarios</h5>
    <ul style="list-style-type:none;">
      <li *ngFor="let object of turnUserList">
        <div class="cardUserTurn">
          <div style="display:flex; justify-content: space-between;">
            <p><strong><i class="bi bi-person-badge-fill"></i></strong> {{object.user.name}}</p>
            <span class="badge badge-success" *ngIf="object.open">Abierto</span>
            <span class="badge badge-danger" *ngIf="!object.open">Cerrado</span>
          </div>
          <br>
          <div class="resemenUser">
            <div class="">
              <strong class="naranja">Fecha</strong>

              <ul style="list-style-type:none;">
                <li><span>Inicio del turno:&nbsp;</span>{{object.open_date | date: 'dd-MM-yyyy HH:mm'}}</li>
                <li *ngIf="object.close_date"><span>Fin del turno:&nbsp;</span>{{object.close_date | date: 'dd-MM-yyyy HH:mm'}}</li>
                <li><strong>Ventas totales:&nbsp;</strong>{{object.totals.total | currency:'CAD':'symbol-narrow'}}</li>
              </ul>
            </div>
            <div>
              <strong class="naranja">Resumen mostrador</strong>
              <ul style="list-style-type:none;">
                <li class="userResemenContent"><span>Total de apertura:&nbsp;</span>{{object.openning_amount | currency:'CAD':'symbol-narrow'}}</li>
                <li class="userResemenContent"><hr style="width:100%"></li>
                <li class="userResemenContent" style="justify-content: center;"><span>Cortes</span></li>
                <li class="userResemenContent" *ngFor="let object of object.partialCutList; let i = index;"><span>{{i + 1}}{{object.observation}}:&nbsp;</span>{{object.amount | currency:'CAD':'symbol-narrow'}}</li>
                <li class="userResemenContent"><hr style="width:100%"></li>
                <li class="userResemenContent"><span>Corte de cierre:&nbsp;</span>{{object.clossing_amount_user | currency:'CAD':'symbol-narrow'}}</li>
                <li class="userResemenContent"><hr style="width:100%"></li>
                <li class="userResemenContent"><span>Total de cortes:&nbsp;</span>{{(object.clossing_amount_user*1 + object.totalCortes*1 ) | currency:'CAD':'symbol-narrow'}}</li>
              </ul>
            </div>
            <div>
              <strong class="naranja">Resumen Real</strong>
              <ul style="list-sstyle-type:none;">
                <li class="userResemenContent"><span>Efectivo:&nbsp;</span>{{object.totals.efectivo | currency:'CAD':'symbol-narrow'}}</li>
                <li class="userResemenContent"><span>Tarjeta:&nbsp;</span>{{object.totals.tarjeta | currency:'CAD':'symbol-narrow'}}</li>
                <li class="userResemenContent"><hr style="width:100%"></li>
                <li class="userResemenContent"><span>Total ventas:&nbsp;</span>{{object.totals.total | currency:'CAD':'symbol-narrow'}}</li>
                <!-- <li class="userResemenContent"><span>Total:&nbsp;</span>{{object.clossing_amount | currency:'CAD':'symbol-narrow'}}</li> -->
              </ul>
            </div>
          </div>

        </div>
      </li>
    </ul>
  </div>

</div>
