import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { LoginComponent } from './login/login.component';
import { ReservationUpdateComponent } from './client/reservation/reservation-update/reservation-update.component';
import { ReservationComponent } from './client/reservation/reservation.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { AdminComponent } from './admin/admin.component';
import { SucessComponent } from './client/sucess/sucess.component';
import { ClientFooterComponent } from './client/client-footer/client-footer.component';
import { ClientTopComponent } from './client/client-top/client-top.component';
import { AdminMenuComponent } from './admin/admin-menu/admin-menu.component';
import { AdminMenuService } from './admin/admin-menu/admin-menu.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'

import { AdminToolbarComponent } from './admin/admin-toolbar/admin-toolbar.component';
import { AdminPriceListComponent } from './admin/admin-price-list/admin-price-list.component';
import { AdminPriceListUpdateComponent } from './admin/admin-price-list/admin-price-list-update/admin-price-list-update.component';
import { AdminShoesComponent } from './admin/admin-shoes/admin-shoes.component';
import { AdminShoesUpdateComponent } from './admin/admin-shoes/admin-shoes-update/admin-shoes-update.component';
import { AdminBookingSuccessComponent } from './admin/admin-booking-success/admin-booking-success.component';
import { AvailableComponent } from './client/available/available.component';
import { AdminBookingDetailsComponent } from './admin/admin-booking-details/admin-booking-details.component';
import { ResponsivaComponent } from './client/responsiva/responsiva.component';
import { AdminBookingConfirmComponent } from './admin/admin-booking-confirm/admin-booking-confirm.component';
import { AdminBookingAddComponent } from './admin/admin-booking-add/admin-booking-add.component';
import { AdminBookingExtrasSuccessComponent } from './admin/admin-booking-extras-success/admin-booking-extras-success.component';
import { AdminInitTurnComponent } from './admin/admin-init-turn/admin-init-turn.component';
import { TurnService } from './admin/turn.service';
import { AdminMetricsTurnsComponent } from './admin/admin-metrics/admin-metrics-turns/admin-metrics-turns.component';
import { AdminCloseTurnComponent } from './admin/admin-close-turn/admin-close-turn.component';
import { AdminByeComponent } from './admin/admin-bye/admin-bye.component';
import { AdminMetricsTurnsDetailsComponent } from './admin/admin-metrics/admin-metrics-turns-details/admin-metrics-turns-details.component';
import { PointSaleComponent } from './point-sale/point-sale.component';
import { PointSalePaymentComponent } from './point-sale/point-sale-payment/point-sale-payment.component';
import { PointSalePartialCutUpdateComponent } from './point-sale/point-sale-partial-cut-update/point-sale-partial-cut-update.component';
import { PointSalePartialCutTicketComponent } from './point-sale/point-sale-partial-cut-ticket/point-sale-partial-cut-ticket.component';
import { AdminPromotionListComponent } from './admin/admin-promotion-list/admin-promotion-list.component';
import { AdminPromotionUpdateComponent } from './admin/admin-promotion-list/admin-promotion-update/admin-promotion-update.component';
import { AdminReportsComponent } from './admin/admin-metrics/admin-reports/admin-reports.component';
import { AdminSubscriptionConfigComponent } from './admin/admin-subscription-config/admin-subscription-config.component';
import { AdminSubscriptionConfigUpdateComponent } from './admin/admin-subscription-config/admin-subscription-config-update/admin-subscription-config-update.component';
import { AdminSubscriptionComponent } from './admin/admin-subscription/admin-subscription.component';
import { AdminSubscriptionUpdateComponent } from './admin/admin-subscription/admin-subscription-update/admin-subscription-update.component';

registerLocaleData(localeEs);

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ReservationUpdateComponent,
    ReservationComponent,
    AdminComponent,
    SucessComponent,
    ClientFooterComponent,
    ClientTopComponent,
    AdminMenuComponent,
    AdminToolbarComponent,
    AdminPriceListComponent,
    AdminPriceListUpdateComponent,
    AdminShoesComponent,
    AdminShoesUpdateComponent,
    AdminBookingSuccessComponent,
    AvailableComponent,
    AdminBookingDetailsComponent,
    ResponsivaComponent,
    AdminBookingConfirmComponent,
    AdminBookingAddComponent,
    AdminBookingExtrasSuccessComponent,
    AdminInitTurnComponent,
    AdminMetricsTurnsComponent,
    AdminCloseTurnComponent,
    AdminByeComponent,
    AdminMetricsTurnsDetailsComponent,
    PointSaleComponent,
    PointSalePaymentComponent,
    PointSalePartialCutUpdateComponent,
    PointSalePartialCutTicketComponent,
    AdminPromotionListComponent,
    AdminPromotionUpdateComponent,
    AdminReportsComponent,
    AdminSubscriptionConfigComponent,
    AdminSubscriptionConfigUpdateComponent,
    AdminSubscriptionComponent,
    AdminSubscriptionUpdateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatDialogModule,
    MatPaginatorModule,
    MatCardModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    SessionModule.forRoot(),
    LoadingModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    AdminMenuService,
    TurnService,
    MatDatepickerModule
  ],
  bootstrap: [AppComponent],
  exports:[
    SessionModule,
    LoadingModule,
  ],
  entryComponents:[
    AdminPriceListUpdateComponent,
    AdminBookingSuccessComponent,
    AdminBookingDetailsComponent,
    AdminBookingConfirmComponent,
    AdminBookingAddComponent,
    AdminInitTurnComponent,
    AdminCloseTurnComponent,
    AdminMetricsTurnsDetailsComponent,
    PointSalePaymentComponent,
    PointSalePartialCutUpdateComponent,
    PointSalePartialCutTicketComponent
  ]
})
export class AppModule { }
