import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { TurnService } from '../turn.service';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-admin-bye',
  templateUrl: './admin-bye.component.html',
  styleUrls: ['./admin-bye.component.css']
})
export class AdminByeComponent implements OnInit {
  turnUserModel = {
    id:'',
    open:true,
    open_date:'',
    close_date:null,
    user:{
      id:''
    },
    turn:{
      id:''
    },
    clossing_amount:0,
    openning_amount:0,
    check:false,
    clossing_amount_user:0
  }
  metadata = {
    total:0,
    totalCortesias:0,
    totalEfectivo:0,
    totalTarjeta:0,
    totalSales:0,
    totalCortes:0,
    totalCortesAddCierre:0
  }
  object = JSON.parse(JSON.stringify(this.turnUserModel))
  bookingMin = {
    status:'Pagado', //Pagado | Pendiente
    filter:'',
    max: 100,
    page: 1,
    turn_user:{
      id:''
    }
  }
  bookingsList = [];
  paginator = {
    total:0,
    pageSizeOptions:[50, 100, 200, 300],
  };
  pageEvent: PageEvent;
  partialCutList = [];
  partialCutMin = {
    filter:'',
    max: 100,
    page: 1,
    turn_user:{
      id:''
    }
  }

  constructor(
    private session: SessionService,
    public loadding:LoadingService,
    public turnService:TurnService,
    private router: Router, private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.object.id = params['turnUserId'];
      //funcionalidad para
      this.loadding.show(true,"Espere un momento...")
      this.session.postRequestAnonimus("turnUser:get",this.object).subscribe((data:any)=>{
        this.object = data.object;
        this.getBooking();
        this.getPartialCut();
        this.getTotals(this.object);
        // console.log(this.object);
        this.loadding.hide();
      },error=>{
        console.log(error);
        this.loadding.hide();
      })
    });
  }


  getBooking(){
    this.loadding.show(true,"Espere un momento...");
    this.bookingMin.turn_user.id = this.object.id;
    this.session.postRequest("turnSale:findAllByTurnUser",this.bookingMin).subscribe((data:any)=>{
      this.bookingsList = data.object.instanceList.map((item)=>{
        return item.booking;
      });
      this.paginator.total = data.object.total;
      this.metadata.totalSales =  data.object.total;
      this.loadding.hide();
    },error=>{
      console.log(error);
      this.loadding.hide();
    })
  }

  getPartialCut(){
    this.loadding.show(true,"Espere un momento...");
    this.partialCutMin.turn_user.id = this.object.id;
    this.session.postRequest("partialCut:findAllByTurnUser",this.partialCutMin).subscribe((data:any)=>{
      this.partialCutList = data.object.instanceList.map((item)=>{
        this.metadata.totalCortes = this.metadata.totalCortes + (item.amount*1);
        return item;
      });
      console.log(this.metadata);
      this.loadding.hide();
    },error=>{
      console.log(error);
      this.loadding.hide();
    })
  }

  /**obtenemos los totales del turno
  */
  getTotals(object){
    this.session.postRequest("turnSale:getTotalByTurnUser",{turn_user:{id:object.id}}).subscribe((data:any)=>{
      object.totals = data.object;
    },error=>{
      console.log(error);
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.bookingMin.max = event.pageSize;
    this.bookingMin.page = event.pageIndex + 1;
    this.getBooking();
  }

}
