<div class="row justify-content-end mt-0">
  <div class="col-1">
    <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
  </div>
  <div class="col-12">
    <h4 class="naranja">Detalles</h4>
  </div>
  <div class="col-12">
    <div class="row justify-content-end m-0">
      <p class="col-12">Detalles del cliente</p><br>
      <ul class="col-12">
        <li><strong>Folio:&nbsp;</strong>{{data.folio}}</li>
        <li><strong>Cliente:&nbsp;</strong>{{data.fullname}}</li>
      </ul>
      <hr class="col-12">
      <p class="col-12">Detalles de la reservación</p>
      <ul class="col-12">
        <li><strong>Fecha:&nbsp;</strong>{{data.dateBooking}}</li>
        <li><strong>Horario:&nbsp;</strong>{{data.scheduleBooking}}</li>
        <li></li>
      </ul>

      <ul class="col-12">
        <li><strong>Patines</strong></li>
        <li *ngFor="let object of shoesList"><strong style="color: #4e4e4e !important;">{{object.concept}}:&nbsp;</strong>Nº - {{object.size}}</li>
      </ul>
      <ul class="col-12">
        <li><strong>Extras</strong></li>
        <li *ngFor="let object of extras"><strong style="color: #4e4e4e !important;">{{object.concept}}:&nbsp;</strong> {{object.quantity}} </li>
      </ul>

    </div>
  </div>
  <div class="col-12">
    <div class="row justify-content-end m-0">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-save" (click)="printTicketInstructor();">Imprimir para instructor</button>
      </div>
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-save" (click)="print();">Imprimir</button>
      </div>
    </div>
  </div>
</div>
