<app-client-top></app-client-top>
<div class="row justify-content-center">
  <div class="col-12 col-md-10">
    <h1 >CARTA RESPONSIVA DE PISTA DE HIELO</h1>
    <h3 class="pb-3">Por medio de la presente declaro que:</h3>
    <p class="pb-3">
      Estoy completamente consciente de que la actividad de patinaje sobre hielo que voy a realizar es bajo mi más estricta responsabilidad. Por lo que desde este momento deslindo de cualquier circunstancia o acontecimiento previo o futuro a la <strong>PISTA DE HIELO TECNOMODA</strong>  así como a la <strong>PLAZA TECNOMODA</strong> y/o demás responsables de la actividad, incluyendo de manera enunciativa, más no limitativa cualquier eventualidad o accidente que llegue a presentar dentro de la pista de hielo.
    </p>
    <p class="pb-3">
      Desde este momento manifiesto que se y conozco que la práctica del patinaje sobre hielo está considerada como un deporte riesgoso, que se realizarlo y en razón de ello estoy de acuerdo en asumir a título personal cualquier daño que ocasione tanto a mi persona, como a las instalaciones, así como a terceras personas, por lo que me comprometo a observar en todo momento las indicaciones de seguridad y demás que por mi seguridad me realice el personal asignado, respetar el reglamento de acceso y permanencia en la pista y sus alrededores, no permanecer bajo la influencia de ninguna sustancia legal o ilegal, manifiesto expresamente no estar consumiendo medicinas o sustancias que pongan en riesgo a terceros, así como a cuidar y devolver los patines y demás equipo de protección y mantenerlo en la mismas condiciones de uso y estado en los que los recibo, firmo al calce de la presente expresando mi consentimiento, así como el encontrarme debidamente informado del uso de la pista e instalaciones.
    </p>
  </div>
</div>
<app-client-footer></app-client-footer>
