<!-- <form ngNoForm  [action]="'https://dev.mexpago.com/app/pagoOnline'" target="_blank" [method]="'post'" http-equiv="Content-Type" content="text/html; charset=utf-8"> <Label>Monto:</Label>
<input type="hidden" type="input" name="monto" value="10.00">
<input type="hidden" type="input" name="llave" value="eyJsbGF2ZSI6IjBiY2ZjM2JkLTI4YzYtNDNmNC1hNzAyLTM5NDc2OWJjMDNiMiIsImlkT25saW5lIjoiZDczYTFkOTEtMGIwZC00MTY4LTkxZTktM2JiZmQ4NWZkYjdjIn0=" >
<input type="hidden" name="noTransaccion" value="12345" >
<input type="hidden" name="fecha" value="2020-10-19 19:55:50">
<input type="hidden" name="articulos" value= '{"articulos":[{"descripcion":"Descripcion Articulo #1", "monto":"2.00"}, { "descripcion":" Descripcion Articulo #2", "monto":"4.00" }, {"descripcion":" Descripcion Articulo #3", "monto":"4.00"}] }'>
<input type="hidden" name="precargaDatos" value= '{"nombre":"JOSE","apPaterno":"PEREZ","apMaterno":"GOMEZ","correo":"gustavo.solano@sti.mx","celular":"9512828601" }'>
<input type="hidden" name="enviarCorreo" value= "true">
<input type="hidden" name="infoComercio" value= "true">
<input type="hidden" name="lenguaje" value= "es">
<br><br>
<button type="submit" style="background: white; border: none; "> <img src="https://www.mexpago.com/img/btn-mexpago.png"> </button>
</form> -->
<app-client-top></app-client-top>
<div class="row justify-content-center" style="height:70vh;">
  <div class="col-7">
    <div class="row mt-1 mb-1">
      <div class="col-12 text-center">
        <div class="logo">
          <!-- <img src="assets/img/logo_tulipanes.png" alt="img1" style="height:100%;"> -->
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-10 pt-2">
        <div><p>E-mail</p></div>
        <div class="input-group">

          <input class="form-control" [ngClass]="complexForm.controls['username'].hasError('required') ? 'is-invalid':'is-valid'" type="text" placeholder="Correo electrónico" name="mName"  [(ngModel)]="object.email" [formControl]="complexForm.controls['username']" required>
          <div class="invalid-feedback">
            Se debe ingresar un correo electrónico
          </div>
        </div>

        <div><p>Contraseña</p></div>
        <div class="input-group">
          <input class="form-control" [ngClass]="complexForm.controls['password'].hasError('required') ? 'is-invalid':'is-valid'" type="password" placeholder="Contraseña" name="mPassword" [(ngModel)]="object.password" [formControl]="complexForm.controls['password']" required>
          <div class="invalid-feedback">
            Ingrese su contraseña
          </div>
        </div>


        <!-- <div class="checkbox-1" style="margin-top: -25px;">
          <input type="checkbox" id="ejemplo-1" [(ngModel)]="metadata.remember" value="1" name="" />
          <label for="ejemplo-1" > </label>
          <span style="font-size: .8rem;
            color: #9c9c9c; margin-left: 6px; position: absolute;">&nbsp;Recordarme</span>
        </div> -->

        <div class="form-group text-right">
           <button type="button" class="btn btn-danger mt-4 " (click)="send();" [disabled]="complexForm.invalid">INICIAR SESIÓN</button>
        </div>


    </div>
  </div>
</div>
</div>
v1.0.2
<app-client-footer></app-client-footer>
