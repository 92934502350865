<div class="row justify-content-end mt-0">
  <div class="col-1">
    <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
  </div>
  <div class="col-12">
    <h2 class="naranja">Reservación {{data.folio}}</h2>
  </div>
  <div class="col-12">
    <div class="row justify-content-end m-0">
      <ul class="col-6">
        <li><strong>Folio:&nbsp;</strong>{{data.folio}}</li>
        <li><strong>Cliente:&nbsp;</strong>{{data.fullname}}</li>
      </ul>
      <ul class="col-6">
        <li><strong>Fecha:&nbsp;</strong>{{data.dateBooking}}</li>
        <li><strong>Horario:&nbsp;</strong>{{data.scheduleBooking}}</li>
        <li></li>
      </ul>
      <ul class="col-12">
        <li><strong>Extras</strong></li>
        <li *ngFor="let object of extras"><strong style="color: #4e4e4e !important;">{{object.concept}}:&nbsp;</strong> {{object.quantity}} </li>
      </ul>
      <hr class="col-12">
      <div class="col-12 row">
        <p class="col-12 pb-2">Agregar mas extras</p>
        <div class="col-md-4 col-6 mb-2" *ngFor="let object of bookingDetailList; let i = index;">
          <div class="form-group mb-0" *ngIf="object.type == 'Extra' && object.avalability.length > 0">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="gridCheck{{i}}" [(ngModel)]="object.status" (change)="calculateTotal();">
              <label class="form-check-label labelInputForm blue" for="gridCheck{{i}}">
                {{object.concept}} | {{object.unit_price | currency:'CAD':'symbol-narrow'}} MXN
                <small class="comments-min">Por persona</small> | <small class="comments-min">{{object.avalability.length}} Disponibles</small>
              </label>
            </div>
          </div>
          <div class="form-group" *ngIf="object.type == 'Extra' && object.status">
            <label for="concept" class="labelInputForm">Cantidad</label>
            <select class="form-control" id="quantity{{i}}" name="quantity{{i}}" [(ngModel)]="object.quantity" (ngModelChange)="calculateTotal();">
              <option *ngFor="let avalability of object.avalability" >{{avalability}}</option>
            </select>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-12">
    <div class="row justify-content-end m-0">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-close" (click)="close(false);">CERRAR</button>
      </div>
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-primary btn-save" (click)="sendToPay();">COBRAR</button>
      </div>
    </div>
  </div>
</div>
