import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import * as printJS from 'print-js';

@Component({
  selector: 'app-admin-booking-extras-success',
  templateUrl: './admin-booking-extras-success.component.html',
  styleUrls: ['./admin-booking-extras-success.component.css']
})
export class AdminBookingExtrasSuccessComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AdminBookingExtrasSuccessComponent>,
    private session: SessionService,
    public loadding:LoadingService,
      @Inject(MAT_DIALOG_DATA) public data: any) { }

      ngOnInit(): void {
      }

      closeTru(){
        this.close(true);
      }


      print(){
        this.loadding.show(true,'Espere un momento');
        this.session.postRequest("booking:downloadTicketExtras",this.data).subscribe((data:any)=>{
          printJS({printable:data.object, type: 'pdf', base64: true});
          this.loadding.hide();
        },error=>{
          this.loadding.hide();

        })
      }


      close(status){
        let object = {
          transaction:'',
          code:'',
          object:{}
        };
        if(status){
          object.transaction = 'ok';
          object.object = {};
        }else{
          object.transaction = 'bad';
          object.code = 'price_list:001';
        }
        this.dialogRef.close(object);
      }

}
