import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from './service/session/authentication.guard';
import { LoginComponent } from './login/login.component';
import { ReservationUpdateComponent } from './client/reservation/reservation-update/reservation-update.component';
import { ReservationComponent } from './client/reservation/reservation.component';
import { AdminComponent } from './admin/admin.component';
import { SucessComponent } from './client/sucess/sucess.component';
import { AvailableComponent } from './client/available/available.component';
import { AdminPriceListComponent } from './admin/admin-price-list/admin-price-list.component';
import { AdminShoesComponent } from './admin/admin-shoes/admin-shoes.component';
import { AdminShoesUpdateComponent } from './admin/admin-shoes/admin-shoes-update/admin-shoes-update.component';
import { ResponsivaComponent } from './client/responsiva/responsiva.component';
import { AdminMetricsTurnsComponent } from './admin/admin-metrics/admin-metrics-turns/admin-metrics-turns.component';
import { AdminByeComponent } from './admin/admin-bye/admin-bye.component';
import { PointSaleComponent } from './point-sale/point-sale.component';
import { AdminPromotionListComponent } from './admin/admin-promotion-list/admin-promotion-list.component';
import { AdminReportsComponent } from './admin/admin-metrics/admin-reports/admin-reports.component';
import { AdminSubscriptionConfigComponent } from './admin/admin-subscription-config/admin-subscription-config.component';
import { AdminSubscriptionComponent } from './admin/admin-subscription/admin-subscription.component';

const routes: Routes = [
  { path :'' ,redirectTo:'client/reservation/update' , pathMatch:'full' },
  {path:'login', component:  LoginComponent},
  {path:'client/sucess/:data', component: SucessComponent},
  {path:'client/search/:phone', component: ReservationComponent},
  {path:'client/reservation/update', component: ReservationUpdateComponent},
  {path:'client/available', component: AvailableComponent},
  {path:'admin/dashboard', component: AdminComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','COUNTER','SUPERVISOR']}},
  {path:'admin/bye/:turnId/:turnUserId', component: AdminByeComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','COUNTER','SUPERVISOR']}},
  {path:'admin/prices', component: AdminPriceListComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','SUPERVISOR']}},
  {path:'admin/metrics/resports', component:  AdminReportsComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  {path:'admin/shoes', component: AdminShoesComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','SUPERVISOR']}},
  {path:'admin/promotions', component: AdminPromotionListComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','SUPERVISOR']}},
  {path:'admin/subscription/config', component: AdminSubscriptionConfigComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','SUPERVISOR']}},
  {path:'admin/subscription/manager', component: AdminSubscriptionComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','SUPERVISOR']}},
  {path:'admin/shoes/update', component: AdminShoesUpdateComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','SUPERVISOR']}},
  {path:'admin/metrics/turns', component: AdminMetricsTurnsComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','SUPERVISOR']} },
  {path:'client/responsiva', component: ResponsivaComponent },
  {path:'pointsale', component: PointSaleComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR','COUNTER','SUPERVISOR']}},
  // Acceso con permiso -> {path:'dashboard', component: AdminDashboardComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  // Acceso usuario anonimo -> {path:'dashboard', component: AdminDashboardComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']}},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash:true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
