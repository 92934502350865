<mat-drawer-container class="drawerConteiner" >
    <mat-drawer #drawer class="mainDrawer" mode="side">
      <app-admin-menu></app-admin-menu>
    </mat-drawer>
    <mat-drawer-content class="drawerContent">
      <app-admin-toolbar (menuToogle)="toogleMenu($event)"></app-admin-toolbar>
      <!-- aqui va el contenido -->
      <div class="row justify-content-center m-0" style="margin-top: 30px!important;">
  
        <div class="col-11 col-md-10">
          <div class="row justify-content-between mb-2 mt-2">
            <div class="col-md-4 col-6" style="padding: 5px;"><h5 class="blue">Membresias activas</h5></div>
            <div class="col-4 col-md-4 alingEnd">
              <button type="button" class="btn btn-link naranja" (click)="update('new');"><i class="bi bi-plus-circle"></i> Activar membresía</button>
            </div>
          </div>
          <div class="row">
            <table class="compra">
              <tr>
                <th>Nombre</th>
                <th>Membresía</th>
                <th>Ultima visita</th>
                <th>Estatus</th>
                <th></th>
              </tr>
              <tr *ngFor="let object of list">
                <!-- <td>{{object.size}}</td>
                <td>{{object.quantity}}</td>
                <td>
                  <button type="button" class="btn btn-link naranja" (click)="update(object.id);"><i class="bi bi-pencil-square"></i></button>
                  <button type="button" class="btn btn-link naranja" (click)="delete(object);"><i class="bi bi-trash"></i></button>
                </td> -->
              </tr>
            </table>
          </div>
          <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
            <div class="col-6 ">
              <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
      <app-client-footer></app-client-footer>
    </mat-drawer-content>
  </mat-drawer-container>
  