import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ViewEncapsulation, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../directive/loading/loading.module';
import { SessionService } from '../service/session/session.module';
import * as moment from 'moment';
moment.locale('es-us');
import * as Rx from 'rxjs/Rx';
import { CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { AdminBookingSuccessComponent } from './admin-booking-success/admin-booking-success.component';
import { MatDialog } from '@angular/material/dialog';
import { AdminBookingDetailsComponent } from '../admin/admin-booking-details/admin-booking-details.component';
import * as printJS from 'print-js';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { AdminBookingConfirmComponent } from '../admin/admin-booking-confirm/admin-booking-confirm.component';
import { AdminBookingAddComponent } from './admin-booking-add/admin-booking-add.component';
import { PageEvent } from '@angular/material/paginator';
import { AdminInitTurnComponent } from './admin-init-turn/admin-init-turn.component';
import { TurnService } from './turn.service';
import { AdminCloseTurnComponent } from './admin-close-turn/admin-close-turn.component';
import { PointSalePaymentComponent } from '../point-sale/point-sale-payment/point-sale-payment.component';
import { PointSalePartialCutUpdateComponent } from '../point-sale/point-sale-partial-cut-update/point-sale-partial-cut-update.component';
import { PointSalePartialCutTicketComponent } from '../point-sale/point-sale-partial-cut-ticket/point-sale-partial-cut-ticket.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AdminComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  @ViewChild('acc',{static:false})acc: NgbAccordion;
  @ViewChild('top',{static:false})top: ElementRef;

  bookingMin = {
    status:'Pagado', //Pagado | Pendiente
    filter:'',
    max: 100,
    page: 1,
    turn_user:{
      id:''
    }
  }


  bookingsList = [];

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  clickedDate: Date;
  clickedColumn: number;
  bookingModel = {
    id:'',
    sendEmail:false,
  	folio:'',
  	fullname:'',
  	phone:'',
    email:'',
  	dateBooking: moment().format('YYYY-MM-DD'),
  	scheduleBooking:'', //string 13:00 - 14:00,
  	people: 1,
  	bowlings: 1, // numero de lineas a reservar
  	peopleUnitPrice: 0,
  	bowlingsUnitPrice: 0,
  	discount: 0.00,
  	subtotal: 0.00,
  	tax: 0.00,
  	total: 0.00,
    terms: true,
    privacy:true,
  	type_payment:'Efectivo', //mexpago, paypal, referencia
  	payment_id:'',
  	status:'Pagado', //pagado, pendiente, cancelado.
    bookingDetails:[]
  }
  bookingDetailModel = {
    id:'',
    concept:'',
    unit_price:0.00,
    quantity:0,
    discount:0.00,
    subtotal:0.00,
    tax: 0.00,
    total: 0.00,
    type:'',
    booking:{
      id:''
    },
    size:'',
    status:false,
  }
  priceObjectModel = {
    id:'',
    day:null,
    time:'1',
    description:'',
    unit_price:'',
    people:1,
    shoes:false,
    allDays:false,
    status:true,
    access:false
  }
  object = JSON.parse(JSON.stringify(this.bookingModel));

  locale: string = 'es';
  shedules = [
    '12:00 - 13:00',
    '13:15 - 14:15',
    '14:45 - 15:45',
    '16:00 - 17:00',
    '17:30 - 18:30',
    '18:45 - 19:45',
    '20:00 - 21:00',
  ];
  defaultShedules = JSON.parse(JSON.stringify(this.shedules));

  peopleList = [];
  priceMin = {
    status:true,
    max: 10,
    page: 1,
  }
  priceList = [];
  accessPriceList = [];
  otherChangerList = [];
  bookingDetailList = [];
  shoeList = [];
  promoModel = {
    id:'',
    name:'',
    type_price:'',
    type_quantity:'',
    quantity:0,
    price:0.0,
    permanent:false,
    date:null,
    observation:''
  }
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    searching:false,
    name:'',
    firtlastname:'',
    secondlastname:'',
    msj:'',
    priceSelected:JSON.parse(JSON.stringify(this.priceObjectModel)),
    turnOpen:false,
    showGlobalReservation:true,
    role:'',
    promoSelected:JSON.parse(JSON.stringify(this.promoModel))
  }
  paymentMethods = [
    {
      name:'Efectivo',
      value:'Efectivo',
      icon:'bi bi-cash'
    },{
      name:'Tarjeta',
      value:'Tarjeta',
      icon:'bi bi-credit-card'
    },{
      name:'Cortesía',
      value:'Cortesia',
      icon:'bi bi-person-badge'
    }
  ]
  paginator = {
    total:0,
    pageSizeOptions:[50, 100, 200, 300],
  };
  pageEvent: PageEvent;
   user = {
     id:'',
     name:''
   }

   promotionMin = {
     max: 100,
     page: 1,
     date:null
   };
   promotions = [];

   metricsMin = {
     user:{
       id:''
     },
     max: 100,
     page: 1,
     date:null
   };
   historySales = [];
   paginatorHistory = {
     total:0,
     pageSizeOptions:[ 2 ,5, 10, 25, 100],
   };
   pageEventHistory: PageEvent;

  constructor(
    protected session: SessionService,
    public loading: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public turnService:TurnService,
    private _snackBar: MatSnackBar
  ) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.bookingsList = [];
          this.getBookingSearching();
        } else {
          this.bookingsList = [];
          this.bookingMin.filter = '';
          this.getBooking();
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        console.log(error);
      });
  }

  async ngOnInit() {
    let roles = this.session.getRoles();
    this.metadata.role = roles[0]
    this.user =  JSON.parse(JSON.stringify(this.session.getSession().user));
    this.metricsMin.user.id = this.user.id;
    this.checkAndGetTurn();
    await this.loadPrices();
    this.daySelected({day:{date:new Date()}})
  }

  /**funcion para obtener las refervaciones por numero telefonico
  */
  getBooking(){
    let roles = this.session.getRoles();
    if(this.metadata.showGlobalReservation){
      this.loading.show(true,"Espere un momento...");
      this.metadata.searching = false;
      this.session.postRequest("booking:findAllByStatus",this.bookingMin).subscribe((data:any)=>{
        this.bookingsList = JSON.parse(JSON.stringify(data.object.instanceList));
        this.paginator.total = data.object.total;
        this.loading.hide();
      },error=>{
        console.log(error);
        this.loading.hide();
      })
    }else{
      this.loading.show(true,"Espere un momento...");
      this.metadata.searching = false;
      this.bookingMin.turn_user.id = this.turnService.turnUser.id;
      this.session.postRequest("turnSale:findAllByTurnUser",this.bookingMin).subscribe((data:any)=>{
        this.bookingsList = data.object.instanceList.map((item)=>{
          return item.booking;
        });
        this.paginator.total = data.object.total;
        this.loading.hide();
      },error=>{
        console.log(error);
        this.loading.hide();
      })
    }

  }

  /**fucnionalidad para cambiar las reservaciones globales
  */
  showGlobalReservation(globalStatus){
    this.metadata.showGlobalReservation = globalStatus;
    this.bookingMin.status = 'Pagado';
    this.bookingMin.page = 1;
    this.getBooking();
  }

  /**funcion para obtener las refervaciones por busqueda
  */
  getBookingSearching(){
    this.metadata.searching = true;
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("booking:findByNameOrFolio",this.bookingMin).subscribe((data:any)=>{
      this.bookingsList = data.object.instanceList;
      this.historySales = data.object.instanceList;
      this.loading.hide();
    },error=>{
      console.log("error:booking:findByNameOrFolio",error);
      this.loading.hide();
    })
  }

  /*
    *Funcion para buscar
  */
  search() {
    this.metadata.searchBoxInput.next(this.bookingMin.filter);
  }

  /**funcion que se ejecuta cuando se cambia un tab
  */
  changeTab(ev){
    // console.log(ev);
    if(ev == 2){
      this.bookingMin.status = 'Pagado';
      this.bookingMin.page = 1;
      // console.log(this.bookingMin);
      this.getBooking();
    }

    if(ev == 3){
      this.metricsMin.page = 1;
      this.getSales();
    }

  }

  recargarBookign(){
    this.getBooking();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }


  /**Metodos de reservacion ==================================
  */

  pay(object) {
  }

  daySelected(ev){
    let today = moment();
    let selectedDay = moment(ev.day.date);
    // ev.day.cssClass = 'bg-selected-day';
    if(selectedDay.diff(today,'day') >= 0){
      this.object.dateBooking = moment(ev.day.date).format('YYYY-MM-DD');
      this.clickedDate = ev.day.date;
      this.priceSelectedByDay();
      this.object.scheduleBooking = '';
      this.findAllPromotionsByDate(this.clickedDate);
    }else{
      // console.log("Este dia no se puede seleccionar");
    }
    // console.log("------------",this.object);
  }
  /**función para calcular el totla
  */
  calculateTotal(){
    let tickets = 0;
    this.object.subtotal = 0;
    this.object.discount = 0;
    this.object.tax = 0;
    this.object.total = 0;

    //Reglas de precio de ticket
    //calculamos los ticket a utilizar para calcular el precio
    tickets = Math.round(this.object.people / this.metadata.priceSelected.people);
    // console.log("Tickets:::::::",tickets);
    this.object.subtotal = (tickets * this.metadata.priceSelected.unit_price)/1.16;
    this.object.tax = (tickets * this.metadata.priceSelected.unit_price) - this.object.subtotal;

    //Verificamos si hay extra agregados
    for(let  i =0; i < this.bookingDetailList.length; i++){
      this.bookingDetailList[i].subtotal = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price)/1.16;
      this.bookingDetailList[i].tax = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price) - this.bookingDetailList[i].subtotal;
      this.bookingDetailList[i].total = this.bookingDetailList[i].subtotal + this.bookingDetailList[i].tax - this.bookingDetailList[i].discount;
      if(this.bookingDetailList[i].status && this.bookingDetailList[i].type== 'Extra' ){
        this.object.subtotal = this.object.subtotal + this.bookingDetailList[i].subtotal;
        this.object.tax = this.object.tax + this.bookingDetailList[i].tax;
      }
    }

    // this.object.subtotal = ((this.object.people * this.object.peopleUnitPrice) + (this.object.bowlings * this.object.bowlingsUnitPrice))/1.16;
    // this.object.tax = ((this.object.people * this.object.peopleUnitPrice) + (this.object.bowlings * this.object.bowlingsUnitPrice)) - this.object.subtotal;
    this.object.total =  this.object.subtotal + this.object.tax - this.object.discount;
    // console.log(this.object);
  }

  /************ Funciones de Pagos *******************/
    /** función para cambiar el methodo de pago
    */
   async sendToPayEfective(){
     const dialogRef = this.dialog.open(PointSalePaymentComponent, {
       width: '56rem',
       data:{object:this.object}
     });
     dialogRef.afterClosed().subscribe(async(result) => {
       if(result != undefined ){
         if(result.transaction == 'ok'){
           this.object.fullname =  this.metadata.name+' '+this.metadata.firtlastname+' '+this.metadata.secondlastname;
           this.object.bookingDetails = [];
           for(let item of this.bookingDetailList){
             if(item.status)
              this.object.bookingDetails.push(item);
           }
            switch(this.object.type_payment){
              case 'Tarjeta':
              case 'Efectivo':{
                this.metadata.msj = '';
                this.object.payment = {};
                this.object.status = "Pagado";
                this.loading.show(true,"Espere un momento...");
                let sale:any = await this.sendSale();
                //funcionalidad para enviar a imprimir
                // this.print(sale);
                this.loading.hide();
                this.metadata.msj ="Gracias por tu compra";
                // console.log("Gracias por tu compra");
                this.scrollTo(this.top.nativeElement);
                this.acc.toggle('toggle-1');
                this.resetBooking(sale);
                break;
              }
              case 'Cortesia':{
                const dialogRef = this.dialog.open(AdminBookingConfirmComponent, {
                  width: '36rem',
                  disableClose:true,
                });
                dialogRef.afterClosed().subscribe(async(result) => {
                  if(result != undefined ){
                    if(result.transaction == 'ok'){
                      // El modal se cerro con objeto
                      this.metadata.msj = '';
                      this.object.payment = {};
                      this.object.status = "Pagado";
                      //agregamos la cortesia para pasar los saldos a cero
                      let auxBooking = JSON.parse(JSON.stringify(this.bookingDetailModel));
                      auxBooking.concept = "Cortesía";
                      auxBooking.discount = this.object.total;
                      auxBooking.status = true;
                      auxBooking.type = "Cortesia";
                      //verificamos los totales
                      this.object.discount = this.object.total;
                      auxBooking.total = auxBooking.subtotal + auxBooking.tax - auxBooking.discount;
                      this.object.total =  this.object.subtotal + this.object.tax - this.object.discount;
                      //fin los totales
                      this.object.bookingDetails.push(auxBooking);
                      this.loading.show(true,"Espere un momento...");
                      let sale:any = await this.sendSale();
                      //funcionalidad para enviar a imprimir
                      // this.print(sale);
                      this.loading.hide();
                      this.metadata.msj ="Gracias por tu compra";
                      // console.log("Gracias por tu compra");
                      this.scrollTo(this.top.nativeElement);
                      this.acc.toggle('toggle-1');
                      this.resetBooking(sale);
                    }else{
                      // El modal se cerro sin objeto
                    }
                  }else{
                    // El modal se cerro sin seleccionar algo, dandole click fuera
                  }
                });
                break;
              }
              default:{
                console.log("Error, selecciona un metodo de pago");
                this.metadata.msj = "Error, selecciona un metodo de pago";
                break;
              }
            }
         }else{
           // El modal se cerro sin objeto
         }
       }else{
         // El modal se cerro sin seleccionar algo, dandole click fuera
       }
     });
    }

    /** función para enviar lso datos de venta al backend
    */
    sendSale(){
      return new Promise((resolve,reject)=>{
        this.session.postRequest("booking:update",this.object).subscribe((data:any)=>{
          // WTF!! son las 1:25AM y parece que ya va a quedar.
          // console.log("WTF al fin!!",data);
          resolve(data.object);
        },error=>{
          // console.log("error:sale:update",error);
          reject(error);
          this.loading.hide();
        })
      })
    }


    /**watcher para determinar un cambio en el calendario
    */
    beforeMonthViewRender(renderEvent:CalendarMonthViewBeforeRenderEvent){
      renderEvent.body.forEach((day) => {
        const dayOfMonth = day.date;
        let today = new Date();
        if (dayOfMonth < today) {
          day.cssClass = 'bg-disabled';
        }
        if (dayOfMonth > today) {
          day.cssClass = 'bg-enabled';
        }
        if(today.getDate() == dayOfMonth.getDate() && today.getMonth() == dayOfMonth.getMonth()){
          day.cssClass = 'bg-enabled';
        }
      });
    }


    /**funcionalidad para obetner la lista de precios vigente
    */
    loadPrices(){
      return new Promise((resolve,reject)=>{
        this.session.postRequest("price_list:findAllByStatus",this.priceMin).subscribe((data:any)=>{
          this.priceList = data.object.instanceList;
          this.accessPriceList = this.priceList.filter(object => object.access == true);
          this.otherChangerList = this.priceList.filter(object => object.access == false);
          this.preloadBookingDetails();
          // console.log("priceList",this.priceList);
          // console.log("accessPriceList",this.accessPriceList);
          // console.log("otherChangerList",this.otherChangerList);
          resolve(true);
        },error=>{
          console.log("error:price_list:findAllByStatus",error);
          reject(error);
        })
      })

    }

    /**funcionalida para cargar el precio de la reservación
    */
    priceSelectedByDay(){
      let daySelected = this.clickedDate;
      let priceAccess:any = {};
      for(let item of this.accessPriceList){
        if(item.allDays){
          priceAccess = JSON.parse(JSON.stringify(item));
          break;
        }
        if(item.day == daySelected.getDay()){
          priceAccess = JSON.parse(JSON.stringify(item));
        }
      }
      this.metadata.priceSelected = priceAccess;
      this.shedules = JSON.parse(JSON.stringify(this.defaultShedules));
      //verificamos si el dia el fin de semana para agregar horarios extras
      if(this.metadata.priceSelected.day == 0 || this.metadata.priceSelected.day == 6  ){
        this.shedules.unshift('10:15 - 11:15');
        this.shedules.unshift('9:00 - 10:00');
      }
      // this.calculateAvalibility();
      // console.log(">>>>>>>>>>>",priceAccess);
    }

    /** fucnionalidad para cargar cuando espacios hay disponibles en la hora de pista seleccionada
    */
    calculateAvalibility(){
      this.peopleList = [];
      if(this.object.dateBooking != ''){
        this.session.postRequest("booking:findSpaceByScheduleAndDate",{date_booking:this.object.dateBooking,schedule_booking:this.object.scheduleBooking}).subscribe((data:any)=>{
          let start = 1;
          let ends = 60 - data.object
          for(let i =start; i <= ends; i++){
            this.peopleList.push(i);
          }
          // console.log("Disponilidad de espacios::::",this.peopleList.length);
        },error=>{
          console.log("error:booking:findSpaceByScheduleAndDate",error);
        })
      }
    }

    /**funcionalidad para precargar datos el bookigndetails
    */
    preloadBookingDetails(){
      this.bookingDetailList = [];
      //calculamos disponibilidad de monitos, instructores
      for(let i = 0; i < this.otherChangerList.length; i++){
        if(this.otherChangerList[i].description == 'Instructor personalizado'){
          this.otherChangerList[i].avalability = [1,2,3,4];
        }else{
          this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8];
        }
        if(this.otherChangerList[i].description == 'Calcetines'){
          this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30];

        }
        if(this.otherChangerList[i].description == 'Lockers'){
          this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30,31,32];
        }
        if(this.otherChangerList[i].description == 'Ayudante Delfin'){
          this.otherChangerList[i].avalability = [1,2,3,4];
        }
        if(this.otherChangerList[i].description == 'Ayudante monito Olaf'){
          this.otherChangerList[i].avalability = [1,2,3,4,5,6,7];
        }
        if(this.otherChangerList[i].description == 'Ayudante monito santa'){
          this.otherChangerList[i].avalability = [1,2,3];
        }
      }
      for(let object of this.otherChangerList){
        let aux = JSON.parse(JSON.stringify(this.bookingDetailModel));
        aux.concept = object.description;
        aux.unit_price = object.unit_price;
        aux.quantity = object.people;
        aux.type = 'Extra';
        aux.avalability = object.avalability;
        this.bookingDetailList.push(aux);
      }
    }

    /**función para cargar disponibilidad de los articulos extras
    */
    getAvalabitlityByConcept(object){
      let concepts = [];
      for(let item of object){
        if(item.type == 'Extra'){
          concepts.push(item.concept);
        }
      }
      this.session.postRequest("booking:findStockByScheduleAndConceptList",{date_booking:this.object.dateBooking,schedule_booking:this.object.scheduleBooking, concept:concepts}).subscribe((data:any)=>{
        for(let item of data.object){
          for(let i = 0; i < object.length; i++){
            if(item.concept == object[i].concept ){
              for(let j = 1 ; j <= item.used; j++){
                object[i].avalability.pop();
              }
            }
          }
        }
        // for(let i = 1 ; i <= data.object; i++){
        //   object.avalability.pop();
        // }
      },error=>{
        console.log("error:booking:findStockByScheduleAndConceptList",error);
      })
    }

    /**funcionalidad para cargar los zapatos seleccionados
    */
    loadShoesOnBookingDetails(){
      // incializamos lo ncesario para el detalle de zapatos del booking
      for(let i = 0; i < this.bookingDetailList.length; i++){
        if(this.bookingDetailList[i].type == 'Shoes'){
          this.bookingDetailList.splice(i,1);
          i--;
        }
      }
      for(let j = 0; j < this.object.people; j++){
        let aux = JSON.parse(JSON.stringify(this.bookingDetailModel));
        aux.unit_price = 0;
        aux.quantity = 1;
        aux.type = "Shoes";
        aux.status = true;
        this.bookingDetailList.push(aux);
      }
    }

    /**funcioanalidad para obetner los zapatos
    */
    loadShoes(){
      this.session.postRequest("shoes:findAllByStatus",{status:true}).subscribe((data:any)=>{
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].use = 0;
        }
        //Ordenamos los zapatos
        this.shoeList = data.object.instanceList.sort((a,b)=>{
          if(a.size < b.size){
            return -1;
          }
          if(a.size > b.size){
            return 1;
          }
          return 0;
        });
        this.getShoesAvalabotlity(this.shoeList);
      },error=>{
        console.log("error:price_list:findAllByStatus",error);
      })
    }

    /**Cargamos las disponibilidad de lo extra
    */
    loadAvalabilityExtra(){
      //Aqui verificamos
      for(let i = 0; i < this.bookingDetailList.length; i++){
        if(this.bookingDetailList[i].type == 'Extra'){
          if(this.bookingDetailList[i].concept == 'Instructor personalizado'){
            this.bookingDetailList[i].avalability = [1,2,3,4];
          }else{
            this.bookingDetailList[i].avalability = [1,2,3,4,5,6,7,8];
          }
          if(this.bookingDetailList[i].concept == 'Calcetines'){
            this.bookingDetailList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30];

          }
          if(this.bookingDetailList[i].concept == 'Lockers'){
            this.bookingDetailList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30,31,32];
          }
          if(this.bookingDetailList[i].concept == 'Ayudante Delfin'){
            this.bookingDetailList[i].avalability = [1,2,3,4];
          }
          if(this.bookingDetailList[i].concept == 'Ayudante monito Olaf'){
            this.bookingDetailList[i].avalability = [1,2,3,4,5,6,7];
          }
          if(this.bookingDetailList[i].concept == 'Ayudante monito santa'){
            this.bookingDetailList[i].avalability = [1,2,3];
          }
        }
      }
      this.getAvalabitlityByConcept(this.bookingDetailList)
    }


    getShoesAvalabotlity(object){
      let sizes = [];
      for(let item of object){
        sizes.push(item.size);
      }
      this.session.postRequest("booking:findShoesSizeByScheduleList",{date_booking:this.object.dateBooking,schedule_booking:this.object.scheduleBooking, size:sizes}).subscribe((data:any)=>{
        for(let item of data.object){
          for(let i =0; i <object.length; i++){
            if(item.size == object[i].size){
              object[i].avalability = item.used;
              object[i].quantity = object[i].quantity - object[i].avalability;
            }
          }
        }

      },error=>{
        console.log("error:booking:findShoesSizeByScheduleList",error);
      })
    }

    /**funcionalidad para saber si todavia es posible seleccionar el par de patines
    */
    checkShoesAvalaibility(object){
      for(let i = 0; i < this.shoeList.length; i++){
        if(object.size == this.shoeList[i].size){
          let avalability = this.shoeList[i].quantity - this.shoeList[i].use;
          if(avalability > 0){
            this.shoeList[i].use++;
          }else{
            object.size = '';
            object.error = "Lo sentimos ya no hay disponilidad para este par, seleccione otro por favor.";
          }
        }
      }
    }

  /**Funcionalidad para terminar el detalle del booking
  */
  finisBookingDetails(){
    for(let i = 0; i < this.bookingDetailList.length; i++){
      if(this.bookingDetailList[i].type == 'Ticket'){
        this.bookingDetailList.splice(i,1);
      }
    }
    let aux = JSON.parse(JSON.stringify(this.bookingDetailModel));
    aux.concept = this.metadata.priceSelected.description;
    aux.unit_price = this.metadata.priceSelected.unit_price;
    // let tickets = Math.round(this.object.people / this.metadata.priceSelected.people);
    let tickets = this.object.people;
    aux.quantity = tickets;
    aux.subtotal = (tickets * this.metadata.priceSelected.unit_price)/1.16;
    aux.tax = (tickets * this.metadata.priceSelected.unit_price) - aux.subtotal;
    aux.total = aux.subtotal + aux.tax;
    aux.status = true;
    aux.type = "Ticket";
    this.bookingDetailList.unshift(aux);
  }

  resetBooking(sale){
    this.turnService.addBookingToTurn(sale);
    const dialogRef = this.dialog.open(AdminBookingSuccessComponent, {
      width: '40rem',
      disableClose:true,
      data:sale
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.object = JSON.parse(JSON.stringify(this.bookingModel));
          this.metadata.firtlastname = '';
          this.metadata.name = '';
          this.metadata.secondlastname = '';
          this.metadata.priceSelected = JSON.parse(JSON.stringify(this.bookingDetailModel));
          this.loadPrices();
          this.calculateAvalibility();
          this.daySelected({day:{date:new Date()}})
          this.metadata.promoSelected = JSON.parse(JSON.stringify(this.promoModel));
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });

  }

  moreBookingDetails(object){
    const dialogRef = this.dialog.open(AdminBookingDetailsComponent, {
      width: '36rem',
      height: '40rem',
      data:object
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });

  }

  scrollTo(element:HTMLElement){
    setTimeout(function(){
      element.scrollIntoView({behavior: 'smooth'});
    }, 100);
  }


  print(object){
    this.loading.show(true,'Espere un momento');
    this.session.postRequest("booking:downloadTicket",{id:object.id}).subscribe((data:any)=>{
      printJS({printable:data.object, type: 'pdf', base64: true});
      this.loading.hide();
    },error=>{
      this.loading.hide();
    });
  }

  /**funcionalidad para cargar los datos del cliente
  */
  loadPublicClient(){
    this.metadata.name = "PUBLICO";
    this.metadata.firtlastname ="EN";
    this.metadata.secondlastname = "GENERAL";
    this.object.phone="0000000000";
    this.object.email="cobranza@tecnomoda.mx";
  }

  cleanClient(){
    this.metadata.name = "";
    this.metadata.firtlastname ="";
    this.metadata.secondlastname = "";
    this.object.phone="";
    this.object.email="";
  }

  addExtraBookings(object){
    const dialogRef = this.dialog.open(AdminBookingAddComponent , {
      width: '46rem',
      height: '80%',
      data:object
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          this.getBooking();
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.bookingMin.max = event.pageSize;
    this.bookingMin.page = event.pageIndex + 1;
    this.getBooking();
  }

/****
* Inicia seccion de métodos del turno
**/

/** funcionalidad para saber si ya existe un turno iniciado
*/
async checkAndGetTurn(){
  let username = this.session.getSession().user.email;
  let rol = this.session.getRoles()[0];
  if(rol != 'ADMINISTRATOR' && rol != 'SUPERVISOR'){
    let checkTurn:any = await this.turnService.checkTurn();
    this.metadata.turnOpen = checkTurn;
    if(!checkTurn){
      // como no tenemos un turno iniciado... procedemos a inicializar uno
      await this.turnService.openTurn();
    }

    let checkTurnUser = await this.turnService.checkTurnUser();

    if(!checkTurnUser){
      // como no hay ningun turno de usuario iniciado
      const dialogRef = this.dialog.open(AdminInitTurnComponent, {
        width: '36rem',
        height: '80%',
        disableClose:true
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
          }else{
            // El modal se cerro sin objeto
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

  }

}

/**funcionalidad para cerra el turno
*/
async closeSession(){
  try{
    let username = this.session.getSession().user.email;
    let rol = this.session.getRoles()[0];
    if(rol != 'ADMINISTRATOR' && rol != 'SUPERVISOR'){
      if(this.turnService.turnUser.id != ''){
        const dialogRef = this.dialog.open(AdminCloseTurnComponent, {
          width: '36rem',
          height: '80%',
          disableClose:true
        });
        dialogRef.afterClosed().subscribe(async(result) => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              let fisheTurn:any = await this.turnService.closeTurn(result.object.clossing_amount_user);
              this.router.navigate(['/admin/bye/'+fisheTurn.turn.id+'/'+fisheTurn.id]);
            }else{
              // El modal se cerro sin objeto
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
          }
        });
      }else{
        this.router.navigate(['login']);
      }
    }else{
      this.router.navigate(['login']);
    }

  }catch(e){
    console.log(e);
    this.router.navigate(['login']);
  }
}


/**funcionalidad para agregar un corte parcial
*/
async newPartiaCut(){
  try{
    let username = this.session.getSession().user.email;
    let rol = this.session.getRoles()[0];
    if(rol != 'ADMINISTRATOR' && rol != 'SUPERVISOR'){
      if(this.turnService.turnUser.id != ''){
        const dialogRef = this.dialog.open(PointSalePartialCutUpdateComponent, {
          width: '36rem',
          height: '80%',
          disableClose:true
        });
        dialogRef.afterClosed().subscribe(async(result) => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // console.log(result);
              let partialCut:any = await this.turnService.partialCut(result.object);
              const dialogRef = this.dialog.open(PointSalePartialCutTicketComponent, {
                width: '36rem',
                data:partialCut,
                disableClose:true
              });
              dialogRef.afterClosed().subscribe(result => {
                if(result != undefined ){
                  if(result.transaction == 'ok'){
                    //no hacemos nada
                  }else{
                    // El modal se cerro sin objeto
                  }
                }else{
                  // El modal se cerro sin seleccionar algo, dandole click fuera
                }
              });
            }else{
              // El modal se cerro sin objeto
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
          }
        });
      }else{
      }
    }else{
    }

  }catch(e){
    console.log(e);
  }
}

  /**funcionalidad para obtener las promociones de un dia
  */
  findAllPromotionsByDate(date){
    return new Promise((resolve,reject)=>{
      this.promotions = [];
      this.promotionMin.date = moment(date).format('YYYY-MM-DD');
      this.metadata.promoSelected = JSON.parse(JSON.stringify(this.promoModel));
      this.session.postRequest("promo:findAllByDay",this.promotionMin).subscribe((data:any)=>{
        this.promotions = data.object.instanceList;
        // console.log("Promotions::::", this.promotions);
        resolve(true);
      },error=>{
        console.log(error);
        reject(error);
      })
    });
  }

  /**funcionalidad para seleccionar una promoción
  */
  promoSelected(){
    this.metadata.promoSelected = JSON.parse(JSON.stringify(this.promoModel));
    let promo = JSON.parse(JSON.stringify({}));
    for(let item of this.promotions){
      if(item.selected){
        promo = JSON.parse(JSON.stringify(item))
      }
    }
    //verificamos las reglas
    //checamos si comple con la condiciones de Cantidad
    switch(promo.type_quantity){
      case 'Personas':
        if(this.object.people >= promo.quantity){
          //seguimos para aplicar la promo
          this.metadata.promoSelected = JSON.parse(JSON.stringify(promo));
        }else{
          // la promo no aplica
          // console.log("promo no aplica");
          this._snackBar.open('La promoción no se puede aplicar; pues no cumple con las condiciones', 'Aceptar');
          this.deselectPromo();
        }
      break;
      case 'Monto':
        if(this.object.total >= promo.quantity){
          //seguimos para aplicar la promo
          this.metadata.promoSelected = JSON.parse(JSON.stringify(promo));
        }else{
          // la promo no aplica
          // console.log("promo no aplica");
          this._snackBar.open('La promoción no se puede aplicar; pues no cumple con las condiciones', 'Aceptar');
          this.deselectPromo();
        }
      break;
      // object.people
    }

    // si existe una promocion seleccionada
    if(this.metadata.promoSelected.id != ''){
      // console.log("promoSelected:",this.metadata.promoSelected);
      // console.log("bookingDetailList:",this.bookingDetailList);
      let indexAccess = 0 ;
      for(let k = 0; k < this.bookingDetailList.length; k++){
        if(this.bookingDetailList[k].type == 'Ticket'){
          indexAccess = k;
        }
      }
      // console.log("Acceso:",this.bookingDetailList[indexAccess]);

      switch(this.metadata.promoSelected.type_quantity){
        case 'Personas':
          //verificamos cuantos ticket aplica la promoción
          let promotionsToAply = Math.trunc(this.bookingDetailList[indexAccess].quantity / this.metadata.promoSelected.quantity);
          let accessTicketsAply = Math.trunc(this.bookingDetailList[indexAccess].quantity % this.metadata.promoSelected.quantity);
          // applicamos la promo
          if(promotionsToAply > 0){
            let promoBookingDetail = JSON.parse(JSON.stringify(this.bookingDetailList[indexAccess]));
            promoBookingDetail.concept = this.metadata.promoSelected.name;
            switch(this.metadata.promoSelected.type_price){
              case 'Acceso':
                promoBookingDetail.unit_price = this.metadata.promoSelected.price;
              break;
              case 'Descuento':
                promoBookingDetail.unit_price = (promoBookingDetail.unit_price*1) - (promoBookingDetail.unit_price*(this.metadata.promoSelected.price/100));
              break;
            }
            promoBookingDetail.quantity = promotionsToAply;
            this.bookingDetailList.push(promoBookingDetail);
          }

          //Modificamos la cantidad de tickes de precio normal
          if(accessTicketsAply > 0){
            this.bookingDetailList[indexAccess].quantity = accessTicketsAply;
          }else{
            //No se cobran tickets a precio normal, eliminamos el registros
            this.bookingDetailList.splice(indexAccess,1);
          }
        break;
        case 'Monto':
        let promoBookingDetail = JSON.parse(JSON.stringify(this.bookingDetailList[indexAccess]));

        switch(this.metadata.promoSelected.type_price){
          case 'Acceso':
            if(promoBookingDetail.total > this.metadata.promoSelected.price){
              promoBookingDetail.concept = this.metadata.promoSelected.name;
              promoBookingDetail.total = this.metadata.promoSelected.price;
              promoBookingDetail.subtotal = promoBookingDetail.total/1.16;
              promoBookingDetail.tax = promoBookingDetail.total - promoBookingDetail.subtotal;
              promoBookingDetail.unit_price = (promoBookingDetail.total / promoBookingDetail.quantity);
              this.bookingDetailList[indexAccess] = promoBookingDetail;
            }
          break;
          case 'Descuento':
            if(promoBookingDetail.total > this.metadata.promoSelected.price){
              promoBookingDetail.concept = this.metadata.promoSelected.name;
              promoBookingDetail.total = (promoBookingDetail.total * 1) - (promoBookingDetail.total * (this.metadata.promoSelected.price/100));
              promoBookingDetail.subtotal = promoBookingDetail.total/1.16;
              promoBookingDetail.tax = promoBookingDetail.total - promoBookingDetail.subtotal;
              promoBookingDetail.unit_price = (promoBookingDetail.total / promoBookingDetail.quantity);
              this.bookingDetailList[indexAccess] = promoBookingDetail;
            }
          break;
        }

        break;
      }
      this.calculateTotalByDetails();
    }
    else{
      // Eliminamos todas la promos de ticket
      this.bookingDetailList = this.bookingDetailList.filter((item)=>{
        return item.type != 'Ticket';
      })
      // no hay promo seleccionada se recalcula el calculo de cobros
      this.calculateTotal();
      this.finisBookingDetails();

    }
  }

  deselectPromo(){
    for(let i = 0; i < this.promotions.length; i++){
      if(this.promotions[i].selected){
        this.promotions[i].selected = false;
      }
    }
  }

  /**función para calcular final para actualizar precios
  */
  calculateTotalByDetails(){
    this.object.subtotal = 0;
    this.object.discount = 0;
    this.object.tax = 0;
    this.object.total = 0;

    //Verificamos si hay extra agregados
    for(let  i =0; i < this.bookingDetailList.length; i++){
      this.bookingDetailList[i].subtotal = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price)/1.16;
      this.bookingDetailList[i].tax = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price) - this.bookingDetailList[i].subtotal;
      this.bookingDetailList[i].total = this.bookingDetailList[i].subtotal + this.bookingDetailList[i].tax - this.bookingDetailList[i].discount;
      if(this.bookingDetailList[i].status){
        this.object.subtotal = this.object.subtotal + this.bookingDetailList[i].subtotal;
        this.object.tax = this.object.tax + this.bookingDetailList[i].tax;
      }
    }
    this.object.total =  this.object.subtotal + this.object.tax - this.object.discount;
  }


  /**funcionalidad para pbtener las ventas de un usuario
  */
  getSales(){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("metric:findAllSalesByUser",this.metricsMin).subscribe((data:any)=>{
      this.historySales = data.object.instanceList;
      this.paginatorHistory.total = data.object.total;
      this.loading.hide();
    },error=>{
      console.log("error:metric:findAllSalesByUser",error);
      this.loading.hide();
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChangehistory(event){
    this.metricsMin.max = event.pageSize;
    this.metricsMin.page = event.pageIndex + 1;
    this.getSales();
  }
}
