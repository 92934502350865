import { Injectable } from '@angular/core';
import { SessionService } from '../service/session/session.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TurnService {
  turnModel = {
    id:'',
    open:true,
    clossing_amount:0,
    openning_amount:0,
    open_date:'',
    close_date:null,
    valid:true,
    check:false
  }
  turnUserModel = {
    id:'',
    open:true,
    open_date:'',
    close_date:null,
    user:{
      id:''
    },
    turn:{
      id:''
    },
    clossing_amount:0,
    openning_amount:0,
    check:false,
    clossing_amount_user:0
  }
  turnBokkingModel = {
    id:'',
    booking:{
      id:''
    },
    turn:{
      id:''
    },
    turn_user:{
      id:''
    },
  }
  partialCutModel = {
    id:'',
    amount:0,
    cutoff_date:'',
    check:false,
    observation:'',
    turn:{
      id:''
    },
    turn_user:{
      id:''
    },
    expense:false
  }
  turn = JSON.parse(JSON.stringify(this.turnModel));
  turnUser = JSON.parse(JSON.stringify(this.turnUserModel));
  metadata = {
    turnIsOpen:false,
    turnUserIsOpen:false
  }


  constructor(private session:SessionService) { }

  /**funcionalida para determinar si al sistema tiene un turno ya iniciado
  */
  checkTurn(){
    return new Promise((resolve,reject)=>{
      if(!this.metadata.turnIsOpen){
        this.session.postRequest("turn:findAllByOpen",{open:true}).subscribe((data:any)=>{
          if(data.object.instanceList.length > 0){
            this.turn = JSON.parse(JSON.stringify(data.object.instanceList[0]));
            this.metadata.turnIsOpen = true;
          }else{
            this.metadata.turnIsOpen = false;
          }
          resolve(this.metadata.turnIsOpen);
        },error=>{
          this.metadata.turnIsOpen = false;
          reject(error);
        });
      }else{
        resolve(this.metadata.turnIsOpen);
      }

    })
  }

  getTurn(){
    return new Promise((resolve,reject)=>{
      if(this.turn.id == ''){
        this.session.postRequest("turn:findAllByOpen",{open:true}).subscribe((data:any)=>{
          if(data.object.instanceList.length > 0){
            this.turn = JSON.parse(JSON.stringify(data.object.instanceList[0]));
          }
          this.metadata.turnIsOpen = true;
          resolve(this.turn);
        },error=>{
          this.metadata.turnIsOpen = false;
          reject(error);
        });
      }else{
        this.metadata.turnIsOpen = true;
        resolve(this.turn);
      }
    });
  }

  /**funcionalidad para alamacenar el turno
  */
  setTurn(object){
    this.turn = JSON.parse(JSON.stringify(object));
    console.log("Turno almacenado",this.turn);
  }

  /**funcionalidad para alamacenar el turno del usuario
  */
  setTurnUser(object){
    this.turnUser = JSON.parse(JSON.stringify(object));
    console.log("Turno de usuario almacenado",this.turnUser);
  }


  /**funcionalidad para obtener el turno de un usuario
  */
  getTurnUser(){
    return new Promise((resolve,reject)=>{
      if(this.turn.id != ''){
        let user = this.session.getSession().user;
        this.session.postRequest("turnUser:findByUserAndOpen",{user:{id:user.id},open:true}).subscribe((data:any)=>{
          if(data.object){
            this.turnUser = JSON.parse(JSON.stringify(data.object));
            resolve(this.turnUser);
          }else{
            //creamos el turno
            // this.turnUser.open = true;
            // this.turnUser.open_date = moment().format('yyyy-MM-DD HH:mm:ss');
            // this.turnUser.turn.id = this.turn.id;
            // this.turnUser.user.id = user.id;
            // this.session.postRequest("turnUser:update",this.turnUser).subscribe((data:any)=>{
            //   this.turnUser = JSON.parse(JSON.stringify(data.object));
            //   resolve(this.turnUser);
            // },error=>{
            //   reject(error);
            // });
          }
        },error=>{
          reject(error);
        });
      }else{
        resolve(this.turnUser);
      }

    });
  }

  checkTurnUser(){
    return new Promise((resolve,reject)=>{
      if(!this.metadata.turnUserIsOpen){
        let user = this.session.getSession().user;
        this.session.postRequest("turnUser:findByUserAndOpen",{user:{id:user.id},open:true}).subscribe((data:any)=>{
          if(data.object){
            this.turnUser = JSON.parse(JSON.stringify(data.object));
            this.metadata.turnUserIsOpen = true;
          }else{
            this.metadata.turnUserIsOpen = false;
          }
          resolve(this.metadata.turnUserIsOpen);
        },error=>{
          this.metadata.turnUserIsOpen = false;
          reject(error);
        });
      }else{
        resolve(this.metadata.turnUserIsOpen);
      }
    })
  }

  /**funcionalidad para asociar una reservación al turno - usurio
  */
  addBookingToTurn(booking){
    if(this.turn.id != ''){
      let turnBooking = JSON.parse(JSON.stringify(this.turnBokkingModel));
      turnBooking.booking.id = booking.id;
      turnBooking.turn.id = this.turn.id;
      turnBooking.turn_user.id = this.turnUser.id;
      this.session.postRequest("turnSale:update",turnBooking).subscribe((data:any)=>{
      },error=>{
        console.log(error);
      });
    }

  }

  /** funcionalidad para cerrar el turno
  @params
  @closeAmount -> Monto de cierre del turno del usuario
  */
  closeTurn(closeAmount){
    return new Promise((resolve,reject)=>{
      if(this.turn.id != ''){
        // abrimos
        this.session.postRequest("turnUser:closeTurn",{id:this.turnUser.id,clossing_amount_user:closeAmount}).subscribe((data:any)=>{
          this.turn = JSON.parse(JSON.stringify(this.turnModel));
          this.turnUser = JSON.parse(JSON.stringify(this.turnUserModel));
          this.metadata.turnIsOpen = false;
          this.metadata.turnUserIsOpen = false;
          resolve(data.object);
        },error=>{
          console.log(error);
          reject(error)
        });
      }else{
        resolve(false)
      }

    })
  }

  /**funcionalidad para abrir el turno global del dia
  */
  openTurn(){
    return new Promise((resolve,reject)=>{
      this.turn.open_date = moment().format('yyyy-MM-DD HH:mm:ss');
      this.session.postRequest("turn:update",this.turn).subscribe(async(data:any)=>{
        this.metadata.turnIsOpen = true;
        this.setTurn(data.object);
        resolve(true);
      },error=>{
        console.log('Erro:turn:update',error);
        reject(error);
      })
    })

  }

  /** funcionalidad para crear un corte parcial
  @params
  @amount -> Monto de corte parcial
  */
  partialCut(object){
    return new Promise((resolve,reject)=>{
      if(this.turn.id != ''){
        // abrimos
        let partialCut = JSON.parse(JSON.stringify(this.partialCutModel));
        partialCut.cutoff_date = moment().format('yyyy-MM-DD HH:mm:ss');
        partialCut.amount = object.amount;
        partialCut.turn.id = this.turn.id;
        partialCut.turn_user.id = this.turnUser.id;
        partialCut.observation = object.observation;
        partialCut.expense = object.expense;

        this.session.postRequest("partialCut:update",partialCut).subscribe((data:any)=>{
          resolve(data.object);
        },error=>{
          console.log(error);
          reject(error)
        });
      }else{
        resolve(false)
      }

    })
  }


}
