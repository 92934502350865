import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { AdminMetricsTurnsDetailsComponent } from '../admin-metrics-turns-details/admin-metrics-turns-details.component';

@Component({
  selector: 'app-admin-metrics-turns',
  templateUrl: './admin-metrics-turns.component.html',
  styleUrls: ['./admin-metrics-turns.component.css']
})
export class AdminMetricsTurnsComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  turnMin = {
    open:true,
    filter:'',
    max: 100,
    page: 1,
  }
  turnList = [];
  paginator = {
    total:0,
    pageSizeOptions:[50, 100, 200, 300],
  };
  pageEvent: PageEvent;
  constructor(
    private session: SessionService,
    public loadding:LoadingService,
    private router: Router, private activatedRoute: ActivatedRoute,
    public dialog:MatDialog
  ) { }

  ngOnInit(): void {
  }

  getTurnByOpen(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest("turn:list",this.turnMin).subscribe((data:any)=>{
      this.turnList = JSON.parse(JSON.stringify(data.object.instanceList));
      for(let i=0; i < this.turnList.length; i++){
        this.getTotals(this.turnList[i]);
      }
      this.paginator.total = data.object.total;
      this.loadding.hide();
    },error=>{
      this.loadding.hide();
      console.log(error);
    });
  }

  /**obtenemos los totales del turno
  */
  getTotals(object){
    this.session.postRequest("turnSale:getTotalByTurn",{turn:{id:object.id}}).subscribe((data:any)=>{
      object.totals = data.object;
    },error=>{
      console.log(error);
    });
  }

  changeTab(ev){
    this.turnMin.page = 1;
    this.turnMin.open = ev == 1 ? true : false;
    this.getTurnByOpen();
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.turnMin.max = event.pageSize;
    this.turnMin.page = event.pageIndex + 1;
    this.getTurnByOpen();
  }

  /**funcionalidad para abrir el detalle del turno
  */
  openTurnDetails(object){
    const dialogRef = this.dialog.open(AdminMetricsTurnsDetailsComponent, {
      width: '80%',
      height:'85%',
      data:object
    });
    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getTurnByOpen();
        }else{
          // El modal se cerro sin objeto
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
