<div class="row justify-content-end mt-0">
    <div class="col-1">
      <i class="bi bi-x-circle btnClose" (click)="close(false)"></i>
    </div>
    <div class="col-12">
      <h6>Crea una nueva membresia</h6>
      <p><small>Rellena el formulario de acuerdo al nuevo concepto a crear.</small></p>
    </div>
    <div class="col-4">
        <img src="assets/imagenes/000001_IMAGEN_NO_DISPONIBLE.jpg" alt="" style="width: 60%; margin-left: 20%;">
    </div>
    <div class="col-8">
      <div class="row m-0">
        <div class="col-12">
          <div class="form-group">
            <label for="name" class="labelInputForm">Nombre</label>
            <input type="text" class="form-control" id="name" [(ngModel)]="object.name">
          </div>
        </div>
        <div class="col-8">
          <div class="form-group">
            <label for="name" class="labelInputForm">Dirección</label>
            <input type="text" class="form-control" id="description" [(ngModel)]="object.address">
          </div>
        </div>
        <div class="col-4">
            <div class="form-group">
              <label for="inputState" class="labelInputForm">Tipo de membresias</label>
              <select id="inputState" class="form-control" name="type" [(ngModel)]="object.member">
                <option *ngFor="let object of memberList" value="{{object}}">{{object}}</option>
              </select>
            </div>
          </div>

      </div>
      <div class="row justify-content-end m-0">
        <div class="col-12 col-md-6">
          <button type="button" class="btn btn-primary btn-save" (click)="send();">Guardar</button>
        </div>
      </div>
    </div>
  </div>
  