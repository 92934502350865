import { Component, OnInit } from '@angular/core';
import { AdminMenuService } from './admin-menu.service';
import { Router } from '@angular/router';
// import { TurnService } from '../turn.service';

export interface oneMenuOption {
  route: string,
  title: string,
  icon: string,
  options: Array<{
    route: string,
    title: string,
    icon: string,
  }>,
  selected: boolean
}

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.css']
})
export class AdminMenuComponent implements OnInit {

  metadata = {
    user: {
      main: {
        uuid: '',
        username: ''
      }
    },
  }
  menuOptions = [];

  constructor( private menustore: AdminMenuService, private router: Router) {
    this.menuOptions = this.menustore.findMyMenu();
   }
   ngOnInit() {
     // console.log(this.menuOptions);
     let menuSelected =  sessionStorage.getItem('router');
     for(let i=0; i < this.menuOptions.length; i++){
       this.menuOptions[i].selected = false;
       if(menuSelected ==  this.menuOptions[i].route){
         this.menuOptions[i].selected = true;
       }
     }
   }

  async  logout() {
     // this.session.logout();
     try{
       this.menustore.clean();
       this.router.navigate(['login']);
     }catch(e){
       console.log(e);
       this.router.navigate(['login']);
     }
   }

   menuSelection(object) {
     for (let i = 0; i < this.menuOptions.length; i++) {
       this.menuOptions[i].selected = false;
     }
     if (object.options.length > 0) {
     } else {
       this.menustore.clean();
     }
     object.selected = true;
     this.menustore.selected(object.route);
     this.router.navigate([object.route]).catch(error => {
       console.log("URL no definida:", object.route);
     });
   }

}
