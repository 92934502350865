<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer class="mainDrawer" mode="side">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-admin-toolbar (menuToogle)="toogleMenu($event)"></app-admin-toolbar>

    <!-- aqui va el contenido -->
    <div class="row justify-content-center m-0" style="margin-top: 30px!important;">

      <div class="col-11 col-md-10">
        <div class="row justify-content-between mb-2 mt-2">
          <div class="col-md-4 col-6" style="padding: 5px;"><h5 class="blue">Turnos</h5></div>
          <div class="col-4 col-md-4 alingEnd">
            <!-- <button type="button" class="btn btn-link naranja" (click)="update('new');"><i class="bi bi-plus-circle"></i> Nuevo Concepto</button> -->
          </div>
        </div>
        <div class="row">
          <ul ngbNav #nav="ngbNav" class="nav-tabs col-12" (activeIdChange)="changeTab($event);">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Turnos abiertos</a>
              <ng-template ngbNavContent>
                <div class="row justify-content-center">
                  <div class="col-4" *ngFor="let object of turnList;">
                    <div class="cardResumen">
                      <div class="bulletLigth">
                        <i class="bi bi-circle-fill"></i>
                      </div>
                      <h4 class="naranja">Detalles del turno</h4>
                      <ul style="list-style-type:none;">
                        <li><strong>Inicio del turno:&nbsp;</strong>{{object.open_date | date: 'dd-MM-yyyy HH:mm'}}</li>
                        <li *ngIf="object.close_date"><strong>Fin del turno:&nbsp;</strong>{{object.close_date | date: 'dd-MM-yyyy HH:mm'}}</li>
                      </ul>
                      <hr>
                      <p class="mb-3">Saldos del turno</p>
                      <div>
                        <div class="resumenTotal">
                          <p><strong>Apertura</strong></p>
                          <p>{{object.openning_amount | currency:'CAD':'symbol-narrow'}}</p>
                        </div>
                        <hr>
                        <div class="resumenTotal" *ngIf="object.totals">
                          <p><strong>Efectivo</strong></p>
                          <p>{{object.totals.efectivo | currency:'CAD':'symbol-narrow'}}</p>
                        </div>
                        <div class="resumenTotal" *ngIf="object.totals">
                          <p><strong>Tarjeta</strong></p>
                          <p>{{object.totals.tarjeta | currency:'CAD':'symbol-narrow'}}</p>
                        </div>
                        <div class="resumenTotal" *ngIf="object.totals">
                          <p><strong>Total real</strong></p>
                          <p><strong>{{(object.totals.total) | currency:'CAD':'symbol-narrow'}}</strong></p>
                        </div>
                        <hr>
                        <div class="resumenTotal">
                          <p><strong>Cierre</strong></p>
                          <p>{{object.clossing_amount | currency:'CAD':'symbol-narrow'}}</p>
                        </div>
                        <div class="sectionActions mt-2">
                          <button type="button" class="btn btn-outline-warning btn-sm" (click)="openTurnDetails(object);"><i class="bi bi-card-list"></i>&nbsp;Ver detalle</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Turno cerrado</a>
              <ng-template ngbNavContent>
                <div class="row justify-content-center">
                  <div class="col-4" *ngFor="let object of turnList;">
                    <div class="cardResumen">
                      <div class="closeBullet">
                        <i class="bi bi-circle-fill"></i>
                      </div>
                      <h4 class="naranja">Detalles del turno</h4>
                      <ul style="list-style-type:none;">
                        <li><strong>Inicio del turno:&nbsp;</strong>{{object.open_date | date: 'dd-MM-yyyy HH:mm'}}</li>
                        <li *ngIf="object.close_date"><strong>Fin del turno:&nbsp;</strong>{{object.close_date | date: 'dd-MM-yyyy HH:mm'}}</li>
                      </ul>
                      <hr>
                      <p class="mb-3">Saldos del turno</p>
                      <div>
                        <div class="resumenTotal">
                          <p><strong>Apertura</strong></p>
                          <p>{{object.openning_amount | currency:'CAD':'symbol-narrow'}}</p>
                        </div>
                        <hr>
                        <div class="resumenTotal" *ngIf="object.totals">
                          <p><strong>Efectivo</strong></p>
                          <p>{{object.totals.efectivo | currency:'CAD':'symbol-narrow'}}</p>
                        </div>
                        <div class="resumenTotal" *ngIf="object.totals">
                          <p><strong>Tarjeta</strong></p>
                          <p>{{object.totals.tarjeta | currency:'CAD':'symbol-narrow'}}</p>
                        </div>
                        <div class="resumenTotal" *ngIf="object.totals">
                          <p><strong>Total real</strong></p>
                          <p><strong>{{(object.totals.total) | currency:'CAD':'symbol-narrow'}}</strong></p>
                        </div>
                        <hr>
                        <div class="resumenTotal">
                          <p><strong>Cierre</strong></p>
                          <p>{{object.clossing_amount | currency:'CAD':'symbol-narrow'}}</p>
                        </div>
                        <div class="sectionActions">
                          <button type="button" class="btn btn-outline-warning btn-sm" (click)="openTurnDetails(object);"><i class="bi bi-card-list"></i>&nbsp;Ver detalle</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav" class="mt-2" style="width:100%;"></div>
        </div>
        <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
          <div class="col-6 ">
            <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
    <app-client-footer></app-client-footer>
  </mat-drawer-content>
</mat-drawer-container>
