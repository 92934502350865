<div class="row justify-content-center m-0" style="    padding-top: 30px!important;background: #f1f1f1;">
  <div class="col-12 col-md-5">
  </div>
  <div class="col-12 col-md-5" style="text-align: end;">
  </div>
  <div class="col-11 col-md-10">
    <div class="row">
      <h2>Turno finalizado</h2>
    </div>
    <div class="row justify-content-center mt-3">

      <!-- <div class="col-4">
        <div class="cardResumen">
          <h4 class="naranja">Cortes parciales realizados</h4>
          <hr>
          <table class="compra">
            <tr>
              <th>#</th>
              <th>Total</th>
            </tr>
            <tr *ngFor="let object of partialCutList; let i = index;">
              <td style="text-align: center;">{{i+1}}</td>
              <td style="text-align: center;"> {{object.amount | currency:'CAD':'symbol-narrow'}}</td>
            </tr>
          </table>
        </div>
      </div> -->
      <div class="col-6">
        <div class="cardResumen">
          <h4 class="naranja">Corte del dia</h4>
          <ul style="list-style-type:none;">
            <li><strong>Realizado:&nbsp;</strong>{{object.close_date | date: 'dd-MM-yyyy HH:mm'}}</li>
          </ul>
          <ul style="list-style-type:none;" *ngIf="object.totals">
            <li><strong>Mostrador:&nbsp;</strong>{{object.name}}</li>
            <li class="userResemenContent"><strong><span>Ventas totales:&nbsp;</span>{{object.totals.total | currency:'CAD':'symbol-narrow'}}</strong></li>
          </ul>
          <ul style="list-style-type:none; text-align: center; text-transform: uppercase;" *ngIf="object.totals">
            <li class="userResemenContent">  {{metadata.totalSales}} ventas en el dia</li>
          </ul>
          <hr>
          <p class="mb-3" style="text-align: center; text-transform: uppercase;">Ventas</p>
          <div *ngIf="object.totals">
            <div class="resumenTotal">
              <p>Efectivo</p>
              <p>{{object.totals.efectivo | currency:'CAD':'symbol-narrow'}}</p>
            </div>
            <div class="resumenTotal">
              <p>Tarjeta</p>
              <p>{{object.totals.tarjeta | currency:'CAD':'symbol-narrow'}}</p>
            </div>
            <hr style="border-style: dashed;">
            <div class="resumenTotal">
              <p><strong>Total ventas</strong></p>
              <p><strong>{{object.totals.total | currency:'CAD':'symbol-narrow'}}</strong></p>
            </div>
          </div>

          <hr>
          <p class="mb-3" style="text-align: center; text-transform: uppercase;">Cortes</p>
          <div>
            <div class="resumenTotal" *ngFor="let object of partialCutList; let i = index;">
              <p>{{i + 1}} {{ object.observation}}</p>
              <p>{{object.amount | currency:'CAD':'symbol-narrow'}}</p>
            </div>
            <hr style="border-style: dashed;">
            <div class="resumenTotal">
              <p>Corte al cierre</p>
              <p>{{object.clossing_amount_user | currency:'CAD':'symbol-narrow'}}</p>
            </div>
            <hr style="border-style: dashed;">

            <div class="resumenTotal">
              <p><strong>Total cortes</strong></p>
              <p><strong>{{(metadata.totalCortes + object.clossing_amount_user*1) | currency:'CAD':'symbol-narrow'}}</strong></p>
            </div>
          </div>

          <!-- <p class="mb-3">Saldos del turno</p>
          <div>
            <div class="resumenTotal">
              <p><strong>Apertura</strong></p>
              <p>{{object.openning_amount | currency:'CAD':'symbol-narrow'}}</p>
            </div>
            <div class="resumenTotal">
              <p><strong>Cierre</strong></p>
              <p>{{object.clossing_amount_user | currency:'CAD':'symbol-narrow'}}</p>
            </div>
            <hr>
            <div class="resumenTotal">
              <p><strong>Total en Caja</strong></p>
              <p><strong>{{(object.clossing_amount_user*1 + object.openning_amount*1) | currency:'CAD':'symbol-narrow'}}</strong></p>
            </div>
          </div> -->
        </div>
        <button type="button" class="btn  btn-sm mb-1 mt-3 btn-warning" style="width:100%;" routerLink="/login">Regresar a login</button>

      </div>
      <div class="col-10 mt-2">
        <div class="cardResumen">
          <h4 class="naranja" style="text-align: center;">Ventas realizadas</h4>
          <table class="compra">
            <tr>
              <th>Folio</th>
              <th>Fecha / Horario</th>
              <th>Cliente</th>
              <th>Pago</th>
              <th>Total</th>
            </tr>
            <tr *ngFor="let object of bookingsList">
              <td style="text-align: center;">{{object.folio}}</td>
              <td style="text-align: center;">{{object.dateBooking | date:'dd-MM-yyyy'}} <br> | {{object.scheduleBooking}}</td>
              <td style="text-align: center;">{{object.fullname}}</td>
              <td style="text-align: center;">{{object.typePayment}}</td>
              <td style="text-align: center;"> {{object.total | currency:'CAD':'symbol-narrow'}}</td>
            </tr>
          </table>
          <div class="row justify-content-end" style="width:100%;margin-left:0px; margin-right:0px;">
            <div class="col-12">
              <mat-paginator [length]=paginator.total [pageSize]="100" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
