import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { AdminSubscriptionUpdateComponent } from './admin-subscription-update/admin-subscription-update.component';

@Component({
  selector: 'app-admin-subscription',
  templateUrl: './admin-subscription.component.html',
  styleUrls: ['./admin-subscription.component.css']
})
export class AdminSubscriptionComponent implements OnInit {

  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  memberMin = {
    max: 10,
    page: 1,
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  constructor(private session: SessionService,
  public loadding:LoadingService,
  public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

    /** funcionalidad para crear una categoria
  */
    update(uuid){
      const dialogRef = this.dialog.open(AdminSubscriptionUpdateComponent, {
        width: '56rem',
        height:'90%',
        data: uuid,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            // El modal se cerro con objeto
            // this.loadShoes();
          }else{
            // El modal se cerro sin objeto
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

    // funcion para obtener los datos del paginado.
    onPaginateChange(event){
      this.memberMin.max = event.pageSize;
      this.memberMin.page = event.pageIndex + 1;
      // this.loadShoes();
    }
  
    /* funcionalidad para cambiar el menú
    */
    toogleMenu(ev){
      this.drawerCtrl.opened = ev;
    }


}
