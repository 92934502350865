import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import * as printJS from 'print-js';

@Component({
  selector: 'app-admin-booking-details',
  templateUrl: './admin-booking-details.component.html',
  styleUrls: ['./admin-booking-details.component.css']
})
export class AdminBookingDetailsComponent implements OnInit {

  shoesList = [];
  extras = [];
  constructor(public dialogRef: MatDialogRef<AdminBookingDetailsComponent>,
    private session: SessionService,
    public loadding:LoadingService,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
    // console.log(this.data);
    this.shoesList = this.data.bookingDetails.filter(object => object.type == "Shoes");
    this.extras = this.data.bookingDetails.filter(object => object.type == "Extra");
    // console.log(this.shoesList);
    // console.log(this.extras);
  }

  closeTru(){
    this.close(true);
  }

  print(){
    this.loadding.show(true,'Espere un momento');
    this.session.postRequest("booking:downloadTicket",{id:this.data.id}).subscribe((data:any)=>{
      printJS({printable:data.object, type: 'pdf', base64: true});
      this.loadding.hide();
    },error=>{
      this.loadding.hide();

    })
  }
  downloadTicket(){
    this.loadding.show(true,'Espere un momento');
    this.session.postRequest("booking:downloadTicket",{id:this.data.id}).subscribe((data:any)=>{
      let elem = document.createElement('a');
      elem.href = 'data:application/pdf;base64,'+data.object;
      elem.setAttribute('download', this.data.folio+'.pdf');
      document.body.appendChild(elem);
      elem.click();
      this.loadding.hide();
    },error=>{
      this.loadding.hide();
    })
  }

  downloadTicketTEMP(){
    this.loadding.show(true,'Espere un momento');
    this.session.postRequest("booking:downloadTicketTemp",{id:this.data.id}).subscribe((data:any)=>{
      printJS({printable:data.object, type: 'pdf', base64: true});
      this.loadding.hide();
    },error=>{
      this.loadding.hide();

    })
  }

  printTicketInstructor(){
    this.loadding.show(true,'Espere un momento');
    this.session.postRequest("booking:downloadTicketInstructor",{id:this.data.id}).subscribe((data:any)=>{
      printJS({printable:data.object, type: 'pdf', base64: true});
      this.loadding.hide();
    },error=>{
      this.loadding.hide();
    })
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'price_list:001';
    }
    this.dialogRef.close(object);
  }

}
