import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { SessionService } from '../../service/session/session.service';
export interface menu {
    route: string
  }
@Injectable({
  providedIn: 'root'
})
export class AdminMenuService {
  object = {
    route:''
  };

  metadata = {
    typeMenu:''
  }

  menuChange: Subject<menu> = new Subject<menu>();
  constructor(private session: SessionService) {
    this.menuChange.next(this.object);
   }

   selected(value){
     this.object.route = value;
     this.menuChange.next(this.object);
     sessionStorage.setItem('router', value);
   }
   getSubMenuSelected(){
     return Observable.create( observer => {
       observer.next(this.object.route)
     });
   }
   clean(){
     this.object.route = '';
     this.menuChange.next(this.object);
   }

   /** función para setear el tipo de menu a mostrar
   */
   setMenuType(object:string):void {
     sessionStorage.setItem('menuType', object);
     this.metadata.typeMenu = object;
   }

   /** funcion para obtener el tipo de menu a mostrar
   */
   getMenuType():string{
     this.metadata.typeMenu = sessionStorage.getItem('menuType');
     return this.metadata.typeMenu;
   }

   /** funcion para determinar el tipo de menu a rederizar
   */
   findMyMenu(){
     let typeMenu = this.getMenuType();
     let array = [];
     // obtenemos el rol con el que inicia sesión el usuario.
     let rol  = this.session.getRoles()[0];
     switch(rol){
       case 'ADMINISTRATOR':
       array = [
         { route: '/admin/metrics/turns', title:'Turnos', icon:'bi bi-laptop',  options:[], selected:false },
         { route: '/admin/dashboard', title:'Reservaciones', icon:'bi bi-calendar-check',  options:[], selected:true },
         { route: '/admin/subscription/manager', title:'Gestion de Membrecias', icon:'bi bi-ticket-perforated',  options:[], selected:false },
         { route: '/admin/metrics/resports', title:'Reportes', icon:'bi bi-file-bar-graph',  options:[], selected:true },
         { route: '/admin/prices', title:'Lista de precios', icon:'bi bi-card-list',  options:[], selected:false },
         { route: '/admin/subscription/config', title:'Membresias', icon:'bi bi-card-heading',  options:[], selected:false },
         { route: '/admin/promotions', title:'Promociones', icon:'bi bi-stars',  options:[], selected:false },
         { route: '/admin/shoes', title:'Patines', icon:'bi bi-sort-numeric-down-alt',  options:[], selected:false },
       ];
       break;
       case 'SUPERVISOR':
       array = [
         { route: '/admin/metrics/turns', title:'Turnos', icon:'bi bi-laptop',  options:[], selected:false },
         { route: '/admin/dashboard', title:'Reservaciones', icon:'bi bi-calendar-check',  options:[], selected:true },
         { route: '/admin/subscription/manager', title:'Gestion de Membrecias', icon:'bi bi-ticket-perforated',  options:[], selected:false },
         { route: '/admin/prices', title:'Lista de precios', icon:'bi bi-card-list',  options:[], selected:false },
         { route: '/admin/subscription/config', title:'Membresias', icon:'bi bi-card-heading',  options:[], selected:false },
         { route: '/admin/promotions', title:'Promociones', icon:'bi bi-stars',  options:[], selected:false },
         { route: '/admin/shoes', title:'Patines', icon:'bi bi-sort-numeric-down-alt',  options:[], selected:false }
       ];
       break;
       case 'COUNTER':
       array = [
         { route: '/admin/dashboard', title:'Reservaciones', icon:'bi bi-calendar-check',  options:[], selected:true },
       ];
       break;
       default:
       array = [];
       break;
     }
     return array;
   }
}
