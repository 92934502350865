import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { AdminBookingExtrasSuccessComponent } from '../admin-booking-extras-success/admin-booking-extras-success.component';
import * as printJS from 'print-js';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-booking-add',
  templateUrl: './admin-booking-add.component.html',
  styleUrls: ['./admin-booking-add.component.css']
})
export class AdminBookingAddComponent implements OnInit {
  priceMin = {
    status:true,
    max: 10,
    page: 1,
  }
  bookingDetailModel = {
    id:'',
    concept:'',
    unit_price:0.00,
    quantity:0,
    discount:0.00,
    subtotal:0.00,
    tax: 0.00,
    total: 0.00,
    type:'',
    booking:{
      id:''
    },
    size:'',
    status:false,
  }
  extras = [];
  priceList = [];
  otherChangerList = [];
  bookingDetailList = [];
  constructor(public dialogRef: MatDialogRef<AdminBookingAddComponent>,
    private session: SessionService,
    public loadding:LoadingService,
    public dialog:MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
    // console.log(this.data);
    this.extras = this.data.bookingDetails.filter(object => object.type == 'Extra');
    this.loadPrices();
  }

  loadPrices(){
    return new Promise((resolve,reject)=>{
      this.session.postRequest("price_list:findAllByStatus",this.priceMin).subscribe((data:any)=>{
        this.priceList = data.object.instanceList;
        this.otherChangerList = this.priceList.filter(object => object.access == false);
        this.preloadBookingDetails();
        resolve(true);
      },error=>{
        console.log("error:price_list:findAllByStatus",error);
        reject(error);
      })
    })
  }

  /**funcionalidad para precargar datos el bookigndetails
  */
  preloadBookingDetails(){
    this.bookingDetailList = [];
    //calculamos disponibilidad de monitos, instructores
    for(let i = 0; i < this.otherChangerList.length; i++){
      if(this.otherChangerList[i].description == 'Instructor personalizado'){
        this.otherChangerList[i].avalability = [1,2,3,4];
      }else{
        this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8];
      }
      if(this.otherChangerList[i].description == 'Calcetines'){
        this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30];

      }
      if(this.otherChangerList[i].description == 'Lockers'){
        this.otherChangerList[i].avalability = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,25,27,28,29,30,31,32];
      }
    }
    for(let object of this.otherChangerList){
      let aux = JSON.parse(JSON.stringify(this.bookingDetailModel));
      aux.concept = object.description;
      aux.unit_price = object.unit_price;
      aux.quantity = object.people;
      aux.type = 'Extra';
      aux.avalability = object.avalability;
      this.bookingDetailList.push(aux);
    }
  }

  sendToPay (){
    //se agrega funcionalidad para enviar a cobrar los extras
    let bookingsListAux = [];
    for(let item of this.bookingDetailList){
      if(item.status){
        bookingsListAux.push(item);
        item.booking.id = this.data.id;
      }
    }
    this.loadding.show(true,"Espere un momento")
    this.session.postRequest("bookingDetails:sendToPay",bookingsListAux).subscribe((data:any)=>{
      if(data.object.instanceList.length > 0){
        for(let i = 0; i < bookingsListAux.length; i++){
          for(let j = 0; j < data.object.instanceList.length; j++){
            if(bookingsListAux[i].concept == data.object.instanceList[j].concept){
              bookingsListAux[i].id = data.object.instanceList[j].id;
            }
          }
        }
        // se agrega funcionalidad para imprimir
        this.printExtras(bookingsListAux);
      }
    },error=>{
      this.loadding.hide();
      console.log(error);
    })
  }

  /**funcionalidad para imprimir los extras
  */
  printExtras(extras){
    this.loadding.show(true,"Espere un momento")
    this.session.postRequest("booking:downloadTicketExtras",extras).subscribe((data:any)=>{
      printJS({printable:data.object, type: 'pdf', base64: true});
      this.openModalSuccess(extras);
      this.loadding.hide();
    },error=>{
      this.loadding.hide();
    })
  }

  openModalSuccess(extras){
    const dialogRef = this.dialog.open(AdminBookingExtrasSuccessComponent, {
      width: '36rem',
      disableClose:true,
      data:extras
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.close(true);
        }else{
          // El modal se cerro sin objeto
          this.close(true);
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.close(true);
      }
    });
  }

  calculateTotal(){
    for(let  i =0; i < this.bookingDetailList.length; i++){
      this.bookingDetailList[i].subtotal = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price)/1.16;
      this.bookingDetailList[i].tax = (this.bookingDetailList[i].quantity * this.bookingDetailList[i].unit_price) - this.bookingDetailList[i].subtotal;
      this.bookingDetailList[i].total = this.bookingDetailList[i].subtotal + this.bookingDetailList[i].tax - this.bookingDetailList[i].discount;
    }
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'price_list:001';
    }
    this.dialogRef.close(object);
  }


}
