import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-point-sale-payment',
  templateUrl: './point-sale-payment.component.html',
  styleUrls: ['./point-sale-payment.component.css']
})
export class PointSalePaymentComponent implements OnInit {
  fastPayment = [
    {
      name:'20 MXN',
      value:20
    },{
      name:'50 MXN',
      value:50
    },{
      name:'100 MXN',
      value:100
    },{
      name:'200 MXN',
      value:200
    },{
      name:'500 MXN',
      value:500
    }
  ];
  calculatorDigits = [
    {
      name:'7',
      value:7,
      type:'number'
    },{
      name:'8',
      value:8,
      type:'number'
    },{
      name:'9',
      value:9,
      type:'number'
    },{
      name:'4',
      value:4,
      type:'number'
    },{
      name:'5',
      value:5,
      type:'number'
    },{
      name:'6',
      value:6,
      type:'number'
    },{
      name:'1',
      value:1,
      type:'number'
    },{
      name:'2',
      value:2,
      type:'number'
    },{
      name:'3',
      value:3,
      type:'number'
    },{
      name:'0',
      value:0,
      type:'number'
    },{
      name:'.',
      value:'.',
      type:'number'
    },{
      name:'CE',
      value:'CE',
      type:'clean'
    },{
      name:'COMPLETO',
      value:'COMPLETO',
      type:'completed'
    }
  ];
  paymentMethods = [
    {
      name:'Efectivo',
      value:'Efectivo',
      icon:'bi bi-cash'
    },{
      name:'Tarjeta',
      value:'Tarjeta',
      icon:'bi bi-credit-card'
    },{
      name:'Cortesía',
      value:'Cortesia',
      icon:'bi bi-person-badge'
    }
  ];
  metadata = {
    totalPayment:0,
    totalChange:0,
    saldo:0,
    paymentMethod:'Efectivo',
    firtsPickCalculator:false,
    object:{
      id:'',
      total:0,
      total_payment:0,
      total_change:0,
      type_payment:''
    }
  };
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<PointSalePaymentComponent>) {
    this.metadata.object = data.object;
    this.metadata.paymentMethod = data.object.type_payment;
  }


  ngOnInit(): void {
    this.metadata.totalPayment =this.metadata.object.total;
    this.calculateChangePayment();
  }

  /**fucnión para cargar un pago rapido
  */
  loadFastPayment(object){
    this.metadata.totalPayment = object.value;
    this.metadata.firtsPickCalculator = false;
    this.calculateChangePayment();
  }

  /**función para calcular el cambio
  */
  calculateChangePayment(){
    this.metadata.totalChange = this.metadata.totalPayment - this.metadata.object.total;
    if(this.metadata.totalChange < 0)
      this.metadata.totalChange = 0;
  }

  /**función para concatenar el valor de totalPayment
  */
  useCalculator(object,status){
    if(!this.metadata.firtsPickCalculator){
      this.metadata.totalPayment = 0;
      this.metadata.firtsPickCalculator = true;
    }
    if(status == 'number'){
      let actualValue:any = this.metadata.totalPayment;
      actualValue = `${actualValue}${object.value}`;
      this.metadata.totalPayment = actualValue;
    }else{
      if(status == 'completed'){
        this.metadata.totalPayment = this.metadata.object.total;
      }else{
        //clean totalChange
        this.metadata.totalPayment = 0;
      }
    }
    this.calculateChangePayment();
  }


  changeUpdate(){
    this.metadata.object.total_change = this.metadata.object.total_payment - this.metadata.object.total;
  }

  /**funcion para seleccionar el metodo de pago
  */
  selectedPaymentMethod(object){
    this.metadata.firtsPickCalculator = false;
    this.metadata.paymentMethod = object;
    if(this.metadata.paymentMethod != 'Efectivo'){
      this.metadata.totalPayment = this.metadata.object.total;
      this.calculateChangePayment();
    }
  }

  close(status){
    this.metadata.firtsPickCalculator = false;
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      let totalChange:any = this.metadata.totalChange;
      let totalPayment:any = this.metadata.totalPayment;
      this.metadata.object.total_change = parseFloat(totalChange);
      this.metadata.object.total_payment = parseFloat(totalPayment);
      this.metadata.object.type_payment = this.metadata.paymentMethod;
      object.object = this.metadata;
    }else{
      object.transaction = 'bad';
      object.code = 'characteristic:001';
    }
    this.dialogRef.close(object);
  }

}
