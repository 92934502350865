import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-price-list-update',
  templateUrl: './admin-price-list-update.component.html',
  styleUrls: ['./admin-price-list-update.component.css']
})
export class AdminPriceListUpdateComponent implements OnInit {
  complexForm: FormGroup;
  priceListModel = {
    id:'',
    day:null,
    time:'1',
    description:'',
    unit_price:'',
    people:1,
    shoes:false,
    allDays:false,
    status:true,
    access:false
  }

  object = JSON.parse(JSON.stringify(this.priceListModel));
  constructor(public fb: FormBuilder,
    private session: SessionService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<AdminPriceListUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.complexForm = fb.group({
      'description': [null, Validators.required],
      'people': [null, Validators.required],
    })
  }

  ngOnInit(): void {
    if(this.data != 'new') {
      this.loadingService.show(true, "Espere un momento...");
       // cargamos los datos de la BD
       this.session.postRequest('price_list:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: price_list:get',error)
       })
    }
  }


  /**
   * funcion para guardar un concepto
   */
  send(){
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("price_list:update",this.object).subscribe(
      (data:any) => {
        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: price_list:update",error);
        this.loadingService.hide();
      }
    );
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'price_list:001';
    }
    this.dialogRef.close(object);
  }

}
