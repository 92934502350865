<div class="row justify-content-end mt-0">
  <div class="col-1">
    <i class="bi bi-x-circle btnClose" (click)="closeTru()"></i>
  </div>
  <div class="col-12">
    <h2 class="naranja">Información</h2>
    <h2 class="blue" *ngIf="!data.point_sale">Reservación creada exitosamente.</h2>
    <h2 class="blue" *ngIf="data.point_sale">Gracias.</h2>
  </div>
  <div class="col-12">
    <div class="row justify-content-end m-0">
      <div class="col-12 col-md-4">
        <button type="button" class="btn btn-primary btn-close" (click)="closeTru();">CERRAR</button>
      </div>
      <div class="col-12 col-md-4">
        <button type="button" class="btn btn-primary btn-save" (click)="printTicketInstructor();">IMP. INSTRUCTOR</button>
      </div>
      <div class="col-12 col-md-4">
        <button type="button" class="btn btn-primary btn-save" (click)="print();">IMPRIMIR</button>
      </div>
    </div>
  </div>
</div>
