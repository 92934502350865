
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code:'auth:login',
    endpoint:"auth/login"
  },{
    code: 'auth:signup',
    endpoint: 'auth/signup'
  },{
    code: 'auth:loginWithFaceboook',
    endpoint: 'auth/loginWithFaceboook'
  },{
    code: "auth:user",
    endpoint: "auth/user"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "booking:update",
    endpoint: "booking/update"
  },{
    code: "booking:findAllByPhone",
    endpoint: "booking/findAllByPhone"
  },{
    code: "booking:list",
    endpoint: "booking/list"
  },{
    code: "booking:findByMexpago",
    endpoint: "booking/findByMexpago"
  },{
    code: "booking:checkAndValidate",
    endpoint: "booking/checkAndValidate"
  },{
    code: "booking:findAllByStatus",
    endpoint: "booking/findAllByStatus"
  },{
    code: "booking:findByNameOrFolio",
    endpoint: "booking/findByNameOrFolio"
  },{
    code: "price_list:list",
    endpoint: "price_list/list"
  },{
    code: "price_list:update",
    endpoint: "price_list/update"
  },{
    code: "price_list:delete",
    endpoint: "price_list/delete"
  },{
    code: "price_list:get",
    endpoint: "price_list/get"
  },{
    code: "shoe_list:list",
    endpoint: "shoe/list"
  },{
    code: "shoe_get:get",
    endpoint: "shoe/get"
  },{
    code: "shoe_update:update",
    endpoint: "shoe/update"
  },{
    code: "price_list:findAllByStatus",
    endpoint: "price_list/findAllByStatus"
  },{
    code: "shoes:findAllByStatus",
    endpoint: "shoe/findAllByStatus"
  },{
    code: "booking:findShoesSizeBySchedule",
    endpoint: "booking/findShoesSizeBySchedule"
  },{
    code: "booking:findSpaceByScheduleAndDate",
    endpoint: "booking/findSpaceByScheduleAndDate"
  },{
    code: "booking:findSpacesBySchedule",
    endpoint: "booking/findSpacesBySchedule"
  },{
    code: "booking:findStockByScheduleAndConcept",
    endpoint: "booking/findStockByScheduleAndConcept"
  },{
    code: "booking:downloadTicket",
    endpoint: "booking/downloadTicket"
  },{
    code: "booking:downloadTicketTemp",
    endpoint: "booking/downloadTicketTemp"
  },{
    code: "booking:downloadTicketExtras",
    endpoint: "booking/downloadTicketExtras"
  },{
    code: "bookingDetails:sendToPay",
    endpoint: "bookingDetails/sendToPay"
  },{
    code: "booking:findShoesSizeByScheduleList",
    endpoint: "booking/findShoesSizeByScheduleList"
  },{
    code: "booking:findStockByScheduleAndConceptList",
    endpoint: "booking/findStockByScheduleAndConceptList"
  },{
    code: "turn:findAllByOpen",
    endpoint: "turn/findAllByOpen"
  },{
    code: "turn:update",
    endpoint: "turn/update"
  },{
    code: "turnUser:findByUserAndOpen",
    endpoint: "turnUser/findByUserAndOpen"
  },{
    code: "turnUser:update",
    endpoint: "turnUser/update"
  },{
    code: "turnSale:update",
    endpoint: "turnSale/update"
  },{
    code: "turnUser:closeTurn",
    endpoint: "turnUser/closeTurn"
  },{
    code: "booking:downloadTicketInstructor",
    endpoint: "booking/downloadTicketInstructor"
  },{
    code: "turnSale:findAllByTurnUser",
    endpoint: "turnSale/findAllByTurnUser"
  },{
    code: "turnUser:get",
    endpoint: "turnUser/get"
  },{
    code: "turn:findAllByOpen",
    endpoint: "turn/findAllByOpen"
  },{
    code: "turn:list",
    endpoint:'turn/list'
  },{
    code: "turnUser:findAllByTurn",
    endpoint: "turnUser/findAllByTurn"
  },{
    code: "turnSale:getTotalByTurn",
    endpoint: "turnSale/getTotalByTurn"
  },{
    code: "turnSale:getTotalByTurnUser",
    endpoint: "turnSale/getTotalByTurnUser"
  },{
    code: "partialCut:update",
    endpoint: "partialCut/update"
  },{
    code: "partialCut:downloadTicket",
    endpoint: "partialCut/downloadTicket"
  },{
    code: "partialCut:findAllByTurnUser",
    endpoint: "partialCut/findAllByTurnUser"
  },{
    code: "shoe_list:delete",
    endpoint: "shoe/delete"
  },{
    code: "promo:update",
    endpoint: "promo/update"
  },{
    code: "promo:list",
    endpoint: "promo/list"
  },{
    code: "promo:get",
    endpoint: "promo/get"
  },{
    code: "promo:findAllByDay",
    endpoint: "promo/findAllByDay"
  },{
    code: "promo:delete",
    endpoint: "promo/delete"
  },{
    code: "promo:findAllByName",
    endpoint: "promo/findAllByName"
  },{
    code: "metric:findAllSalesByUser",
    endpoint: "metric/findAllSalesByUser"
  },{
    code: "metric:findAllSalesWithDetailsByPeriod",
    endpoint: "metric/findAllSalesWithDetailsByPeriod"
  },{
    code: "metric:downloadSalesWithDetailsByPeriod",
    endpoint: "metric/downloadSalesWithDetailsByPeriod"
  },{
    code: "metric:downloadSalesWithDetailsByPeriodAndResume",
    endpoint: "metric/downloadSalesWithDetailsByPeriodAndResume"
  }
];

export default urls;
